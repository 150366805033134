import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage2 from "../HomePage2/HomePage2";
import AboutUsPage from "../AboutUsPage/AboutUsPage";
import PricingPage from "../PricingPage/PricingPage";
import ServicePage from "../ServicePage/ServicePage";
import ServiceSinglePage from "../ServiceSinglePage/ServiceSinglePage";
import BlogPage from "../BlogPage/BlogPage";
import BlogDetails from "../BlogDetails/BlogDetails";
import ContactPage from "../ContactPage/ContactPage";
import NestedSingleServicePage from "../NestedSingleServicePage/NestedSingleServicePage";
import { KnowledgeCenter } from "../knowledge-center/knowlege-center";
import { News } from "../News/News";
import { Career } from "../Carrer/Carrer";
import { CareerSinglePage } from "../Carrer-Single/Carrer-Single";
import { PrivacyPolicy } from "../Privacy-Policy/Privacy-Policy";
import { TermsConditions } from "../Terms-Conditions/Terms-Conditions";
import PageNotFound from "../404/PageNotFound";
import OrphanUrls from "../../components/pages/OrphanUrls";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage2 />} />
          <Route path="about" element={<AboutUsPage />} />
          <Route path="pricing" element={<PricingPage />} />
          <Route path="service" element={<ServicePage />} />
          <Route path="service-single/:slug" element={<ServiceSinglePage />} />
          <Route
            path="service-single/:slug/:slug"
            element={<NestedSingleServicePage />}
          />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog-single/:slug" element={<BlogDetails />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="knowledge-center" element={<KnowledgeCenter />} />
          <Route path="industry-updates" element={<News />} />
          <Route path="career" element={<Career />} />
          <Route path="career-details/:slug" element={<CareerSinglePage />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-conditions" element={<TermsConditions />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="orphan-urls" element={<OrphanUrls />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
