export const careerdata = [
  {
    id: 1,
    title: "SOC Manager",
    slug: "soc-manager",
    description: "The SOC Manager oversees the security operations center (SOC), managing a team of security analysts and ensuring incident response and threat management.",
    tasks: [
      "Lead the SOC team in identifying, analyzing, and responding to security incidents.",
      "Ensure continuous monitoring of security alerts and efficient incident management.",
      "Collaborate with different teams to improve security strategies.",
      "Develop and enforce security policies and incident response plans.",
      "Analyze security metrics and generate detailed reports for senior management.",
      "Ensure the SOC is equipped with the latest tools and technologies."
    ],
    requirements: [
      "5+ years of experience in security operations.",
      "Strong understanding of SIEM tools and incident response protocols.",
      "Leadership and communication skills.",
      "Experience in managing large-scale security incidents.",
      "Deep knowledge of threat intelligence and mitigation techniques.",
      "Certifications such as CISSP, CISM, or CISA are preferred."
    ]
  },
  {
    id: 2,
    title: "Security Analyst - VAPT",
    slug : "security-analyst-vapt",
    description: "A Security Analyst specializing in VAPT (Vulnerability Assessment and Penetration Testing) identifies security vulnerabilities and performs penetration testing to strengthen defenses.",
    tasks: [
      "Conduct vulnerability assessments and penetration tests on systems and networks.",
      "Document and report security flaws with recommendations for fixes.",
      "Stay updated on the latest security vulnerabilities and trends.",
      "Coordinate with development teams to remediate security issues.",
      "Develop and maintain automated security testing scripts and tools.",
      "Perform regular security audits and provide detailed recommendations."
    ],
    requirements: [
      "Experience with VAPT tools like Nessus, Metasploit, or Burp Suite.",
      "Knowledge of network protocols and web application security.",
      "Certifications like CEH, OSCP are a plus.",
      "Strong analytical and problem-solving skills.",
      "Experience in threat modeling and risk assessment.",
      "Familiarity with secure coding practices and DevSecOps."
    ]
  },
  {
    id: 3,
    title: "SOC Analyst",
    slug: "soc-analyst",
    description: "The SOC Analyst monitors and responds to security threats, investigates incidents, and ensures the integrity of information systems.",
    tasks: [
      "Monitor security alerts and respond to potential threats.",
      "Conduct initial triage and investigation of security incidents.",
      "Collaborate with the SOC team to resolve issues and improve defenses.",
      "Analyze network traffic to identify anomalies and threats.",
      "Create and maintain incident reports for further analysis.",
      "Work with threat intelligence data to enhance incident response."
    ],
    requirements: [
      "1-3 years of experience in cybersecurity or SOC operations.",
      "Familiarity with SIEM tools such as Splunk, ArcSight, or QRadar.",
      "Analytical and problem-solving skills.",
      "Understanding of intrusion detection and prevention systems (IDS/IPS).",
      "Knowledge of firewalls, proxies, and endpoint security solutions.",
      "Ability to work under pressure during active incidents."
    ]
  },
  {
    id: 4,
    title: "Manager - Information Security Compliance",
    slug: "manager-information-security-compliance",
    description: "The Compliance Manager ensures that the organization adheres to industry standards and regulations, managing audits and security policies.",
    tasks: [
      "Develop and implement information security policies and procedures.",
      "Ensure compliance with legal and regulatory requirements (ISO 27001, GDPR, etc.).",
      "Coordinate security audits and assessments.",
      "Provide training and awareness programs for staff on security policies.",
      "Monitor and report on compliance status to senior management.",
      "Manage third-party audits and certifications."
    ],
    requirements: [
      "5+ years of experience in information security compliance.",
      "Knowledge of regulatory frameworks and compliance standards.",
      "Strong project management and communication skills.",
      "Experience with internal and external audits.",
      "Knowledge of data privacy laws and breach reporting processes.",
      "Certifications such as CISM, CISA, or CRISC are highly desirable."
    ]
  },
  {
    id: 5,
    title: "Business Development Executive",
    slug: "business-development-executive",
    description: "The Business Development Executive focuses on generating new business opportunities in cybersecurity, building client relationships, and driving growth.",
    tasks: [
      "Identify and pursue new business opportunities.",
      "Develop relationships with potential clients and partners.",
      "Collaborate with technical teams to propose cybersecurity solutions.",
      "Create and execute sales strategies for cybersecurity services.",
      "Generate leads through networking and market research.",
      "Track sales targets and prepare reports for management."
    ],
    requirements: [
      "Experience in sales or business development, ideally in cybersecurity or IT.",
      "Strong negotiation and communication skills.",
      "Understanding of the cybersecurity market is a plus.",
      "Ability to create and deliver persuasive sales presentations.",
      "Proven track record of meeting or exceeding sales targets.",
      "Experience with CRM software and sales pipeline management."
    ]
  }
];
