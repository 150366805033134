import React from "react";
import ContactForm from "../ContactFrom/ContactForm";
import icon1 from "../../images/icons/icon_map_mark_2.svg";
import icon2 from "../../images/icons/icon_calling_2.svg";
import icon3 from "../../images/icons/icon_mail_3.svg";
import icon4 from "../../images/icons/icon_calendar_2.svg";
import { RiMapPin2Line } from "react-icons/ri";
import { BiPhoneCall } from "react-icons/bi";
import { MdOutlineEmail } from "react-icons/md";

const ContactSection = (props) => {
  return (
    <section className="contact_section section_space">
      <div className="container">
        <div className="contact_info_box row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div
              className="iconbox_block box-shadow text-center"
              style={{ height: "100%" }}
            >
              <div className="iconbox_icon">
                {/* <img src={icon1} alt="Map Mark SVG Icon" /> */}
                <RiMapPin2Line color="white" fontSize={50} />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title text-dark">Our Office</h3>
                <p className="mb-0 text-dark">
                  T-17/E, India Bulls Mega Mall, Vadodara 390008,India.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div
              className="iconbox_block box-shadow text-center"
              style={{ height: "100%" }}
            >
              <div className="iconbox_icon">
                {/* <img src={icon2} alt="Calling SVG Icon" /> */}
                <BiPhoneCall color="white" fontSize={50} />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title text-dark">Contact</h3>
                <p className="mb-0 text-white">
                  <a
                    href="tel:+91 8866 244 200"
                  >
                    +91 8866 244 200
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div
              className="iconbox_block box-shadow text-center"
              style={{ height: "100%" }}
            >
              <div className="iconbox_icon">
                {/* <img src={icon3} alt="User Check SVG Icon" /> */}
                <MdOutlineEmail color="white" fontSize={50} />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title text-dark">Email</h3>
                <p className="mb-0">
                  <a
                    href="mailto:adritechlabs@gmail.com"
                  >
                   adritechlabs@gmail.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section_space pb-0">
      <div className="container">
        <div className="row justify-content-lg-between">
          <div className="col-lg-7">
            <div className="contact_form mb-0">
              <h3 className="details_item_info_title mb-1">
                We’d love to talk with you
              </h3>
              <p className="mb-5">
                Anything On your Mind. We’ll Be Glad To Assist You!
              </p>
              <ContactForm />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="gmap_canvas ps-lg-5">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3691.250377747732!2d73.1771478!3d22.3063687!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xacaed684a261fdad!2sADRI%20IT%20SOLUTIONS!5e0!3m2!1sen!2sin!4v1569402059517!5m2!1sen!2sin"
              ></iframe>
            </div>
          </div>
        </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
