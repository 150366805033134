import React, { useEffect } from "react";

const MetaTags = ({
  title,
  description,
  keywords,
  canonicalUrl,
}) => {
  useEffect(() => {
    document.title = title;

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content = description;
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = "description";
      newMetaDescription.content = description;
      document.head.appendChild(newMetaDescription);
    }

    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.content = keywords;
    } else {
      const newMetaKeywords = document.createElement('meta');
      newMetaKeywords.name = "keywords";
      newMetaKeywords.content = keywords;
      document.head.appendChild(newMetaKeywords);
    }

    const canonicalTag = document.querySelector('link[rel="canonical"]');
    if (canonicalTag) {
      canonicalTag.href = canonicalUrl;
    } else {
      const newCanonicalTag = document.createElement('link');
      newCanonicalTag.rel = "canonical";
      newCanonicalTag.href = canonicalUrl;
      document.head.appendChild(newCanonicalTag);
    }

    const ogTags = [
      { property: "og:title", content: title },
      { property: "og:description", content: description },
      { property: "og:url", content: canonicalUrl },
      { property: "og:image", content: "https://www.adritechlabs.com/images/development.png" },
      { property: "og:image:alt", content: "Adri Tech Labs" },
      { property: "og:type", content: "website" },
    ];

    ogTags.forEach(tag => {
      const metaTag = document.querySelector(`meta[property="${tag.property}"]`);
      if (metaTag) {
        metaTag.content = tag.content;
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('property', tag.property);
        newMetaTag.content = tag.content;
        document.head.appendChild(newMetaTag);
      }
    });

    const twitterTags = [
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:title", content: title },
      { name: "twitter:description", content: description },
      { name: "twitter:image", content: "https://www.adritechlabs.com/images/development.png" },
      { name: "twitter:url", content: canonicalUrl },
    ];

    twitterTags.forEach(tag => {
      const metaTag = document.querySelector(`meta[name="${tag.name}"]`);
      if (metaTag) {
        metaTag.content = tag.content;
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.name = tag.name;
        newMetaTag.content = tag.content;
        document.head.appendChild(newMetaTag);
      }
    });

    const additionalTags = [
      { name: "robots", content: "index, follow" },
      { name: "format-detection", content: "telephone=no" },
      { httpEquiv: "X-Content-Type-Options", content: "nosniff" },
      {
        httpEquiv: "Content-Security-Policy",
        content: "default-src 'self' 'unsafe-eval' 'unsafe-inline' 'unsafe-hashes' filesystem: blob: mediastream: data: http: https: https://www.adritechlabs.com/ www.google-analytics.com;",
      },
    ];

    additionalTags.forEach(tag => {
      const metaTag = document.querySelector(`meta[name="${tag.name}"]`) || document.querySelector(`meta[http-equiv="${tag.httpEquiv}"]`);
      if (metaTag) {
        if (tag.content) metaTag.content = tag.content; 
        else if (tag.httpEquiv) metaTag.setAttribute("http-equiv", tag.httpEquiv);
      } else {
        const newMetaTag = document.createElement('meta');
        if (tag.httpEquiv) {
          newMetaTag.setAttribute('http-equiv', tag.httpEquiv);
        } else {
          newMetaTag.name = tag.name;
        }
        newMetaTag.content = tag.content;
        document.head.appendChild(newMetaTag);
      }
    });

    return () => {
      document.title = "Adri Tech Labs | Comprehensive Security Solutions Tailored for Your Needs";
      if (metaDescription) {
        metaDescription.content = "We provide cutting-edge security services to protect your business from evolving cyber threats.";
      }
      if (metaKeywords) {
        metaKeywords.content = "cyber security services companies, cyber security service, cyber security services company, cyber security consulting services,cyber security , Managed Security Services, Threat Intelligence, Endpoint Protection, Cloud Security, Incident Response, Vulnerability Management , Phishing Protection , Malware Protection , Security Operations Center (SOC) , Data Loss Prevention, Compliance Management, Mobile Security, web security , website security , Network Security , Security Information and Event Management (SIEM) , Identity Protection, Security Awareness Training , Advanced Threat Detection , Ransomware Protection, Web Application Security, Risk Management,IT Security Services, Cloud Access Security Broker (CASB), Zero Trust Security, Adri IT Solutions, Comprehensive Security Solutions, Cybersecurity Services, Business Protection, Evolving Cyber Threats, Security Consulting, Tailored Security Solutions, IT Security Services, Risk Management, Cyber Threat Protection, Security Assessment, Incident Response, Security Governance, Data Protection Services, Network Security Solutions, Digital Security Strategies, Threat Intelligence, Security Compliance, Vulnerability Assessment, Security Infrastructure, Managed Security Services"; // Reset to default if necessary
      }
      if (canonicalTag) {
        canonicalTag.href = "https://www.adritechlabs.com";
      }
      ogTags.forEach(tag => {
        const metaTag = document.querySelector(`meta[property="${tag.property}"]`);
        if (metaTag) {
          document.head.removeChild(metaTag);
        }
      });
      twitterTags.forEach(tag => {
        const metaTag = document.querySelector(`meta[name="${tag.name}"]`);
        if (metaTag) {
          document.head.removeChild(metaTag);
        }
      });
      additionalTags.forEach(tag => {
        const metaTag = document.querySelector(`meta[name="${tag.name}"]`) || document.querySelector(`meta[http-equiv="${tag.httpEquiv}"]`);
        if (metaTag) {
          document.head.removeChild(metaTag);
        }
      });
    };
  }, [title, description, keywords, canonicalUrl]);

  return null;
};

export default MetaTags;
