import React, { useState } from "react";
import { Link } from "react-router-dom";
import about1 from "../../images/about/svgviewer-output (3).png";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import circle3 from "../../images/hero/circle_engine_3.webp";
import circle4 from "../../images/hero/circle_engine_4.webp";

const About = (props) => {
  const [isOpen, setOpen] = useState(false);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="about_section section_space">
      <div className="container">
        <div className="row align-items-center justify-content-lg-center gap-5">
          <div className="col-lg-6">
            <div className="about_image_1">
              <img src={about1} />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about_content">
              <div className="heading_block">
                {/* <div className="heading_focus_text">About Us</div> */}
                <h2 className="heading_text mb-0">Who We Are ?</h2>
                <p className="heading_description mb-0">
                  At Adri Tech Labs, we are committed to safeguarding your
                  business with advanced security solutions. Our team of experts
                  brings years of experience in cybersecurity, ensuring your
                  data and systems are always protected.
                </p>
              </div>
              <ul className="icon_list unordered_list_block mb-3">
                <li>
                  <span className="icon_list_icon">
                    <i className="fa-solid fa-circle fa-fw"></i>
                  </span>
                  <span className="icon_list_text">
                    Experienced Cybersecurity Experts
                  </span>
                </li>
                <li>
                  <span className="icon_list_icon">
                    <i className="fa-solid fa-circle fa-fw"></i>
                  </span>
                  <span className="icon_list_text">
                    Advanced Security Solutions
                  </span>
                </li>
                <li>
                  <span className="icon_list_icon">
                    <i className="fa-solid fa-circle fa-fw"></i>
                  </span>
                  <span className="icon_list_text">
                    Commitment to Protection
                  </span>
                </li>
              </ul>
              <ul className="btns_group unordered_list p-0 justify-content-start">
                <li>
                  <Link
                    onClick={ClickHandler}
                    to="/about"
                    className="noraml-btn"
                  >
                    <span className="btn_label" data-text="Learn More">
                      Learn More
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </Link>
                </li>
                {/* <li>
                  <button className="video_btn" onClick={() => setOpen(true)}>
                    <span className="btn_icon">
                      <i className="fa-solid fa-play"></i>
                    </span>
                    <span className="btn_label">How We Works</span>
                  </button>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
