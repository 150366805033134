import React, { Fragment, useState,useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Link, useLocation } from "react-router-dom";

const menus = [
  {
    id: 1,
    title: "Home",
    link: "/",
  },
  {
    id: 3,
    title: "About",
    link: "/about",
    // submenu: [
    //     {
    //         id: 31,
    //         title: 'Case Studies',
    //         link: '/portfolio'
    //     },
    //     {
    //         id: 3222,
    //         title: 'Our Pricing',
    //         link: '/pricing'
    //     },
    //     {
    //         id: 322,
    //         title: 'About Us',
    //         link: '/about'
    //     },
    //     {
    //         id: 345,
    //         title: 'Team',
    //         link: '/team'
    //     },
    //     {
    //         id: 3454,
    //         title: 'Team Details',
    //         link: '/team-single/Atticus-Sterling'
    //     }
    // ]
  },
  {
    id: 4,
    title: "Services",
    link: "/service",
    // submenu: [
    //     {
    //         id: 41,
    //         title: 'Service',
    //         link: '/service'
    //     },
    //     {
    //         id: 42,
    //         title: 'Service Details',
    //         link: '/service-single/IT-Management-Services'
    //     }
    // ]
  },
  {
    id: 5,
    title: "Blog",
    link: "/blog",
  },
  {
    id: 6,
    title: "Contact",
    link: "/contact",
  },
  {
    id: 7,
    title: "Pricing",
    link: "/pricing",
  },
  {
    id: 8,
    title: "Career",
    link: "/career",
  },
  {
    id: 9,
    title: "Knowledge Center",
    link: "/knowledge-center",
  },
  {
    id: 10,
    title: "Industry Updates",
    link: "/industry-updates",
  },
];

const MobileMenu = () => {

  const [openId, setOpenId] = useState(0);

  const location = useLocation(); // Hook to get current route

  // Scroll to top when route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, [location.pathname]); 

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <ul className="main_menu_list clearfix">
      {menus.map((item, mn) => {
        return (
          <ListItem className={item.id === openId ? "active" : null} key={mn}>
            {item.submenu ? (
              <Fragment>
                <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>
                  {item.title}
                  <i
                    className={
                      item.id === openId ? "fa fa-angle-up" : "fa fa-angle-down"
                    }
                  ></i>
                </p>
                <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                  <List className="subMenu">
                    <Fragment>
                      {item.submenu.map((submenu, i) => {
                        return (
                          <ListItem key={i}>
                            <Link
                              onClick={ClickHandler}
                              className="active"
                              to={submenu.link}
                            >
                              {submenu.title}
                            </Link>
                          </ListItem>
                        );
                      })}
                    </Fragment>
                  </List>
                </Collapse>
              </Fragment>
            ) : (
              <Link className="active" to={item.link}>
                {item.title}
              </Link>
            )}
          </ListItem>
        );
      })}
    </ul>
  );
};

export default MobileMenu;
