import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import srImg from "../../images/services/service3.png";
import ServiceData from "../../components/ServiceSection/servicedata";
import { Link } from "react-router-dom";
import MetaTags from "../../components/MetaTags";
import {
  Card,
  Col,
  Row,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const NestedSingleServicePage = (props) => {
  const { slug } = useParams();

  const urlSegments = window.location.pathname.split("/");
  const parentSegment = urlSegments[urlSegments.length - 2];

  const formatTitle = (segment) => {
    return segment
      .split("-") // Split by hyphen
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words back with spaces
  };

  const formattedTitle = formatTitle(parentSegment);
  const ServiceDetails = ServiceData?.flatMap((mainservice) =>
    mainservice.services.filter((service) => service.slug === slug)
  );

  const [open, setOpen] = useState(1);
  const toggle = (id) => {
    if (open === id) {
      setOpen(1);
    } else {
      setOpen(id);
    }
  };

  return (
    <Fragment>
      <Header />
      <main className="page_content service-single-page">
        <MetaTags
          title={`${ServiceDetails[0].name} | Adri Tech Labs`}
          description={`${ServiceDetails[0].description}`}
          canonicalUrl={`https://www.adritechlabs.com/service-single/${slug}/${ServiceDetails[0].slug}`}
          keywords="Services, Adri Tech Labs, cybersecurity services, risk assessment, threat detection, vulnerability management, incident response, business security solutions, tailored cybersecurity, digital asset protection"
        />
        <PageTitle
          pageTitle={ServiceDetails[0].name}
          // pagesub={"Details 😍"}
          pageTop={
            <span>
              <a href="/service" className="text-white">
                Services
              </a>{" "}
              {">"}{" "}
              <a
                href={`/service-single/${parentSegment}`}
                className="text-white"
              >
                {" "}
                {formattedTitle}{" "}
              </a>{" "}
              {">"} {ServiceDetails[0].name}{" "}
            </span>
          }
        />
        <section className="about_section section_space">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 order-lg-last p-0">
                <div className="p-4">
                  <div className="team_cartoon_image">
                    <img
                      src={srImg}
                      alt="Service Cartoon"
                      style={{ maxWidth: "90%" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-0">
                <div className="p-4">
                  <div className="about_content">
                    <div className="heading_block">
                      <div className="heading_focus_text mb-2">
                        {formattedTitle}
                      </div>
                      <h1 className="mb-4">{ServiceDetails[0].name}</h1>
                      <ul className="heading_description mb-4">
                        {ServiceDetails[0].service_description.map(
                          (item, index) => {
                            return <li key={index}>{item}</li>;
                          }
                        )}
                      </ul>
                    </div>
                    <Link to={"/pricing"} className="noraml-btn">
                      <span className="btn_label" data-text="Talk to an Expert">
                        Talk to an Expert
                      </span>
                      <span className="btn_icon">
                        <i className="fa-solid fa-arrow-up-right"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {ServiceDetails[0].commitment &&
          ServiceDetails[0].commitment.length > 0 && (
            <section
              className="service_section section_space"
              style={{ background: "#001E3C" }}
            >
              <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                  <div className="col-lg-12">
                    <div className="ps-lg-5">
                      <div className="heading_block">
                        <h2
                          className="heading_text text-center mb-0"
                          style={{ color: "#FFFFFF" }}
                        >
                          Our Commitment
                        </h2>
                      </div>
                      <Row>
                        {ServiceDetails[0].commitment.map((commit, pitem) => (
                          <Col lg="3">
                            <div class="ag-courses_item">
                              <div href="#" class="ag-courses-item_link">
                                <div class="ag-courses-item_bg"></div>

                                <div class="ag-courses-item_title mb-0">
                                  {commit}
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        {ServiceDetails[0].how_we_do_it &&
          ServiceDetails[0].how_we_do_it.length > 0 && (
            <>
              <section className="service_section section_space">
                <div className="container">
                  <div className="ps-lg-5">
                    <div className="heading_block">
                      <h2 className="heading_text2 text-center mb-0">
                        How we do it
                      </h2>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-lg-12">
                      <Row className="g-3 mb-4">
                        {ServiceDetails[0].how_we_do_it.map(
                          (how_we_do_it, index) => {
                            return (
                              <>
                                <Col lg="4" md="6" key={index}>
                                  <Card className="main-second-div h-100">
                                    <h5 className="icon_list_new_text">
                                      {how_we_do_it.title}
                                    </h5>
                                    <p className="icon_list_text">
                                      {how_we_do_it.description}
                                    </p>
                                  </Card>
                                </Col>
                              </>
                            );
                          }
                        )}
                      </Row>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        {ServiceDetails[0].offerings &&
          ServiceDetails[0].offerings.length > 0 && (
            <>
              <section
                className="service_section section_space"
                style={{ background: "#001E3C" }}
              >
                <div className="container">
                  <div className="heading_block">
                    <h2 className="heading_text text-center text-white mb-0">
                      Our Offerings
                    </h2>
                  </div>
                  <div className="row mb-4">
                    <div className="col-lg-12">
                      <Row className="g-3 mb-4">
                        {ServiceDetails[0].offerings.map((offerings, index) => {
                          return (
                            <>
                              <Col lg="4" md="6" key={index}>
                                <Card className="main-second-div h-100">
                                  <h5 className="icon_list_text">
                                    {offerings.title}
                                  </h5>
                                  <p className="icon_list_text">
                                    {offerings.description}
                                  </p>
                                </Card>
                              </Col>
                            </>
                          );
                        })}
                      </Row>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        {ServiceDetails[0].strategy &&
          ServiceDetails[0].strategy.length > 0 && (
            <>
              <section className="service_section section_space">
                <div className="container">
                  <div className="ps-lg-5">
                    <div className="heading_block">
                      <h2 className="heading_text2 text-center service-title mb-0">
                        Our Strategy
                      </h2>
                    </div>
                    <Row className="g-3 mb-4">
                      {ServiceDetails[0].strategy.map((strategy, index) => {
                        return (
                          <>
                            <Col lg="4" md="6" key={index}>
                              <Card className="main-second-div h-100">
                                <h5 className="icon_list_text">
                                  {strategy.title}
                                </h5>
                                <p className="icon_list_text">
                                  {strategy.description}
                                </p>
                              </Card>
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              </section>
            </>
          )}

        {ServiceDetails[0].key_features &&
          ServiceDetails[0].key_features.length > 0 && (
            <>
              <section
                className="service_section section_space"
                style={{ background: "#001E3C" }}
              >
                <div className="container">
                  <div className="ps-lg-5">
                    <div className="heading_block">
                      <h2 className="heading_text text-center text-white mb-0">
                        Key Features
                      </h2>
                    </div>
                  </div>
                  <div className="row mb-4 flex-nowrap gap-4">
                    {ServiceDetails[0].key_features.map(
                      (key_features, index) => {
                        return (
                          <>
                            <div className="col-lg-4 main-div" key={index}>
                              <h5 className="icon_list_new_text">
                                {key_features.title}
                              </h5>
                              <p className="icon_list_text">
                                {key_features.description}
                              </p>
                            </div>
                          </>
                        );
                      }
                    )}
                  </div>
                </div>
              </section>
            </>
          )}

        {ServiceDetails[0].benefits &&
          ServiceDetails[0].benefits.length > 0 && (
            <>
              <section className="service_section section_space">
                <div className="container">
                  <div className="heading_block">
                    <h2 className="heading_text2 text-center mb-0">
                      What benefits you will have:
                    </h2>
                  </div>
                  <Row className="g-3 mb-4">
                    {ServiceDetails[0].benefits.map((benefits, index) => {
                      return (
                        <>
                          <Col lg="4" md="6" key={index}>
                            <Card className="main-second-div h-100">
                              <h5 className="icon_list_text">
                                {benefits.title}
                              </h5>
                              <p className="icon_list_text">
                                {benefits.description}
                              </p>
                            </Card>
                          </Col>
                        </>
                      );
                    })}
                  </Row>
                </div>
              </section>
            </>
          )}

        {ServiceDetails[0].how_we_achieve_it &&
          ServiceDetails[0].how_we_achieve_it.length > 0 && (
            <>
              <section className="service_section section_space">
                <div className="container">
                  <div className="heading_block">
                    <h2 className="heading_text2 text-center mb-0">
                      How we achieve it
                    </h2>
                  </div>
                  <div className="row mb-4">
                    <div className="col-lg-12">
                      <Row className="g-3 mb-4">
                        {ServiceDetails[0].how_we_achieve_it.map(
                          (how_we_achieve_it, index) => {
                            return (
                              <>
                                <Col lg="4" md="6" key={index}>
                                  <Card className="main-second-div h-100">
                                    <h5 className="icon_list_text">
                                      {how_we_achieve_it.title}
                                    </h5>
                                    <p className="icon_list_text">
                                      {how_we_achieve_it.description}
                                    </p>
                                  </Card>
                                </Col>
                              </>
                            );
                          }
                        )}
                      </Row>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}

        {ServiceDetails[0].how_we_strengthen_your_security &&
          ServiceDetails[0].how_we_strengthen_your_security.length > 0 && (
            <>
              <section className="service_section section_space">
                <div className="container">
                  <div className="heading_block">
                    <h2 className="heading_text2 text-center mb-0">
                      How We Strengthen Your Security
                    </h2>
                  </div>
                  <Row className="g-3 mb-4">
                    {ServiceDetails[0].how_we_strengthen_your_security.map(
                      (how_we_strengthen_your_security, index) => {
                        return (
                          <>
                            <Col lg="4" md="6" key={index}>
                              <Card className="main-second-div h-100">
                                <h5 className="icon_list_text">
                                  {how_we_strengthen_your_security.title}
                                </h5>
                                <p className="icon_list_text">
                                  {how_we_strengthen_your_security.description}
                                </p>
                              </Card>
                            </Col>
                          </>
                        );
                      }
                    )}
                  </Row>
                </div>
              </section>
            </>
          )}

        {ServiceDetails[0].how_do_we_work &&
          ServiceDetails[0].how_do_we_work.length > 0 && (
            <>
              <section
                className="service_section section_space"
                style={{ background: "#001E3C" }}
              >
                <div className="container">
                  <div className="heading_block">
                    <h2 className="heading_text text-center text-white mb-0">
                      How Do We Work ?
                    </h2>
                  </div>
                  <Row className="g-3 mb-4">
                    {ServiceDetails[0].how_do_we_work.map(
                      (how_do_we_work, index) => {
                        return (
                          <>
                            <Col lg="3" md="6" key={index}>
                              <Card className="main-second-div h-100">
                                <p
                                  className="icon_list_text"
                                  style={{ color: "#0064B4", fontSize: "18px" }}
                                >
                                  {how_do_we_work}
                                </p>
                              </Card>
                            </Col>
                          </>
                        );
                      }
                    )}
                  </Row>
                </div>
              </section>
            </>
          )}

        {ServiceDetails[0].how_it_will_help &&
          ServiceDetails[0].how_it_will_help.length > 0 && (
            <>
              <section className="service_section section_space">
                <div className="container">
                  <div className="heading_block">
                    <h2 className="heading_text2 text-center mb-0">
                      How it will help ?
                    </h2>
                  </div>
                  <Row className="g-3 mb-4">
                    {ServiceDetails[0].how_it_will_help.map(
                      (how_it_will_help, index) => {
                        return (
                          <>
                            <Col lg="4" md="6" key={index}>
                              <Card className="main-div h-100">
                                <p
                                  className="icon_list_text"
                                  style={{ color: "#0064B4", fontSize: "18px" }}
                                >
                                  {how_it_will_help}
                                </p>
                              </Card>
                            </Col>
                          </>
                        );
                      }
                    )}
                  </Row>
                </div>
              </section>
            </>
          )}

        {ServiceDetails[0].how_we_operate &&
          ServiceDetails[0].how_we_operate.length > 0 && (
            <>
              <section className="process_technology_review_section section_decoration">
                <div className="container">
                  <div className="heading_block">
                    <h2 className="heading_text2 text-center mb-0">
                      How We Operate ?
                    </h2>
                  </div>
                  <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6">
                      <Accordion
                        open={open}
                        toggle={toggle}
                        className="accordion"
                        id="service_process_faq"
                      >
                        {ServiceDetails[0].how_we_operate.map(
                          (how_we_operate, index) => {
                            return (
                              <AccordionItem
                                key={index + 1}
                                className="accordion-item"
                              >
                                <AccordionHeader targetId={index + 1}>
                                  {how_we_operate.title}
                                </AccordionHeader>
                                <AccordionBody
                                  accordionId={index + 1}
                                  className="acc_body"
                                >
                                  <p className="m-0">
                                    {how_we_operate.description}
                                  </p>
                                </AccordionBody>
                              </AccordionItem>
                            );
                          }
                        )}
                      </Accordion>
                    </div>
                    <div className="col-lg-5">
                      <Accordion
                        open={open}
                        toggle={toggle}
                        className="accordion"
                        id="service_process_faq"
                      >
                        <ul className="content_layer_group unordered_list_block text-center">
                          {ServiceDetails[0].how_we_operate.map(
                            (how_we_operate, index) => {
                              return (
                                <li key={index + 1}>
                                  <AccordionHeader
                                    className="accordion-new-header"
                                    targetId={index + 1}
                                  >
                                    <span>{how_we_operate.title}</span>
                                  </AccordionHeader>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}

        {ServiceDetails[0].we_achieve_it_by &&
          ServiceDetails[0].we_achieve_it_by.length > 0 && (
            <>
              <section className="service_section section_space">
                <div className="container">
                  <div className="ps-lg-5">
                    <div className="heading_block">
                      <h2 className="heading_text2 text-center mb-0">
                        We achieve it by
                      </h2>
                    </div>
                    <Row className="row mb-4">
                      {ServiceDetails[0].we_achieve_it_by.map(
                        (we_achieve_it_by, index) => {
                          return (
                            <>
                              <Col lg="4" md="6" key={index}>
                                <Card className="main-second-div h-100">
                                  <h5 className="icon_list_new_text">
                                    {we_achieve_it_by.title}
                                  </h5>
                                  <p className="icon_list_text">
                                    {we_achieve_it_by.description}
                                  </p>
                                </Card>
                              </Col>
                            </>
                          );
                        }
                      )}
                    </Row>
                  </div>
                </div>
              </section>
            </>
          )}

        {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        {ServiceDetails[0].why_us && ServiceDetails[0].why_us.length > 0 && (
          <section
            className="service_section section_space"
            style={{ background: "#001E3C" }}
          >
            <div className="container">
              <div className="heading_block">
                <h2 className="heading_text text-center text-white mb-0">
                  Why Us ?
                </h2>
              </div>
              <Row className="g-3 mb-4">
                {ServiceDetails[0].why_us.map((why_us, index) => {
                  return (
                    <>
                      <Col
                        lg="12"
                        md="12"
                        key={index}
                        className="p-4 why-us-div rounded-3"
                      >
                        <h5 className="icon_list_text text-start">
                          {why_us.title} :
                        </h5>
                        <p className="icon_list_text text-start">
                          {why_us.description}
                        </p>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </div>
          </section>
        )}

        {/* //////// // ////////////////////////////////// //  //////////////////////////////////   */}

        {ServiceDetails[0].how_we_handle_investigations &&
          ServiceDetails[0].how_we_handle_investigations.length > 0 && (
            <>
              <section
                className="service_section section_space"
                style={{ background: "#001E3C" }}
              >
                <div className="container">
                  <div className="ps-lg-5">
                    <div className="heading_block">
                      <h2 className="heading_text text-center text-white mb-0">
                        How Do We Handle Cybercrime Investigations ?
                      </h2>
                    </div>
                    <Row className="row mb-4">
                      {ServiceDetails[0].how_we_handle_investigations.map(
                        (how_we_handle_investigations, index) => {
                          return (
                            <>
                              <Col lg="4" md="6" sm="12" key={index}>
                                <Card className="main-div h-100">
                                  <h5 className="icon_list_new_text">
                                    {how_we_handle_investigations.title}
                                  </h5>
                                  <p className="icon_list_text">
                                    {how_we_handle_investigations.description}
                                  </p>
                                </Card>
                              </Col>
                            </>
                          );
                        }
                      )}
                    </Row>
                  </div>
                </div>
              </section>
            </>
          )}
        {ServiceDetails[0].knowledge_base &&
          ServiceDetails[0].knowledge_base.length > 0 && (
            <section className="service_section section_space">
              <div className="container">
                <div className="heading_block">
                  <h2 className="heading_text2 text-center mb-0">
                    Knowledge Base
                  </h2>
                </div>
                <div className="faq_accordion accordion" id="faq_accordion">
                  <Accordion
                    open={open}
                    toggle={toggle}
                    className="accordion"
                    id="service_process_faq"
                  >
                    {ServiceDetails[0].knowledge_base.map(
                      (knowledge_base, index) => {
                        return (
                          <>
                            <AccordionItem className="accordion-item">
                              <AccordionHeader targetId={index + 1}>
                                {knowledge_base.question}
                              </AccordionHeader>
                              <AccordionBody
                                accordionId={index + 1}
                                className="acc_body"
                              >
                                <p className="mb-0">{knowledge_base.answer}</p>
                              </AccordionBody>
                            </AccordionItem>
                          </>
                        );
                      }
                    )}
                  </Accordion>
                </div>
              </div>
            </section>
          )}
      </main>
      <CtaSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default NestedSingleServicePage;
