import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../images/site_logo/adritechlogo.png";
import logo2 from "../../images/site_logo/logo2.png";
import MobileMenu from "../MobileMenu/MobileMenu";

const Header2 = (props) => {
  const [mobailActive, setMobailState] = useState(false);
  const location = useLocation();

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  return (
    <header className="site_header site_header_3">
      <div
        className={`header_bottom stricky  ${
          isSticky ? "stricked-menu stricky-fixed" : ""
        }`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-3 col-lg-2 col-5">
              <div className="site_logo">
                <Link onClick={ClickHandler} className="site_link" to="/">
                  <img
                    src={isSticky ? logo2 : logo}
                    alt="Site Logo – Techco – IT Solutions & Technology, Business Consulting, Software Company Site Template"
                  />
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 col-2">
              <nav className="main_menu navbar navbar-expand-lg">
                <div
                  className="main_menu_inner collapse navbar-collapse justify-content-lg-center"
                  id="main_menu_dropdown"
                >
                  <ul className="main_menu_list unordered_list justify-content-center">
                    <li
                      className={`dropdown ${
                        location.pathname === "/" ? "active" : ""
                      }`}
                    >
                      <Link onClick={ClickHandler} className="nav-link" to="/">
                        Home
                      </Link>
                    </li>
                    <li
                      className={`dropdown ${
                        location.pathname === "/about" ? "active" : ""
                      }`}
                    >
                      <Link
                        onClick={ClickHandler}
                        className="nav-link"
                        to="/about"
                      >
                        About
                      </Link>
                    </li>
                    <li
                      className={`dropdown ${
                        location.pathname === "/service" ? "active" : ""
                      }`}
                    >
                      <Link
                        onClick={ClickHandler}
                        className="nav-link"
                        to="/service"
                      >
                        Services
                      </Link>
                    </li>
                    <li
                      className={`dropdown ${
                        location.pathname === "/knowledge-center"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        onClick={ClickHandler}
                        className="nav-link"
                        to="/knowledge-center"
                      >
                        Knowledge Center
                      </Link>
                    </li>
                    <li
                      className={`dropdown ${
                        location.pathname === "/blog"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        onClick={ClickHandler}
                        className="nav-link"
                        to="/blog"
                      >
                        Blogs
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === "/contact" ? "active" : ""
                      }
                    >
                      <Link onClick={ClickHandler} to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="col-xl-3 col-lg-3 col-5">
              <ul className="header_btns_group unordered_list justify-content-end">
                <li>
                  <button
                    className={
                      isSticky ? "mobile_menu_btn" : "mobile_menu_btn_head"
                    }
                    onClick={() => setMobailState(!mobailActive)}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#main_menu_dropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i className="far fa-bars"></i>
                  </button>
                </li>
                <li>
                  <Link
                    onClick={ClickHandler}
                    className="gradient-header-btn"
                    to="/pricing"
                  >
                    <span className="btn_label" data-text="Get Started">
                      Get Started
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mobail-wrap">
          <div className={`mobail-menu ${mobailActive ? "active" : ""}`}>
            <div className="xb-header-menu-scroll">
              <div
                className="xb-menu-close xb-hide-xl xb-close"
                onClick={() => setMobailState(!mobailActive)}
              ></div>
              <nav className="xb-header-nav">
                <MobileMenu />
              </nav>
            </div>
          </div>
          <div
            className="xb-header-menu-backdrop"
            onClick={() => setMobailState(false)}
          ></div>
        </div>
      </div>
    </header>
  );
};

export default Header2;
