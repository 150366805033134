import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import blogs from "../../api/blogs";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import BlogSingle from "../../components/BlogDetails/BlogDetails";

const BlogDetails = (props) => {
  const { slug } = useParams();

  const BlogDetails = blogs.find((item) => item.slug === slug);
  return (
    <Fragment>
      <Header />
      <main className="page_content about-page">
        <PageTitle
          pageTitle={BlogDetails.title}
          pageTop={
            <span>
              <a href="/blog" className="text-white">
                Blogs
              </a>{" "}
              {">"} {BlogDetails?.title}{" "}
            </span>
          }
        />
        <BlogSingle />
      </main>
      <CtaSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogDetails;
