import React from "react";
import { Link } from "react-router-dom";
import Bg from "../../images/shapes/bg_pattern_3.svg";
import icon1 from "../../images/icons/icon_mail.svg";
import icon2 from "../../images/icons/icon_calling.svg";
import icon3 from "../../images/icons/icon_map_mark.svg";
import icon4 from "../../images/icons/icon_mail_2.svg";
import Services from "../../api/service";
import ServiceDetails from "../ServiceSection/servicedata";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const SubmitHandler = (e) => {
  e.preventDefault();
};

const Footer = (props) => {
  return (
    <footer className="site_footer footer_layout_1">
      <div className="content_box" style={{ backgroundImage: `url(${Bg})` }}>
        <div className="container">
          {/* <div className="diract_contact_links text-white">
            <div className="iconbox_block iconbox_new_footer_block layout_icon_left">
              <div className="iconbox_icon">
                <img src={icon1} alt="Mail SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">Email</h3>
                <p className="mb-0">
                  <a
                    href="mailto:contact@adriitsolutions.com"
                    style={{ color: "#FFFFFF" }}
                  >
                    contact@adriitsolutions.com
                  </a>
                </p>
              </div>
            </div>
            <div className="iconbox_block iconbox_new_footer_block layout_icon_left">
              <div className="iconbox_icon">
                <img src={icon2} alt="Calling Check SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">Contact Us</h3>
                <p className="mb-0">
                  <a href="tel:+91 8866 244 200" style={{ color: "#FFFFFF" }}>
                    +91 8866 244 200
                  </a>
                </p>
              </div>
            </div>
            <div className="iconbox_block iconbox_new_footer_block layout_icon_left">
              <div className="iconbox_icon">
                <img src={icon3} alt="Map Mark Check SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">Our Office</h3>
                <p className="mb-0">
                  {" "}
                  T-17/E, India Bulls Mega Mall, Vadodara 390008,India.
                </p>
              </div>
            </div>
          </div> */}
          <div className="footer_main_content">
            <div className="row justify-content-lg-between">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="footer_widget pe-md-3">
                  <h1 className="footer_info_title gradient-text mb-3">
                    Adri Tech Labs
                  </h1>
                  <p>
                    We provide cutting-edge security services to protect your
                    business from evolving cyber threats.
                  </p>
                  <ul className="social_links_block unordered_list">
                    <li>
                      <a onClick={ClickHandler} target="_blank" href="https://www.facebook.com/profile.php?id=61566992912527">
                        <FaFacebook fontFamily="30" fontSize={20} />
                      </a>
                    </li>
                    <li>
                      <a onClick={ClickHandler} target="_blank" href="https://www.instagram.com/adritechlabs/">
                        <FaInstagram fontFamily="30" fontSize={20} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="footer_widget">
                  <h5 className="footer_title">
                    SECURITY DIAGONITSITC SERVICES
                  </h5>
                  <ul className="icon_list unordered_list_block">
                    {ServiceDetails[0]?.services?.map((service, srv) => (
                      <li key={srv}>
                        {service.name ? (
                          <Link
                            onClick={ClickHandler}
                            to={`/service-single/security-diagnostic-services/${service.slug}`}
                          >
                            <span className="icon_list_text">
                              {service.name}
                            </span>
                          </Link>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="footer_widget mt-3">
                  <h5 className="footer_title">
                    INDUSTRIAL SECURITY ASSESSMENT SERVICES
                  </h5>
                  <ul className="icon_list unordered_list_block">
                    {ServiceDetails[3]?.services?.map((service, srv) => (
                      <li key={srv}>
                        {service.name ? (
                          <Link
                            onClick={ClickHandler}
                            to={`/service-single/industrial-security-assessment-services/${service.slug}`}
                          >
                            <span className="icon_list_text">
                              {service.name}
                            </span>
                          </Link>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="footer_widget">
                  <h5 className="footer_title">CYBER PROTECTION SERVICES</h5>
                  <ul className="icon_list unordered_list_block">
                    {ServiceDetails[1]?.services?.map((service, srv) => (
                      <li key={srv}>
                        {service.name ? (
                          <Link
                            onClick={ClickHandler}
                            to={`/service-single/cyber-protection-services/${service.slug}`}
                          >
                            <span className="icon_list_text">
                              {service.name}
                            </span>
                          </Link>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="footer_widget mt-3">
                  <h5 className="footer_title">PERSONALISED SERVICES</h5>
                  <ul className="icon_list unordered_list_block">
                    {ServiceDetails[2]?.services?.map((service, srv) => (
                      <li key={srv}>
                        {service.name ? (
                          <Link
                            onClick={ClickHandler}
                            to={`/service-single/personalised-services-just-for-you/${service.slug}`}
                          >
                            <span className="icon_list_text">
                              {service.name}
                            </span>
                          </Link>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="footer_widget">
                  <h5 className="footer_title">
                    SECURITY GOVERNANCE AND COMPLIANCE SERVICES
                  </h5>
                  <ul className="icon_list unordered_list_block">
                    {ServiceDetails[4]?.services?.map((service, srv) => (
                      <li key={srv}>
                        {service.name ? (
                          <Link
                            onClick={ClickHandler}
                            to={`/service-single/security-governance-and-compliance-services/${service.slug}`}
                          >
                            <span className="icon_list_text">
                              {service.name}
                            </span>
                          </Link>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="footer_widget d-flex justify-content-between gap-4 mt-3">
                  <div>
                    <h5 className="footer_title">COMPANY AND RESOURCES</h5>
                    {/* <div className="icon_list unordered_list_block"> */}
                    <div className="row">
                      <div className="col-6">
                        <ul className="icon_list p-0">
                          <li className="mb-1">
                            <Link onClick={ClickHandler} to="/about">
                              <span className="icon_list_text">About Us</span>
                            </Link>
                          </li>
                          {/* <li className="mb-1">
                            <Link onClick={ClickHandler} to="/contact">
                              <span className="icon_list_text">Contact</span>
                            </Link>
                          </li> */}
                          <li className="mb-1">
                            <Link onClick={ClickHandler} to="/industry-updates">
                              <span className="icon_list_text">
                                Industry Updates
                              </span>
                            </Link>
                          </li>
                          <li className="mb-1">
                            <Link onClick={ClickHandler} to="/privacy-policy">
                              <span className="icon_list_text">
                                Privacy Policy
                              </span>
                            </Link>
                          </li>
                          <li className="mb-1">
                            <Link onClick={ClickHandler} to="/terms-conditions">
                              <span className="icon_list_text">Terms Conditions</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-6">
                        <ul className="icon_list p-0">
                          <li className="mb-1">
                            <Link onClick={ClickHandler} to="/knowledge-center">
                              <span className="icon_list_text">
                                Knowledge center
                              </span>
                            </Link>
                          </li>
                          <li className="mb-1">
                            <Link onClick={ClickHandler} to="/contact">
                              <span className="icon_list_text">Contact</span>
                            </Link>
                          </li>
                          <li className="mb-1">
                            <Link onClick={ClickHandler} to="/career">
                              <span className="icon_list_text">Career</span>
                            </Link>
                          </li>
                          {/* <li className="mb-1">
                            <Link onClick={ClickHandler} to="/blog">
                              <span className="icon_list_text">Blogs</span>
                            </Link>
                          </li> */}
                          {/* <li className="mb-1">
                            <Link onClick={ClickHandler} to="/case-studies">
                              <span className="icon_list_text">Case Study</span>
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="footer_bottom">
                <div className="container d-md-flex align-items-md-center justify-content-md-between">
                    <p className="copyright_text m-0">
                        Copyright © 2024 Techco, All rights reserved.
                    </p>
                    <p className="copyright_text m-0">
                        Developed by <Link onClick={ClickHandler} to="/">XpressBuddy</Link>
                    </p>
                </div>
            </div> */}
    </footer>
  );
};

export default Footer;
