// images
import blogImg5 from "../images/blog/news1.webp";
import blogImg6 from "../images/blog/news3.webp";
import blogImg7 from "../images/blog/news5.webp";

// images
import blogsImg1 from "../images/blog/blog_post_image_1.webp";
import blogsImg2 from "../images/blog/blog_post_image_2.webp";
import blogsImg3 from "../images/blog/blog_post_image_3.webp";
import blogsImg4 from "../images/blog/blog_post_image_1.webp";


const newsdata = [
    {
        id: '1',
        title: 'Gen AI is driving revolutionary changes in security and industry. ',
        slug: 'Exploring-Emerging-Trends-in-Software-Development.',
        screens: blogImg5,
        bSingle: blogsImg4,
        description: 'The realm of visa applications is a and ever-changing landscape...',
        author: 'Economic Times',
        authorTitle:'Art Director',
        create_at: 'Jun 14, 2024',
        comment:'95',
        thumb:'Consulting',
        link : "https://cio.economictimes.indiatimes.com/tag/cyber+security",
        authlink : "https://cio.economictimes.indiatimes.com/"
    },
    {
        id: '2',
        title: 'Your Phone Won’t Be the Next Exploding Pager',
        slug: 'How-Software-Integration-Can-Improve-Workflow.',
        screens: blogImg7,
        bSingle: blogsImg4,
        description: 'Embarking on a journey to study abroad is an aspiration many students..',
        author: 'WIRED',
        authorTitle:'Art Director',
        create_at: 'Sep 19, 2024',
        comment:'95',
        thumb:'Consulting',
        link : "https://www.wired.com/story/exploding-pagers-hezbollah-phones/",
        authlink : "https://www.wired.com/"
    },
    {
        id: '3',
        title: "Cyber threats are rising — And so is India's cyber insurance market",
        slug: '5-ways-to-relieve-stress-during-the-holidays',
        screens: blogImg6,
        bSingle: blogsImg4,
        description: 'Embarking on a journey to study abroad is an aspiration many students..',
        author: 'Money Control',
        authorTitle:'Art Director',
        create_at: 'Sep 14, 2024',
        comment:'95',
        link : "https://www.moneycontrol.com/news/business/cyber-threats-are-rising-and-so-is-indias-cyber-insurance-market-12821872.html",
        authlink : "https://www.moneycontrol.com/news/",
        thumb:'Live Well',
    },
];
export default newsdata;