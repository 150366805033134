import React, { Fragment, useState } from "react";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import { Link } from "react-router-dom";
import CtaSection from "../../components/CtaSection/CtaSection";
import Footer from "../../components/footer/Footer";
import SimpleReactValidator from "simple-react-validator";
import { useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { careerdata } from "../../api/careerdata";
import emailjs from "emailjs-com";
import MetaTags from "../../components/MetaTags";

export const CareerSinglePage = () => {
  const { slug } = useParams();
  const careerDetails = careerdata.find((item) => item.slug === slug);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [forms, setForms] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();

      const templateParams = {
        to_name: "Your Name",
        from_name: forms.name,
        from_email: forms.email,
        phone: forms.phone,
        message: forms.message,
        job_title: careerDetails.title,
      };

      emailjs
        .send(
          "service_xz86p1r",
          "template_hmo6tsh",
          templateParams,
          "2Wf55mx_7S_5W7m8e"
        )
        .then(
          (response) => {
            setFormSubmitted(true);
            setForms({
              name: "",
              email: "",
              phone: "",
              message: "",
            });
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );
    } else {
      validator.showMessages();
      setForms({ ...forms });
    }
  };

  return (
    <Fragment>
      <Header />
      <main className="page_content about-page">
        <MetaTags
          title={`${careerDetails.title} | Adri Tech Labs`}
          canonicalUrl={`https://www.adritechlabs.com/career-details/${careerDetails.slug}`}
          description={`${careerDetails.description}`}
          keywords="Career, Adri Tech Labs, cybersecurity jobs, job opportunities, work with us, technology careers, cybersecurity professionals, job openings, work culture, career in cybersecurity"
        />
        <PageTitle
          pageTitle={careerDetails.title}
          pageTop={
            <span>
              <a href="/career" className="text-white">
                Career
              </a>{" "}
              {">"} {careerDetails.title}
            </span>
          }
        />
        <section id="job_details" className="career-openings section_space">
          <div className="container max-w-lg">
            <div className="section-inner panel">
              <Row>
                <Col lg="6">
                  <h3 className="gradient-text mb-3">{careerDetails.title}</h3>
                  <p>{careerDetails.description}</p>
                  <h3 className="mt-3">Tasks:</h3>
                  <ul className="m-0">
                    {careerDetails.tasks.map((item, index) => (
                      <li className="mb-1" key={index}>
                        {item}
                      </li>
                    ))}
                  </ul>
                  <h3 className="mt-3">Requirements:</h3>
                  <ul className="m-0">
                    {careerDetails.requirements.map((item, index) => (
                      <li className="mb-1" key={index}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </Col>
                <Col lg="6">
                  <div className="instant_contact_form normal-bg shadow-none sticky-element">
                    {!formSubmitted ? (
                      <form
                        className="xb-item--form contact-from"
                        onSubmit={submitHandler}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group New-Form">
                              <label
                                className="input_title"
                                htmlFor="input_name"
                              >
                                <i className="fa-regular fa-user"></i>
                              </label>
                              <input
                                value={forms.name}
                                type="text"
                                name="name"
                                className="form-control"
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                placeholder="Your Name"
                              />
                              {validator.message(
                                "name",
                                forms.name,
                                "required|alpha_space"
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group New-Form">
                              <label
                                className="input_title"
                                htmlFor="input_email"
                              >
                                <i className="fa-regular fa-envelope"></i>
                              </label>
                              <input
                                value={forms.email}
                                type="email"
                                name="email"
                                className="form-control"
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                placeholder="Your Email"
                              />
                              {validator.message(
                                "email",
                                forms.email,
                                "required|email"
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group New-Form">
                              <label
                                className="input_title"
                                htmlFor="input_phone"
                              >
                                <i className="fa-regular fa-phone-volume"></i>
                              </label>
                              <input
                                value={forms.phone}
                                type="phone"
                                name="phone"
                                className="form-control"
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                placeholder="Your Phone No."
                              />
                              {validator.message(
                                "phone",
                                forms.phone,
                                "required|phone"
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group New-Form">
                              <label
                                className="input_title"
                                htmlFor="input_company"
                              >
                                <i className="fa-regular fa-globe"></i>
                              </label>
                              <input
                                value={careerDetails.title}
                                type="technology"
                                name="technology"
                                className="form-control"
                                disabled
                                placeholder="Technology"
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group New-Form">
                              <label
                                className="input_title"
                                htmlFor="input_textarea"
                              >
                                <i className="fa-regular fa-comments"></i>
                              </label>
                              <textarea
                                value={forms.message}
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                name="message"
                                className="form-control"
                                placeholder="Short Description About Your Self..."
                              ></textarea>
                              {validator.message(
                                "message",
                                forms.message,
                                "required"
                              )}
                            </div>
                            <button
                              type="submit"
                              className="gradient-light-btn btn-padding"
                            >
                              <span
                                className="btn_label"
                                data-text="Send Message"
                              >
                                Send Message
                              </span>
                              <span className="btn_icon">
                                <i className="fa-solid fa-arrow-up-right"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      </form>
                    ) : (
                      <div className="p-6 contact_form">
                        <h2 className="text-2xl mb-0 text-white">Thank you!</h2>
                        <p className="text-lg text-white">
                          Your message has been sent successfully.
                        </p>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </main>
      <CtaSection />
      <Footer />
    </Fragment>
  );
};
