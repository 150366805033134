import React from "react";

const Hero2 = () => {
  return (
    <section className="software_company_hero_section xb-hidden">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="content_wrap">
              <h1 className="text-white">
                Secure Your Business with <mark className="gradient-text">Adri Tech Labs</mark>
              </h1>
              <h5 className="text-white">
                Comprehensive Security Solutions Tailored for Your Needs
              </h5>
              <p>
                We provide cutting-edge security services to protect your
                business from evolving cyber threats.
              </p>
              <ul className="btns_group unordered_list p-0 justify-content-start">
                <li>
                  <a className="gradient-btn" href="/service">
                    <span className="btn_label" data-text="Learn More">
                      Learn More
                    </span>
                  </a>
                </li>
                <li>
                  <a className="white-btn" href="/pricing">
                    <span className="btn_label" data-text="Get Started">
                      Get Started
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero2;
