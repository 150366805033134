import sImg1 from "../../images/blog/techservice1.jpg";
import sImg2 from "../../images/blog/techservice2.jpg";
import sImg3 from "../../images/blog/techservice3.jpg";
import sImg4 from "../../images/blog/techservice4.jpg";
import sImg5 from "../../images/blog/techservice5.jpg";


const ServiceData = [
  {
    Id: "1",
    col: "col-lg-6",
    sImg: sImg1,
    title: "Security Diagnostic Services",
    slug: "security-diagnostic-services",
    subtitle:
      "Identifies vulnerabilities in systems, networks, and applications through comprehensive scanning and testing to prevent exploitation.",
    service_description: [
      "Focuses on identifying and assessing vulnerabilities within an organization’s IT infrastructure.",
      "Involves comprehensive scanning and testing to detect weaknesses in systems, networks, and applications.",
      "Aims to diagnose potential security issues before they can be exploited by attackers.",
    ],
    description:
      "This service focuses on identifying and assessing vulnerabilities within an organization’s IT infrastructure. It involves comprehensive scanning and testing to detect weaknesses in systems, networks, and applications. The aim is to diagnose potential security issues before they can be exploited by attackers.",
    services: [
      {
        name: "Website Security Inspection",
        slug: "website-security-inspection",
        service_description: [
          "Safeguards web applications by identifying and addressing security vulnerabilities.",
          "Uses a blend of automated tools and manual testing to ensure all security standards are thoroughly covered.",
          "Provides a detailed report highlighting vulnerabilities and offering actionable solutions to enhance application security.",
        ],
        description:
          "Safeguard your web applications by identifying and addressing security vulnerabilities. We use a blend of automated tools and manual testing to ensure all security standards are thoroughly covered. Our detailed report highlights vulnerabilities and offers actionable solutions to enhance your application's security.",
        strategy: [
          {
            title: "Information Gathering",
            description:
              "We analyse your web application's architecture and technology stack to understand potential vulnerabilities.",
          },
          {
            title: "Test Case Development",
            description:
              "We create detailed test cases tailored to the specifics of your application.",
          },
          {
            title: "Hybrid Testing",
            description:
              "Our team uses a mix of automated tools and manual expertise to detect common and complex vulnerabilities.",
          },
          {
            title: "Compliance Checks",
            description:
              "Our testing covers key security standards like the OWASP Top Ten, ensuring comprehensive protection.",
          },
          {
            title: "False Positive Minimization",
            description:
              "We ensure the results focus only on real threats by minimising false positives.",
          },
          {
            title: "Reporting and Re-testing",
            description:
              "We provide a detailed report with vulnerabilities and remediation advice, followed by a re-test to confirm issues are resolved.",
          },
        ],
        commitment: [
          "Hybrid Vulnerability Assessment and Penetration Testing (VAPT) Report",
          "Coverage of OWASP Top 10 and WASC 26 Classes",
          "Comprehensive VAPT Report",
          "Vulnerability Exploitation with Proof of Concepts",
          "Guaranteed Zero False Positives",
          "Recommendations for remediation",
          "Online Support and Patch Assistance",
        ],
      },
      {
        name: "Mobile App Threat Analysis",
        slug: "mobile-app-threat-analysis",
        service_description: [
          "Protects mobile applications from unauthorized access and data breaches.",
          "Includes both automated and manual testing to uncover hidden vulnerabilities.",
          "Ensures robust security for mobile applications.",
        ],
        description:
          "Protect your mobile applications from unauthorized access and data breaches. Our approach includes both automated and manual testing to uncover hidden vulnerabilities and ensure robust security.",
        strategy: [
          {
            title: "Architecture Review",
            description:
              "We analyse the structure of your mobile app to identify security weaknesses.",
          },
          {
            title: "Static Analysis",
            description:
              "Automated tools are used to examine the app's code for security flaws.",
          },
          {
            title: "Dynamic Analysis",
            description:
              "We simulate real-world attacks to understand the app's behavior under different conditions.",
          },
          {
            title: "Business Logic Testing",
            description:
              "We test the application’s logic to ensure it handles data and processes securely.",
          },
          {
            title: "False Positive Filtering",
            description:
              "We carefully sift through results to focus on genuine vulnerabilities.",
          },
          {
            title: "Comprehensive Reporting",
            description:
              "A thorough report details our findings and offers remediation steps.",
          },
        ],
        commitment: [
          "Hybrid Vulnerability Assessment and Penetration Testing (VAPT) of Mobile Applications",
          "Coverage of OWASP Top 10 Classes for Mobile Apps",
          "Detailed VAPT Report",
          "Proof of Concept for Vulnerability Exploitation",
          "Zero False Positives Assurance",
          "Remediation Guidance",
          "Ongoing Support and Patch Assistance",
        ],
      },
      {
        name: "Thick Client Security Assessment",
        slug: "thick-client-security-assessment",
        service_description: [
          "Assesses and secures thick client applications against a wide range of threats.",
          "Combines sophisticated tools and manual testing for a deep, thorough analysis.",
        ],
        description:
          "Assess and secure thick client applications against a wide range of threats. Our process combines sophisticated tools and manual testing for a deep, thorough analysis.",
        strategy: [
          {
            title: "Application Exploration",
            description:
              "We examine the inner workings of your thick client application to pinpoint potential vulnerabilities.",
          },
          {
            title: "Advanced Scanning",
            description:
              "Using both automated tools and manual inspection, we detect and analyze security flaws.",
          },
          {
            title: "Security Configuration Review",
            description:
              "We evaluate and enhance security settings to protect against threats.",
          },
          {
            title: "Prioritization and Roadmap Creation",
            description:
              "Risks are sorted by urgency, with a clear action plan provided for remediation.",
          },
          {
            title: "Detailed Reporting",
            description:
              "Our report outlines vulnerabilities, impact levels, and recommended fixes.",
          },
        ],
        commitment: [
          "Hybrid VAPT of Thick Client Application",
          "OWASP Top 10 Coverage",
          "Full VAPT Report",
          "Proofs of Concept for Vulnerability Exploitation",
          "Zero False Positives Guarantee",
          "Remediation Strategies and Recommendations",
          "Online Support for Patch Management",
        ],
      },
      {
        name: "Network/IT Infrastructure Security Assessment",
        slug: "network-it-infrastructure-security-assessment",
        service_description: [
          "Conducts a comprehensive audit of IT infrastructure to identify security vulnerabilities.",
          "Mitigates vulnerabilities to ensure robust defenses against cyber threats.",
        ],
        description:
          "Conduct a comprehensive audit of your IT infrastructure to identify and mitigate security vulnerabilities, ensuring robust defenses against cyber threats.",
        strategy: [
          {
            title: "Infrastructure Understanding",
            description:
              "We conduct a detailed analysis of your IT network and infrastructure to identify potential weaknesses.",
          },
          {
            title: "Vulnerability Detection",
            description:
              "Our team searches meticulously for any security gaps that could be exploited.",
          },
          {
            title: "External and Internal Penetration Testing",
            description:
              "We simulate both external and internal cyber-attacks to test the strength of your defenses.",
          },
          {
            title: "Security Systems Review",
            description:
              "We evaluate your existing security measures to ensure they are effective and up to date.",
          },
          {
            title: "Detailed Reporting and Recommendations",
            description:
              "After analysis, we provide a report with findings and recommendations for strengthening your infrastructure.",
          },
        ],
        commitment: [
          "Hybrid VAPT of IT/Network Infrastructure",
          "NIST Standard Coverage",
          "Comprehensive Security Assessment Report",
          "Vulnerability Exploitation Proofs",
          "Zero False Positives Guarantee",
          "Remediation recommendations",
          "Support and guidance for patching",
        ],
      },
      {
        name: "Cloud Security Assessment",
        slug: "cloud-security-assessment",
        service_description: [
          "Secure your cloud environment by identifying vulnerabilities and ensuring compliance with global security standards.",
        ],
        description:
          "Secure your cloud environment by identifying vulnerabilities and ensuring compliance with global security standards.",
        strategy: [
          {
            title: "In-Depth Review",
            description:
              "We analyse your cloud architecture and configurations to identify vulnerabilities.",
          },
          {
            title: "Comprehensive Scanning",
            description:
              "Advanced tools are used to detect security gaps across your cloud infrastructure.",
          },
          {
            title: "Attack Simulation",
            description:
              "We simulate attacks to assess your defenses against real-world threats.",
          },
          {
            title: "Security Systems Evaluation",
            description:
              "All security mechanisms, from authentication to encryption, are thoroughly reviewed.",
          },
          {
            title: "Standards Compliance",
            description:
              "We ensure your cloud environment meets standards like NIST and CIS.",
          },
        ],
        commitment: [
          "Cloud Security Assessment Report",
          "Cloud Configuration Review Report",
          "Penetration Testing Report",
          "Security control recommendations",
          "Remediation Plan and Roadmap",
          "Online Support for Patch Assistance",
        ],
      },
      {
        name: "Wireless Security Assessment",
        slug: "wireless-security-assessment",
        service_description: [
          "Strengthen your wireless network against potential threats by identifying and addressing vulnerabilities.",
        ],
        description:
          "Strengthen your wireless network against potential threats by identifying and addressing vulnerabilities.",
        strategy: [
          {
            title: "Network Evaluation",
            description:
              "A thorough assessment of your wireless network’s architecture and security controls.",
          },
          {
            title: "Vulnerability Detection",
            description:
              "Advanced tools are used to identify weak spots in the network.",
          },
          {
            title: "Access Controls Enhancement",
            description:
              "Strengthen network access controls to prevent unauthorized access.",
          },
          {
            title: "Encryption and Monitoring",
            description:
              "Implement advanced encryption methods and robust monitoring solutions.",
          },
          {
            title: "Best Practices Guide",
            description: "Recommendations for ongoing security improvements.",
          },
        ],
        commitment: [
          "Wireless Security Controls Assessment",
          "Vulnerability Assessment Findings",
          "Wireless Network Architecture Review",
          "Security recommendations and mitigation strategies",
          "Wireless Security Best Practices Guide",
          "Wireless Encryption Assessment",
        ],
      },
      {
        name: "Source Code Review",
        slug: "source-code-review",
        service_description: [
          "Identify security vulnerabilities in your application’s source code to ensure secure development practices and prevent potential breaches.",
        ],
        description:
          "Identify security vulnerabilities in your application’s source code to ensure secure development practices and prevent potential breaches.",
        strategy: [
          {
            title: "Comprehensive Code Analysis",
            description:
              "We examine your codebase using automated tools and manual inspection to identify potential vulnerabilities.",
          },
          {
            title: "Authentication and Access Review",
            description:
              "Ensure that your code includes robust user access controls.",
          },
          {
            title: "Bug Detection and Fixing",
            description:
              "Locate and provide guidance on fixing any bugs that could be exploited.",
          },
          {
            title: "Security Measure Implementation",
            description:
              "Recommendations for adding security controls where needed.",
          },
          {
            title: "Final Reporting",
            description:
              "Provide a detailed report on findings and remediation steps.",
          },
        ],
        commitment: [
          "Source Code Review Report",
          "Vulnerability Analysis",
          "Code snippets with Examples",
          "Remediation recommendations",
          "Security Controls Assessment",
          "Best Practices Documentation",
          "Executive summary for stakeholders",
        ],
      },
      {
        name: "Server and Endpoint Hardening",
        slug: "server-and-endpoint-hardening",
        service_description: [
          "Fortify your servers and endpoints to prevent cyber threats through comprehensive hardening measures.",
        ],
        description:
          "Fortify your servers and endpoints to prevent cyber threats through comprehensive hardening measures.",
        strategy: [
          {
            title: "Security Assessment Initialization",
            description:
              "A thorough analysis to identify vulnerabilities in your servers and endpoints.",
          },
          {
            title: "Firewall and Access Control Setup",
            description:
              "Implement robust firewalls and access controls to prevent unauthorized access.",
          },
          {
            title: "Patch Management",
            description:
              "Regular updates and patches to secure systems against emerging threats.",
          },
          {
            title: "Software Removal and Optimization",
            description:
              "Remove unnecessary software and applications to minimize attack surfaces.",
          },
          {
            title: "Monitoring and Inventory Management",
            description:
              "Continuous monitoring of network assets to detect and mitigate risks.",
          },
        ],
        commitment: [
          "Hardening Plan and Recommendations",
          "Baseline configuration documentation",
          "Patch Management Strategy",
          "Endpoint and Server Hardening Scripts for Different Operating Systems",
          "Hardened OS Images",
          "Guidance on recommendations and remediation",
        ],
      },
      {
        name: "Hardware Device Security Assessment",
        slug: "hardware-device-security-assessment",
        service_description: [
          "Secures Internet of Things (IoT) devices and embedded systems against potential threats.",
          "Conducts a comprehensive assessment to identify and mitigate risks.",
          "Focuses on risks such as weak passwords, Bluetooth and Wi-Fi vulnerabilities, and insecure network services.",
        ],
        description:
          "Secure your Internet of Things (IoT) devices and embedded systems against potential threats. Our comprehensive assessment focuses on identifying and mitigating risks such as weak passwords, Bluetooth and Wi-Fi vulnerabilities, and insecure network services.",
        strategy: [
          {
            title: "Understanding Hardware / IoT Devices",
            description:
              "We thoroughly analyse your hardware and IoT devices to understand their functionality and tailor our assessment to your specific setup.",
          },
          {
            title: "Attack Surface Identification",
            description:
              "We identify all potential vulnerabilities in your devices, ensuring a comprehensive security analysis.",
          },
          {
            title: "Threat Modelling",
            description:
              "We create a detailed plan to address potential threats to your devices, guiding our assessment process.",
          },
          {
            title: "Non-Destructive Testing",
            description:
              "We perform extensive testing on your devices without causing any damage, focusing on their configurations and network interactions to find security weaknesses.",
          },
          {
            title: "Destructive Testing",
            description:
              "We conduct controlled tests that simulate real-world attacks, revealing vulnerabilities that may not be visible in standard testing scenarios.",
          },
        ],
        commitment: [
          "Firmware and Embedded System Analysis Report",
          "Hardware Security Assessment Report (Destructive)",
          "Hardware Security Assessment Report (Non-Destructive, Application & Network)",
          "Remediation Guidelines & Recommendations",
          "Hardware Security Best Practices Guide",
        ],
      },
      {
        name: "IoT Security Testing",
        slug: "iot-security-testing",
        service_description: [
          "Ensures Internet of Things (IoT) devices and systems are secure.",
          "Identifies and addresses potential vulnerabilities that could be exploited by attackers.",
        ],
        description:
          "Ensure your Internet of Things (IoT) devices and systems are secure by identifying and addressing potential vulnerabilities that could be exploited by attackers.",
        strategy: [
          {
            title: "IoT Device Exploration",
            description:
              "In-depth analysis of your IoT devices, including their firmware, communication protocols, and architecture.",
          },
          {
            title: "Vulnerability Assessment",
            description:
              "Automated and manual testing to uncover security flaws in your IoT systems.",
          },
          {
            title: "Risk Assessment",
            description:
              "Evaluate the impact of identified vulnerabilities and prioritize remediation efforts.",
          },
          {
            title: "Compliance Checks",
            description:
              "Ensure your IoT devices meet relevant security standards and regulations.",
          },
          {
            title: "Reporting and Recommendations",
            description:
              "Provide a detailed report on vulnerabilities and actionable recommendations for improvement.",
          },
        ],
        commitment: [
          "IoT Security Testing Report",
          "Vulnerability Assessment Findings",
          "Risk Analysis and Prioritization",
          "Compliance Status Report",
          "Remediation Recommendations",
          "Ongoing Support and Guidance",
        ],
      },
      {
        name: "Product Security Assessment",
        slug: "product-security-assessment",
        service_description: [
          "A product security assessment ensures products and innovative technologies are developed with the highest security standards.",
          "Protects every aspect of the product ecosystem against evolving threats.",
          "Ensures consistent security across all components.",
        ],
        description:
          "A product security assessment is essential to ensure that your products and innovative technologies are developed while maintaining the highest security standards. This process protects every aspect of your product ecosystem against evolving threats, ensuring consistent security across all components.",
        strategy: [
          {
            title: "Comprehensive Product Ecosystem Analysis",
            description:
              "Our experts thoroughly examine your product ecosystem, including hardware, software, APIs, and integrations, to understand how they interact and identify potential security risks and vulnerabilities.",
          },
          {
            title: "In-Depth Technical Assessment and Testing",
            description:
              "We perform extensive technical evaluations using both automated and manual methods, including intrusion testing, static and dynamic analysis, and source code review, to uncover vulnerabilities in your product’s architecture and codebase.",
          },
          {
            title: "Third-party Library and Component Review",
            description:
              "We assess any third-party libraries or components integrated into your product for security compliance, potential vulnerabilities, compatibility, and licensing issues.",
          },
          {
            title: "Holistic Design and Compliance Assessment",
            description:
              "Beyond technical checks, we review the overall design of your product to identify compliance risks and security issues from a broader perspective, aligning security measures with business objectives.",
          },
          {
            title:
              "Identification of Security Gaps in Development and Deployment",
            description:
              "We evaluate your product development and deployment processes, including DevOps practices, code version control, and the deployment pipeline, to identify and address any security gaps, ensuring security is integrated at every stage.",
          },
        ],
        commitment: [
          "Product Security Assessment Report",
          "In-Depth Component Vulnerability Assessment (VA)",
          "Security Architecture Review",
          "Source Code Review Report",
          "Comprehensive Penetration Testing Report",
          "Data Privacy Assessment Report",
          "Remediation recommendations and best practices",
        ],
      },
    ],
  },
  {
    Id: "2",
    sImg: sImg2,
    title: "Cyber Protection Services",
    col: "col-lg-6",
    slug: "cyber-protection-services",
    service_description: [
      "Provides measures to safeguard digital assets from cyber threats.",
      "Includes strategies and technologies for preventing, detecting, and responding to cyber attacks.",
      "Services may encompass firewall management, intrusion detection systems, antivirus solutions, and incident response planning.",
    ],
    subtitle:
      "Safeguards digital assets with strategies for preventing, detecting, and responding to cyber threats, including firewall management and incident response.",
    description:
      "These services provide measures to safeguard digital assets from cyber threats. They include strategies and technologies for preventing, detecting, and responding to cyber attacks. Services might encompass firewall management, intrusion detection systems, antivirus solutions, and incident response planning.",
    services: [
      {
        name: "Cybersecurity Vulnerability Management",
        slug: "cybersecurity-vulnerability-management",
        service_description: [
          "66% of businesses experience a cyber attack each year, according to a report by the Ponemon Institute.",
          "ISO 27002 defines a vulnerability as a weakness in an asset that can be exploited by various threats.",
          "Identifying and managing these vulnerabilities is crucial to reducing the risk of cyberattacks and data breaches.",
        ],
        description:
          "Did you know that 66% of businesses experience a cyber attack each year, according to a report by the Ponemon Institute? ISO 27002 defines a vulnerability as a weakness in an asset that can be exploited by various threats. Identifying and managing these vulnerabilities is crucial to reducing the risk of cyberattacks and data breaches.",
        strategy: [
          {
            title: "Identifying and Prioritising Vulnerabilities",
            description:
              "We collaborate closely with your organisation to detect potential weaknesses that could expose your cybersecurity infrastructure to threats. This process involves comprehensive scanning to discover vulnerabilities and prioritise the assets that need immediate attention.",
          },
          {
            title: "Evaluating and Classifying Risks",
            description:
              "Using our advanced vulnerability management system, we assess and classify vulnerabilities by understanding their potential impact. Critical vulnerabilities that pose significant risks are flagged for urgent action to prevent exploitation.",
          },
          {
            title: "Mitigating and Resolving Vulnerabilities",
            description:
              "Once vulnerabilities are detected, evaluated, and categorised, our cybersecurity team focuses on mitigating these risks. We implement strategies to strengthen your defences, effectively reducing the likelihood of future cyber-attacks.",
          },
        ],
        commitment: [
          "Detailed vulnerability assessment reports",
          "Risk Classification and Prioritization",
          "Comprehensive Remediation Plans",
          "Patch Management and Update Reports",
          "Continuous vulnerability remediation tracking",
          "In-depth vulnerability trend analysis",
          "Executive summaries and visual dashboards",
        ],
      },
      {
        name: "Security Talent Acquisition and Strengthening",
        slug: "security-talent-acquisition-and-strengthening",
        service_description: [
          "32% of financial services firms experienced cyber attacks on their accounting and financial systems in the past year, according to a recent report by PwC.",
          "This statistic underscores the increasing importance of implementing comprehensive cybersecurity measures.",
          "Helps protect sensitive financial data and maintain organizational integrity.",
        ],
        description:
          "According to a recent report by PwC, 32% of financial services firms experienced cyber attacks on their accounting and financial systems in the past year. This statistic underscores the increasing importance of implementing comprehensive cybersecurity measures to protect sensitive financial data and maintain organisational integrity.",
        strategy: [
          {
            title: "Customised Talent Sourcing",
            description:
              "We collaborate with your organisation to understand your specific cybersecurity needs, developing a tailored strategy to source candidates with the right expertise, experience, and cultural fit for your team.",
          },
          {
            title: "Access to Cybersecurity Experts",
            description:
              "We provide access to a network of experienced cybersecurity professionals who can augment your team on a temporary or long-term basis. This approach allows you to leverage specialised skills and knowledge without the necessity of permanent hires.",
          },
          {
            title: "Efficient Team Integration",
            description:
              "We ensure new team members are seamlessly integrated into your existing cybersecurity team. Our professionals work closely with your current staff, aligning with your organisational goals and operational processes to achieve the best results.",
          },
        ],
        commitment: [
          "Creation of Comprehensive Job Descriptions and Role Definitions",
          "Rigorous candidate screening and assessment",
          "Curated candidate shortlisting and expert recommendations",
          "Flexible contract staffing and team augmentation services",
          "Continuous Skills Development and Training Programs",
          "Ongoing Performance Monitoring and Constructive Feedback",
          "Strategic succession planning and talent pipeline management",
        ],
      },
      {
        name: "Managed SOC Services",
        slug: "managed-soc-services",
        service_description: [
          "Our SOC Service provides comprehensive cybersecurity support.",
          "Offers proactive threat intelligence.",
          "Includes continuous monitoring of security threats.",
          "Provides rapid incident response to security incidents.",
          "Delivers ongoing security management.",
          "Aims to protect your organisation's critical assets and enhance your cybersecurity posture.",
        ],
        description:
          "Our SOC Service provides comprehensive cybersecurity support, offering proactive threat intelligence, continuous monitoring, rapid incident response, and ongoing security management to protect your organisation's critical assets and enhance your cybersecurity posture.",
        // strategy: [
        //   {
        //     title: "Defining Your SOC Vision",
        //     description:
        //       "Transform your cybersecurity approach by leveraging Advanced Security Operations Centers (SOCs) for proactive defence, efficient incident management, data-driven insights, and regulatory compliance.",
        //   },
        //   {
        //     title: "Enhancing Your SOC Capabilities",
        //     description:
        //       "We empower your organisation by combining advanced technology, skilled analysts, and proactive processes to safeguard your digital assets and counter emerging threats.",
        //   },
        //   {
        //     title: "Scalable Security Solutions",
        //     description:
        //       "Adapt your SOC capabilities to grow with your organization’s needs, whether expanding monitoring scope or integrating new security technologies.",
        //   },
        // ],
        offerings: [
          {
            title: "SIEM Support Services",
            description:
              "Get real-time security insights with our Managed Security Information and Event Management (SIEM) service, enabling streamlined threat detection and response.",
          },
          {
            title: "Behavioural Performance Analysis",
            description:
              "Understand user behaviour deeply with our behaviour analytics services, identifying anomalies and mitigating risks effectively.",
          },
          {
            title: "Round the Clock Monitoring",
            description:
              "Ensure your organisation is protected around the clock with 24x7 SOC monitoring, keeping you ahead of potential threats.",
          },
          {
            title: "Threat Detection and Analysis",
            description:
              "Implement proactive defence strategies with our threat intelligence and hunting services, staying ahead of potential attackers.",
          },
          {
            title: "Incident Action Plan",
            description:
              "Quickly resolve and mitigate security incidents with our rapid incident response team, minimising the impact on your organisation.",
          },
          {
            title: "Cyber Awareness Training",
            description:
              "Strengthen your organisation’s first line of defence with comprehensive security awareness training for your team.",
          },
          {
            title: "Digital Forensics",
            description:
              "Reveal hidden insights and preserve digital evidence with our forensic investigation services, ensuring accurate and thorough incident analysis.",
          },
          {
            title: "Security Process Automation",
            description:
              "Enhance efficiency and response times with our security orchestration and automation services, streamlining your security operations.",
          },
        ],
        // additional_info: [
        //   "Flexible monitoring models (8x5 or 24x7)",
        //   "Multi-tier, multi-tenant delivery",
        //   "Experienced SOC professionals",
        //   "Shared or dedicated resource models",
        //   "On-premises or cloud SIEM solutions",
        //   "Service Level Agreement (SLA) Management and Key Performance Indicators (KPI)",
        // ],
        knowledge_base: [
          {
            question: "What is SOC as a Service?",
            answer:
              "SOC-as-a-Service is a comprehensive security solution where an external provider manages and operates a Security Operations Center (SOC) on behalf of your organisation, offering continuous threat monitoring, incident response, and other essential security services.",
          },
          {
            question: "Why should I consider SOC-as-a-Service?",
            answer:
              "SOC-as-a-Service offers cost-effective security management, access to advanced technologies, 24x7 monitoring, rapid incident response, and the flexibility to scale with your business needs.",
          },
          {
            question: "What services are included in SOC-as-a-Service?",
            answer:
              "Typically, SOC-as-a-Service includes real-time threat monitoring, log analysis, incident detection and response, vulnerability management, threat intelligence, and security reporting.",
          },
          {
            question: "How does SOC-as-a-Service improve my security posture?",
            answer:
              "By continuously monitoring your systems for potential threats and providing prompt incident response, SOC-as-a-Service helps strengthen your overall security infrastructure and reduces the risk of cyberattacks.",
          },
          {
            question:
              "Is SOC-as-a-Service suitable for businesses of all sizes?",
            answer:
              "Yes, SOC-as-a-Service is tailored to meet the needs of businesses of any size, from small startups to large enterprises.",
          },
          {
            question: "How does SOC-as-a-Service differ from an in-house SOC?",
            answer:
              "SOC-as-a-Service eliminates the need for costly infrastructure and staffing investments, offering comprehensive security services managed by a specialised external team at a lower cost than building and maintaining an in-house SOC.",
          },
          {
            question:
              "Is SOC-as-a-Service compliant with industry regulations?",
            answer:
              "Yes, SOC-as-a-Service providers ensure compliance with industry standards such as GDPR, HIPAA, and PCI DSS, helping your organisation meet its regulatory requirements.",
          },
          {
            question:
              "How quickly can SOC-as-a-Service respond to security incidents?",
            answer:
              "SOC-as-a-Service providers use advanced technologies and dedicated teams to detect and respond to security incidents in real-time, minimising the impact and reducing risks swiftly.",
          },
          {
            question:
              "Can SOC-as-a-Service integrate with my existing security systems?",
            answer:
              "Yes, SOC-as-a-Service can be seamlessly integrated with your existing security infrastructure, enhancing your current capabilities while leveraging your existing investments.",
          },
        ],
      },
    ],
  },
  {
    Id: "3",
    sImg: sImg3,
    col: "col-lg-4",
    title: "Personalised Services for You",
    slug: "personalised-services-just-for-you",
    service_description: [
      "Offers tailored security solutions designed to meet the specific needs and challenges of an individual client.",
      "Involves customizing security strategies, tools, and support based on the unique requirements and risk profile of the client.",
      "Ensures a more effective and relevant approach to security.",
    ],
    subtitle:
      "Tailors security strategies and tools to meet the unique needs and risk profiles of individual clients for more effective protection.",
    description:
      "This service offers tailored security solutions designed to meet the specific needs and challenges of an individual client. It involves customizing security strategies, tools, and support based on the unique requirements and risk profile of the client, ensuring a more effective and relevant approach to security.",
    services: [
      {
        name: "Dark Web Intelligence Services",
        slug: "dark-web-intelligence-services",
        service_description: [
          "The dark web is a hotbed for stolen data, with sensitive information from major companies often ending up for sale.",
          "Your organization could unknowingly be a target, exposing confidential data to cybercriminals.",
          "It’s crucial to monitor the hidden corners of the internet to protect your business.",
          "Our monitoring services provide round-the-clock surveillance and alert you whenever your information is found on the hidden web.",
          "Helps protect your organization from potential threats.",
        ],
        description:
          "Did you know that the dark web is a hotbed for stolen data, with sensitive information from major companies often ending up for sale? Your organisation could unknowingly be a target, exposing confidential data to cybercriminals. To protect your business, it’s crucial to monitor these hidden corners of the internet. With our monitoring services and round-the-clock surveillance, you are alerted whenever your information is found on the hidden web, helping you protect your organisation from potential threats.",
        why_us: [
          {
            title: "Intelligence Gathering",
            description:
              "We collect intelligence from a variety of deep and dark web sources using scraping, APIs, manual methods, and other techniques. This includes monitoring platforms like TOR, I2P, ZeroNet, and paste sites.",
          },
          {
            title: "Data Analysis",
            description:
              "Using AI classifiers and advanced models like Natural Language Processing (NLP), we analyze thousands of posts to uncover leaked data and detect discussions about potential attacks.",
          },
          {
            title: "Risk Evaluation",
            description:
              "We assess the risks, vulnerabilities, and potential malicious activities related to your executives, brand, customers, and vendors. Our experts provide actionable advice to enhance your security operations.",
          },
          {
            title: "Alert Delivery",
            description:
              "High-quality alerts are provided by our Security Operations Centre (SOC) to notify you of illegal activities such as the sharing of customer information, login credentials, personal identification information, or other fraudulent activities.",
          },
        ],
        benefits: [
          {
            title: "Cyber Threat Intelligence",
            description:
              "Gain comprehensive threat intelligence to prioritise and mitigate risks effectively.",
          },
          {
            title: "Dark Web and Deep Web Monitoring",
            description:
              "Detect dark web threats using advanced technology to protect your assets.",
          },
          {
            title: "Attack Surface Management",
            description:
              "Proactively detect threats across web, mobile, cloud, IoT, and more.",
          },
          {
            title: "Vulnerability Management",
            description:
              "Stay ahead of security risks with real-time vulnerability monitoring.",
          },
          {
            title: "Brand Protection",
            description:
              "Safeguard your brand with rapid takedown solutions for online threats.",
          },
          {
            title: "Takedown and Disruption",
            description:
              "Effectively combat online fraud and cybercrime with powerful takedown services.",
          },
        ],
        key_features: [
          {
            title: "Flexible Monitoring",
            description:
              "Adaptable to various organisational needs with multi-tier and multi-tenant support.",
          },
          {
            title: "Experienced SOC Professionals",
            description:
              "Skilled professionals for early breach detection and rapid response.",
          },
          {
            title: "Early Breach Detection",
            description:
              "Quickly identify compromised data, including payment cards and endpoints.",
          },
        ],
        knowledge_base: [
          {
            question: "What are the benefits of dark and deep web monitoring?",
            answer:
              "It helps detect stolen information early, identifies potential threats, and allows organisations to take steps to minimise data theft and protect against cyber attacks.",
          },
          {
            question: "How is the deep web different from the dark web?",
            answer:
              "The deep web contains non-indexed sites with sensitive data, while the dark web requires special software for access and often hosts illegal content and marketplaces.",
          },
          {
            question: "How do you monitor the dark and deep web?",
            answer:
              "Our experts use advanced technology to scan the deep and dark web, identifying any exposure of your sensitive information to prevent data breaches and cyber attacks.",
          },
          {
            question: "Is deep and dark web monitoring worth it?",
            answer:
              "Yes, it alerts you when your sensitive information is found beyond the surface web, giving you peace of mind and enabling timely action to protect your data.",
          },
          {
            question:
              "Why does my organisation need dark and deep web monitoring?",
            answer:
              "Monitoring helps detect your organisation's private data on the dark and deep web, enabling quick action to prevent data breaches and protect against cybercriminals.",
          },
          {
            question: "What happens if I don’t monitor the deep and dark web?",
            answer:
              "Without monitoring, your organisation’s sensitive data could be exposed on the dark web, leading to unawareness of breaches and potential threats for months or years.",
          },
        ],
      },
      {
        name: "Virtual Security Advisory Services",
        slug: "virtual-security-advisory-services",
        service_description: [
          "Ransomware attacks have surged globally, with a 300% increase in incidents over the past year.",
          "Countries like India, Austria, and the US are particularly vulnerable, with 68% of Indian organizations reporting ransomware attacks.",
          "In this high-risk environment, having a dedicated cybersecurity leader is crucial.",
          "Not all businesses can afford a full-time Chief Information Security Officer (CISO).",
          "Virtual Security Advisory Services offers a strategic, cost-effective solution.",
          "Delivers expert cybersecurity guidance and leadership tailored to your needs without the overhead of a permanent executive.",
        ],
        description:
          "Did you know that ransomware attacks have surged globally, with a 300% increase in incidents over the past year? Countries like India, Austria, and the US are particularly vulnerable, with 68% of Indian organisations reporting ransomware attacks. In this high-risk environment, having a dedicated cybersecurity leader is crucial. However, not all businesses can afford a full-time Chief Information Security Officer (CISO). Virtual Security Advisory Services offers a strategic, cost-effective solution, delivering expert cybersecurity guidance and leadership tailored to your needs without the overhead of a permanent executive.",
        offerings: [
          {
            title: "Strategic Cybersecurity Leadership",
            description:
              "We provide strategic cybersecurity guidance, working closely with your leadership team to align security initiatives with your business goals.",
          },
          {
            title: "Risk Management and Compliance",
            description:
              "With our service, you can effectively manage cyber risks and stay compliant with regulations. We conduct thorough risk assessments, develop risk mitigation strategies, and guide you through complex compliance requirements.",
          },
          {
            title: "Incident Response and Crisis Management",
            description:
              "In the event of a cybersecurity incident, our service leads your organisation’s response and crisis management efforts. With extensive experience in handling security incidents, we minimise damage and quickly restore normal operations.",
          },
        ],
      },
      {
        name: "Crisis Response and Malware Evaluation",
        slug: "crisis-response-and-malware-evaluation",
        service_description: [
          "On average, four companies face ransomware attacks every minute.",
          "Specialized incident response and malware analysis services help organizations quickly detect, respond to, and recover from cybersecurity incidents.",
          "Expert team uses advanced tools and methodologies to handle complex security breaches.",
          "Conducts thorough malware analysis to minimize impact and restore normal operations rapidly.",
        ],
        description:
          "On average, four companies face ransomware attacks every minute. Our specialised incident response and malware analysis services are designed to help organisations quickly detect, respond to, and recover from cybersecurity incidents. Our expert team uses advanced tools and methodologies to handle complex security breaches and conduct thorough malware analysis, minimising impact and restoring normal operations rapidly.",
        how_we_operate: [
          {
            title: "Review your requirements",
            description:
              "We establish a communication channel and gather essential information about your environment, systems, and network. We also prepare our tools and resources for immediate analysis.",
          },
          {
            title: "Study your system",
            description:
              "We investigate the incident using static and dynamic malware analysis to examine suspicious files or URLs. Forensic evidence from affected systems, such as logs and memory dumps, is collected and analysed.",
          },
          {
            title: "Damage Control",
            description:
              "We isolate compromised systems from the network and apply countermeasures to halt malicious activity. We also provide guidance on preventing further infection or spread.",
          },
          {
            title: "System Resolution",
            description:
              "Malware and attack remnants are removed from affected systems. We restore the normal functionality and integrity of your systems and network. We monitor the situation to ensure complete resolution and prevent recurrence. We also offer recommendations to strengthen your security controls and policies.",
          },
          {
            title: "Issue Report",
            description:
              "A detailed report covering the incident timeline, root cause, impact, response actions, and lessons learnt is prepared. We share our findings and provide further guidance as needed.",
          },
        ],
        why_us: [
          {
            title: "Our Expertise",
            description:
              "Extensive experience in handling diverse incidents, including ransomware, APTs, and zero-day exploits.",
          },
          {
            title: "Advanced Tools",
            description:
              "We use cutting-edge technologies like sandboxing, disassemblers, and network analysers for precise analysis.",
          },
          {
            title: "Comprehensive Approach",
            description:
              "A hybrid of static and dynamic analysis reveals hidden behaviours and functions of malware.",
          },
          {
            title: "Tailored Solutions",
            description:
              "Customised strategies and flexible pricing to fit your specific needs and budget.",
          },
        ],
        commitment: [
          "Post-incident detection",
          "Ransomware encryption",
          "Threat Alerts and Triage",
          "Incident Response",
          "Threat Hunting",
          "Malware Research",
        ],
      },
      {
        name: "Phishing and Ransomware Attack Exercises",
        slug: "phishing-and-ransomware-attack-exercises",
        service_description: [
          "Ransomware damages are expected to grow by 30% annually, potentially reaching $265 billion by 2031.",
          "An attack is anticipated to happen every two seconds.",
          "Phishing & Ransomware Simulations service helps your organization assess and strengthen employee readiness against phishing and ransomware attacks.",
          "Simulates realistic scenarios to empower your team to detect and respond effectively.",
          "Boosts your overall cybersecurity.",
        ],
        description:
          "Ransomware damages are expected to grow by 30% annually, potentially reaching $265 billion by 2031, with an attack happening every two seconds. To counter these rising threats, our Phishing & Ransomware Simulations service helps your organisation assess and strengthen employee readiness against phishing and ransomware attacks. By simulating realistic scenarios, we empower your team to detect and respond effectively, boosting your overall cybersecurity.",
        how_do_we_work: [
          "We collaborate to define your objectives and design tailored simulation scenarios. Specific target groups, including executives, are identified for focused testing",
          "We analyse data from simulations, including click rates, response rates, and error rates. Feedback from participants and stakeholders is also collected.",
          "A comprehensive report is provided, detailing key metrics, strengths, weaknesses, and areas for improvement. We also share best practices and answer any questions",
          "Follow-up training is customised based on your organisation's needs, offering tips and resources to strengthen your security posture against future attacks.",
        ],
        why_us: [
          {
            title: "Expertise",
            description:
              "Extensive experience in conducting thousands of phishing and ransomware simulations across various industries.",
          },
          {
            title: "Advanced Tools",
            description:
              "We utilise cutting-edge technologies like sandboxing and virtualisation to create realistic scenarios that challenge your team's skills.",
          },
          {
            title: "Tailored Solutions",
            description:
              "Customisable, flexible solutions with transparent, affordable pricing to match your needs and budget.",
          },
          {
            title: "Privacy and Confidentiality",
            description:
              "We uphold the highest ethical standards, ensuring your information remains secure and undisclosed without consent.",
          },
        ],
        how_it_will_help: [
          "Assess your team's ability to recognise and report phishing and ransomware attempts.",
          "Discover and address vulnerabilities in your security culture and policies.",
          "Educate employees on avoiding and responding to cyberattacks.",
          "Minimise the risk and impact of data breaches and financial losses.",
          "Track click rates, response rates, reporting rates, and error rates for benchmarking.",
        ],
      },
      {
        name: "Cyber Insurance Strategy Consulting",
        slug: "cyber-insurance-strategy-consulting",
        service_description: [
          "Global cybercrime costs are expected to reach $10.5 trillion annually by 2025 due to rising cyberattacks and data breaches.",
          "Offers specialized cyber insurance consulting services to safeguard your company from financial losses caused by cyber threats.",
          "Ensures that you find the right coverage to protect your organization.",
        ],
        description:
          "With cyberattacks and data breaches on the rise, global cybercrime costs are expected to reach $10.5 trillion annually by 2025. Is your business prepared for the potential financial impact? We offer specialised cyber insurance consulting services to safeguard your company from financial losses caused by cyber threats. Our approach ensures that you find the right coverage to protect your organisation.",
        we_achieve_it_by: [
          {
            title: "Thorough Risk Assessment",
            description:
              "We begin by conducting a detailed evaluation of your cybersecurity framework. Our experts identify potential vulnerabilities and assess the risks your organisation may face. This in-depth analysis allows us to recommend the most appropriate cyber liability insurance tailored to your specific needs.",
          },
          {
            title: "Tailored Insurance Solutions",
            description:
              "Our team of seasoned professionals leverages extensive industry knowledge to guide you in selecting the ideal cyber insurance policy. We focus on your organisation's unique risks, ensuring that the chosen policy aligns with your requirements. We also provide clear explanations of how cyber insurance works and how to maximise its benefits.",
          },
          {
            title: "Efficient Claim Support",
            description:
              "Navigating the cyber insurance claim process can be complex and time-consuming. Our consultants streamline this process, assisting you with documentation and paperwork to ensure quick and hassle-free claim settlements. Our goal is to make the entire experience as smooth as possible, providing you with peace of mind.",
          },
        ],
        why_us: [
          {
            title: "Our Deep Domain Knowledge",
            description:
              "We bring extensive expertise in cybersecurity, offering comprehensive solutions that address your organisation's specific needs.",
          },
          {
            title: "Our Research-Driven Approach",
            description:
              "Our team uses a hands-on, research-orientated approach to identify and address cybersecurity threats, ensuring effective solutions.",
          },
        ],
        commitment: [
          "Cyber Insurance Consulting",
          "Cyber Risk Assessment Report",
          "Gap Analysis and Recommendations",
          "Insurance Coverage Review",
          "Risk mitigation strategies",
          "Insurer documentation",
          "Custom Cyber Insurance Roadmap",
        ],
      },
      {
        name: "Digital Crime Investigation and Forensic Examination",
        slug: "digital-crime-investigation-and-forensic-examination",
        service_description: [
          "Cyber threats are more prevalent than ever, with cyberattacks occurring every few seconds.",
          "The rise in cybercrime has made it critical to have robust systems for investigation and forensics.",
          "Effective cybercrime investigation and digital forensics are essential for identifying perpetrators, securing evidence, and preventing future incidents.",
          "Offers comprehensive solutions in cybercrime investigation and digital forensics.",
          "Combines advanced technology with expert analysis to help you tackle digital threats and protect your business.",
        ],
        description:
          "In today's digital age, cyber threats are more prevalent than ever, with cyberattacks occurring every few seconds. The rise in cybercrime has made it critical to have robust systems for investigation and forensics. Effective cybercrime investigation and digital forensics are essential for identifying perpetrators, securing evidence, and preventing future incidents. We offer comprehensive solutions in cybercrime investigation and digital forensics, combining advanced technology with expert analysis to help you tackle digital threats and protect your business.",
        how_we_handle_investigations: [
          {
            title: "Advanced Digital Investigation",
            description:
              "Our seasoned investigators bring years of experience in handling cybercrime cases. We assess the impact on your sensitive data, identify cybercriminals, and provide a detailed report outlining the investigation's findings and recommended actions.",
          },
          {
            title: "Data Collection and Analysis",
            description:
              "Our cybersecurity analysts and digital forensic experts thoroughly examine your organisation's devices and systems. We analyse encrypted data, digital media, and networking channels to collect vital evidence, which can be used in legal proceedings.",
          },
          {
            title: "Minimising Potential Risks",
            description:
              "Leveraging our expertise, we help implement additional security measures to assess and mitigate potential risks. By addressing vulnerabilities, we empower your organisation to reduce the likelihood of future cybercrime incidents.",
          },
        ],
      },
    ],
  },
  {
    Id: "4",
    sImg: sImg4,
    col: "col-lg-4",
    title: "Industrial Security Assessment Services",
    slug: "industrial-security-assessment-services",
    service_description: [
      "Focuses on the security of industrial control systems (ICS) and operational technology (OT).",
      "Evaluates the security posture of critical infrastructure, including manufacturing processes, energy grids, and transportation systems.",
      "Aims to protect against threats that could disrupt operations or cause physical damage.",
    ],
    subtitle:
      "Evaluates the security of critical infrastructure, including industrial control systems, to prevent operational disruptions or physical damage.",
    description:
      "These assessments focus on the security of industrial control systems (ICS) and operational technology (OT). They evaluate the security posture of critical infrastructure, including manufacturing processes, energy grids, and transportation systems, to protect against threats that could disrupt operations or cause physical damage.",
    services: [
      {
        name: "ICS/OT Security Audit",
        slug: "ics-ot-security-audit",
        service_description: [
          "A cyberattack occurs every 39 seconds, affecting thousands of businesses daily.",
          "These breaches can cost companies millions and damage their reputation.",
          "Proactively investing in robust security measures helps safeguard your business from potential threats.",
          "Ensures the privacy and trust of your customers.",
          "Encourages enhancement of cybersecurity defenses with comprehensive security assessment services.",
          "Protect your data, protect your future.",
        ],
        description:
          "Did you know that a cyberattack occurs every 39 seconds, affecting thousands of businesses daily? These breaches can cost companies millions and damage their reputation. However, by proactively investing in robust security measures, you can safeguard your business from potential threats and ensure the privacy and trust of your customers. Don't wait until it's too late—enhance your cybersecurity defences today with our comprehensive security assessment services. Protect your data, protect your future.",
        why_us: [
          {
            title: "Understanding System Vulnerabilities",
            description:
              "We perform a thorough analysis of your company’s OT (operational technology) and ICS (industrial control systems) to understand the infrastructure and identify vulnerabilities. This helps us recognise security gaps that could make systems easy targets for cyber attacks and data breaches. Regular assessments allow us to pinpoint weaknesses and enhance your defence mechanisms.",
          },
          {
            title: "Risk Assessment and Analysis",
            description:
              "Our team uses advanced techniques to evaluate potential risks within your OT/ICS systems. By understanding key vulnerabilities, we can address internal and external threats effectively. We perform rigorous testing to assess the likelihood of cyber attacks, enabling us to implement robust security measures.",
          },
          {
            title: "Network Security and Segmentation",
            description:
              "We conduct comprehensive network security checks to evaluate your current defences against cyber threats. Based on our findings, we recommend additional network segmentation strategies to protect OT/ICS systems from insider threats and reduce the attack surface in the event of a cyber attack. This approach helps create a more resilient network defence architecture.",
          },
        ],
        how_we_strengthen_your_security: [
          {
            title: "OT Asset Inventory",
            description:
              "Gain full visibility and control over your OT assets with our advanced OT Asset Inventory solution. This platform enhances asset management, cybersecurity, and overall performance, ensuring secure and efficient operations.",
          },
          {
            title: "Network Segmentation Review",
            description:
              "Improve your cybersecurity with our expert network segmentation review. We conduct a detailed analysis to ensure a robust network structure, minimising vulnerabilities and enhancing your digital defences.",
          },
          {
            title: "Security Device Configuration Review",
            description:
              "Optimise your security infrastructure with our configuration review service. Our experts fine-tune device settings to maximise performance and eliminate potential risks, aligning your defences with your business needs.",
          },
          {
            title: "Threat Detection in Production ICS Network",
            description:
              "Enhance your threat detection capabilities by monitoring your production ICS network. Our cutting-edge solutions provide real-time insights to help you identify and mitigate potential threats, ensuring the resilience of your critical infrastructure.",
          },
          {
            title: "Vulnerability Assessment",
            description:
              "Strengthen your cybersecurity posture with our comprehensive vulnerability assessment. We identify and address potential threats quickly, ensuring your systems remain secure and resilient.",
          },
          {
            title: "Comprehensive Reporting and Presentation",
            description:
              "Receive a detailed report and professional presentation summarising our findings and recommendations. We provide clear, actionable insights to enhance your security strategy.",
          },
        ],
        commitment: [
          "Access to the Latest Scanning Technologies and Methodologies",
          "Rigorous Security Testing",
          "Zero False Positive Guarantee",
          "Accurate, Actionable Reports Delivered Promptly",
          "Flexible On-Demand or On-Premise Solutions",
          "Customised Solutions to Fulfil Your Needs",
        ],
      },
    ],
  },
  {
    Id: "5",
    col: "col-lg-4",
    sImg: sImg5,
    title: "Security Governance and Compliance Services",
    slug: "security-governance-and-compliance-services",
    service_description: [
      "Helps organizations establish and maintain security policies, procedures, and practices that align with regulatory requirements and industry standards.",
      "Involves creating frameworks for managing security risks.",
      "Ensures compliance with laws.",
      "Conducts audits to verify adherence to security protocols.",
    ],
    subtitle:
      "Establishes and maintains security frameworks to align with regulations and standards, ensuring compliance and conducting audits.",
    description:
      "This service helps organizations establish and maintain security policies, procedures, and practices that align with regulatory requirements and industry standards. It involves creating frameworks for managing security risks, ensuring compliance with laws, and conducting audits to verify adherence to security protocols.",
    services: [
      {
        name: "Cyber Risk and Maturity Evaluation",
        slug: "cyber-risk-and-maturity-evaluation",
        service_description: [
          "Cyber threats are ever-present, with over 2,200 attacks occurring daily—one every 39 seconds on average.",
          "Understanding your organization's cyber risk and identifying security gaps are essential steps in building a strong defense.",
          "Evaluating your security maturity is crucial for enhancing cybersecurity.",
          "Provides a comprehensive view of your cybersecurity landscape.",
          "Empowers you to make informed decisions and implement effective security measures.",
        ],
        description:
          "Cyber threats are ever-present, with over 2,200 attacks occurring daily—one every 39 seconds on average. Understanding your organisation's cyber risk, identifying security gaps, and evaluating your security maturity are essential steps in building a strong defence against these threats.We provide a comprehensive view of your cybersecurity landscape, empowering you to make informed decisions and implement effective security measures.",
        strategy: [
          {
            title: "In-Depth Risk Analysis",
            description:
              "We conduct a detailed assessment of your organisation's cyber risk environment, identifying vulnerabilities, assessing threat likelihood and impact, and evaluating overall risk exposure. This analysis enables you to focus your security efforts where they are most needed, ensuring maximum protection of your critical assets.",
          },
          {
            title: "Identifying and Closing Security Gaps",
            description:
              "Our assessment uncovers gaps in your current infrastructure, policies, and processes. We thoroughly examine your security controls, incident response capabilities, employee awareness, and compliance measures. Following this, we provide actionable recommendations and a clear roadmap to help you address these gaps, strengthening your security posture.",
          },
          {
            title: "Tailored Solutions",
            description:
              "Recognizing that each organisation’s cybersecurity needs are unique, we develop customised solutions aligned with your specific requirements. Our approach ensures comprehensive protection against cyber threats, tailored to your organisation’s particular risk landscape.",
          },
          {
            title: "Expertise and Experience",
            description:
              "Our team consists of seasoned cybersecurity professionals with deep industry knowledge. We bring extensive experience and a thorough understanding of regulatory guidelines to help fortify your defences effectively.",
          },
          {
            title: "Ensuring Regulatory Compliance",
            description:
              "Maintaining compliance with industry regulations is crucial. We prioritise regulatory assessments and ongoing monitoring to ensure your organisation stays fully compliant with the latest guidelines and standards.",
          },
          {
            title: "Implementing Robust Cybersecurity Frameworks",
            description:
              "We help you implement advanced cybersecurity frameworks that incorporate best practices and industry standards. This empowers your organisation to proactively respond to threats and safeguard your valuable assets effectively.",
          },
          {
            title: "Continuous Monitoring and Support",
            description:
              "Our commitment goes beyond the initial assessment. We offer continuous monitoring and support to ensure your security measures remain effective against evolving threats and regulatory changes.",
          },
        ],
        commitment: [
          "Comprehensive Evaluation of Your Security Architecture, Infrastructure, and Technologies",
          "Identification of Enterprise Gaps, Weaknesses, and Risks",
          "A Detailed List of Key Control Areas Needing Enhancement",
          "A Clear Roadmap to Achieve and Maintain Security Readiness",
          "Thorough Analysis of Your Current Security State and Identified Gaps",
          "Evaluation of Your Security Maturity Level",
          "Strategic Recommendations for Improvement",
          "Comprehensive Documentation of All Findings and Assessments",
        ],
      },
      {
        name: "Cyber Risk Insurance Advisory",
        slug: "cyber-risk-insurance-advisory",
        service_description: [
          "Cybercrime continues to rise, with projected global costs reaching $10.5 trillion annually by 2025.",
          "Ensuring your organization is protected from financial losses due to cyberattacks is critical.",
          "Provides expert cyber insurance consulting to help safeguard your company from the financial impact of cyber theft and data breaches.",
          "Offers tailored cyber insurance solutions to meet your specific needs.",
        ],
        description:
          "As cybercrime continues to rise, with projected global costs reaching $10.5 trillion annually by 2025, ensuring your organisation is protected from financial losses due to cyberattacks is critical. We provide expert cyber insurance consulting to help safeguard your company from the financial impact of cyber theft and data breaches through tailored cyber insurance solutions.",
        strategy: [
          {
            title: "Evaluation and Risk Assessment",
            description:
              "Our experts conduct a thorough evaluation of your cybersecurity systems to identify vulnerabilities and potential risks. This assessment allows us to recommend the most suitable cyber liability insurance for your organisation.",
          },
          {
            title: "Finding the Right Cyber Insurance",
            description:
              "With extensive industry experience, our consulting team identifies the specific risks your organisation faces and guides you in selecting a reliable cyber insurance policy that aligns with your needs. We ensure you understand how cyber insurance works and how to maximise its benefits.",
          },
          {
            title: "Claim Assistance",
            description:
              "Navigating the cyber insurance claim process can be complex. Our team simplifies this by offering comprehensive claim assistance, helping you with documentation, paperwork, and ensuring a smooth and swift settlement process.",
          },
        ],
        commitment: [
          "Expert Cyber Insurance Consulting",
          "Comprehensive Cyber Risk Assessment Report",
          "Detailed Gap Analysis with Actionable Recommendations",
          "Thorough Insurance Coverage Analysis",
          "Strategic Risk Mitigation Plans",
          "Complete Documentation Support for Insurers",
          "Tailored Cyber Insurance Roadmap",
        ],
      },
      {
        name: "Bank Cybersecurity Compliance with RBI Guidelines",
        slug: "bank-cybersecurity-compliance-with-rbi-guidelines",
        service_description: [
          "The report by cybersecurity firm Zscaler showed a 60% rise in global phishing attacks over the past year.",
          "India experienced over 79 million phishing attacks, making it the third most targeted country.",
          "This surge underscores the urgent need for banks to strengthen their cybersecurity measures against increasingly sophisticated threats.",
          "Specializes in helping banks navigate the complex landscape of cybersecurity regulations set by the Reserve Bank of India (RBI).",
          "Ensures your institution is well-protected against emerging cyber threats.",
        ],
        description:
          "The report by cybersecurity firm Zscaler showed a 60% rise in global phishing attacks over the past year. And India experienced over 79 million phishing attacks, making it the third most targeted country, it noted. This surge underscores the urgent need for banks to strengthen their cybersecurity measures to combat an increasingly sophisticated threat landscape. We specialise in helping banks navigate the complex landscape of cybersecurity regulations set by the Reserve Bank of India (RBI), ensuring your institution is well-protected against emerging cyber threats.",
        why_us: [
          {
            title: "Customised Solutions",
            description:
              "Recognizing that every bank’s cybersecurity needs are different, we collaborate closely with your team to develop tailored solutions that address your unique challenges and safeguard against cyber threats.",
          },
          {
            title: "Expert Knowledge and Experience",
            description:
              "Our team consists of seasoned cybersecurity professionals with extensive experience in the banking sector and a thorough understanding of RBI’s cybersecurity guidelines. You can trust us to deliver the expertise needed to strengthen your defences.",
          },
          {
            title: "Regulatory Compliance Excellence",
            description:
              "Ensuring your bank meets and exceeds RBI cybersecurity standards is our top priority. Through detailed assessments and ongoing support, we help your institution remain compliant with all regulatory requirements.",
          },
          {
            title: "Advanced Cybersecurity Frameworks",
            description:
              "We assist in implementing state-of-the-art cybersecurity frameworks that incorporate industry best practices, enabling your bank to proactively defend against threats and protect critical assets.",
          },
          {
            title: "Ongoing Support",
            description:
              "Our commitment doesn’t end with implementation. We provide continuous monitoring and support to ensure your security measures stay effective amidst evolving threats and regulatory changes.",
          },
        ],
        commitment: [
          "Comprehensive Security Policies and Procedures",
          "Network and Infrastructure Security Enhancement",
          "Thorough Risk Assessment and Management Strategies",
          "Detailed Security Audits and Evaluations",
          "Effective Incident Response and Management Plans",
          "Robust Data Protection and Privacy Measures",
          "Accurate Compliance Reporting",
          "Third-Party Security Risk Assessment",
        ],
        how_we_do_it: [
          {
            title: "Continuous Monitoring and Support",
            description:
              "We provide ongoing surveillance and support to ensure your bank remains in continuous compliance with RBIs cybersecurity mandates. Our team assists in setting up effective monitoring systems, conducting regular security reviews, and staying ahead of emerging threats and regulatory updates. We are dedicated to maintaining a robust cybersecurity stance for your institution.",
          },
          {
            title: "Compliance Evaluation",
            description:
              "Our experienced professionals perform a comprehensive review of your bank's current cybersecurity measures, policies, and controls. We benchmark them against RBI’s guidelines to identify gaps and areas needing enhancement. This assessment offers critical insights into your compliance status and helps chart a path to achieving and sustaining full RBI cybersecurity compliance.",
          },
          {
            title: "Cybersecurity Framework Implementation",
            description:
              "We assist banks in deploying a resilient cybersecurity framework that adheres to SEBI's standards. Our experts help you establish robust policies, develop effective incident response strategies, enhance access controls, and bolster data protection efforts. By implementing these frameworks, your bank can ensure a secure operational environment and meet SEBI's cybersecurity requirements.",
          },
        ],
      },
      {
        name: "Cybersecurity Compliance with SEBI Regulations",
        slug: "cybersecurity-compliance-with-sebi-regulations",
        service_description: [
          "Offers comprehensive support to help your organization meet the Securities and Exchange Board of India (SEBI) cybersecurity compliance requirements.",
          "Ensures the establishment of strong security protocols.",
          "Helps safeguard investor data.",
          "Ensures adherence to SEBI’s regulations.",
        ],
        description:
          "We offer comprehensive support to help your organisation meet the Securities and Exchange Board of India (SEBI) cybersecurity compliance requirements. Our expertise ensures you establish strong security protocols, safeguard investor data, and adhere to SEBI’s regulations.",
        how_we_do_it: [
          {
            title: "Regulatory Compliance Assessment",
            description:
              "Our team conducts an in-depth evaluation of your current cybersecurity practices, policies, and controls against SEBI’s regulations. This assessment identifies gaps and areas for improvement, providing valuable insights to develop a roadmap for achieving and maintaining SEBI compliance.",
          },
          {
            title: "Data Protection and Privacy Measures",
            description:
              "We help implement strong data protection and privacy measures to safeguard investor information in line with SEBI regulations. Our regular audits ensure ongoing compliance, maintaining investor trust and regulatory adherence.",
          },
          {
            title: "Incident Response and Threat Monitoring",
            description:
              "We specialise in developing effective incident response plans and continuous threat monitoring. Our services include tailored response frameworks and security incident simulations to prepare your organisation for potential breaches and refine your response capabilities.",
          },
        ],
        why_us: [
          {
            title: "Comprehensive Support for SEBI Cyber Security Compliance",
            description:
              "We offer complete support to help you meet SEBI’s cybersecurity requirements, ensuring robust security measures and regulatory compliance.",
          },
          {
            title: "Thorough Regulatory Compliance Assessment",
            description:
              "Our thorough assessment identifies gaps in your cybersecurity measures and provides a roadmap to achieve and maintain SEBI compliance.",
          },
          {
            title: "Data Protection and Privacy Assurance",
            description:
              "We assist in implementing data protection measures and conduct regular audits to ensure compliance with SEBI’s guidelines, securing investor data and maintaining trust.",
          },
          {
            title: "Effective Incident Response and Threat Monitoring",
            description:
              "We help establish incident response frameworks and continuous monitoring to manage and mitigate security breaches, enhancing your organisation’s readiness and response.",
          },
        ],
        commitment: [
          "Legal and Regulatory Advisory",
          "Regulatory Compliance Assessment",
          "Security Policy Review and Development",
          "Vendor Risk Management",
          "Security Testing and Assessment",
          "Network Security Review",
          "Regulatory Reporting Documentation",
          "Periodic Compliance Audits",
        ],
        knowledge_base: [
          {
            question:
              "What are the key cybersecurity regulations issued by SEBI?",
            answer:
              "SEBI’s key regulations include the SEBI (Listing Obligations and Disclosure Requirements) Regulations, 2015, and various circulars outlining cybersecurity and resilience frameworks for market entities.",
          },
          {
            question:
              "Is SEBI Cyber Security Compliance mandatory for all organisations in the securities market?",
            answer:
              "Yes, SEBI compliance is mandatory for all organisations in the securities market. SEBI’s regulations require robust cybersecurity measures for all market participants.",
          },
          {
            question:
              "How does SEBI Cyber Security Compliance help in protecting investor data?",
            answer:
              "Compliance ensures the implementation of data protection measures, secure access controls, encryption, and incident response plans, thereby safeguarding investor data and enhancing market integrity.",
          },
          {
            question:
              "How does SEBI Cyber Security Compliance align with other regulatory requirements?",
            answer:
              "SEBI’s regulations often align with broader data protection and privacy laws, offering a comprehensive approach to cybersecurity that supports overall regulatory compliance.",
          },
          {
            question:
              "What are the common challenges organisations face in achieving SEBI Cyber Security Compliance?",
            answer:
              "Challenges include resource limitations, complex IT systems, evolving threats, and regulatory changes. Effective guidance and tailored solutions can help overcome these hurdles.",
          },
          {
            question:
              "What if I am not compliant with SEBI's cybersecurity regulations?",
            answer:
              "Non-compliance can result in penalties, fines, and regulatory actions that impact operations and reputation. SEBI can impose monetary fines, restrict activities, or enforce other measures to ensure compliance.",
          },
        ],
      },
      {
        name: "General Data Protection Regulations Readiness",
        slug: "general-data-protection-regulations-gdpr-readiness",
        service_description: [
          "The General Data Protection Regulation (GDPR) is a law that protects the personal data of individuals in the EU.",
          "Many companies, even outside the EU, have adjusted their data collection and processing practices to align with GDPR.",
          "Aligning with GDPR helps avoid potential fines and build trust with international customers.",
          "GDPR has inspired similar data protection laws in countries like Brazil, India, and Japan.",
          "Promotes global harmonization of privacy standards.",
        ],
        description:
          "The General Data Protection Regulation (GDPR) is a law that protects the personal data of individuals in the EU. Many companies, even outside the EU, have adjusted their data collection and processing practices to align with GDPR to avoid potential fines and build trust with international customers. GDPR has inspired similar data protection laws in countries like Brazil, India, and Japan, promoting global harmonisation of privacy standards.",
        how_we_do_it: [
          {
            title: "GDPR Compliance Assessment",
            description:
              "We conduct a detailed evaluation of your current data protection practices against GDPR standards. This helps identify any gaps and creates a customised plan to achieve and maintain compliance.",
          },
          {
            title: "Data Protection and Privacy Enhancements",
            description:
              "We assist in implementing strong data protection measures, such as updating privacy policies, applying privacy-by-design principles, conducting Data Protection Impact Assessments (DPIAs), and managing data subject rights. These actions ensure compliance with GDPR and help build trust with customers.",
          },
          {
            title: "Data Breach Response and Incident Management",
            description:
              "We support the development of effective incident response plans, conduct readiness exercises, and guide you on breach notification requirements under GDPR. Our proactive approach helps manage breaches efficiently and minimise their impact.",
          },
        ],
        why_us: [
          {
            title: "Tailored Solutions",
            description:
              "We provide customised solutions based on your organisation's specific needs to ensure thorough GDPR compliance.",
          },
          {
            title: "Expertise and Experience",
            description:
              "Our team consists of experts with extensive knowledge of GDPR, ensuring that your compliance strategies are both effective and practical.",
          },
          {
            title: "Regulatory Compliance Excellence",
            description:
              "We focus on achieving and maintaining GDPR compliance through in-depth assessments and ongoing support to address any evolving requirements.",
          },
          {
            title: "Robust Data Protection Frameworks",
            description:
              "We help implement robust frameworks that align with GDPR standards and best practices to protect personal data effectively.",
          },
          {
            title: "Continuous Support",
            description:
              "Our commitment includes ongoing support to adapt to regulatory changes and ensure your data protection measures remain effective.",
          },
        ],
        commitment: [
          "Data Protection Principles",
          "Rights of Data Subjects",
          "Legal Bases for Data Processing",
          "Responsibilities and Obligations of Controllers and Processors",
          "Data Mapping and Inventory",
          "Privacy Impact Assessments (DPIAs)",
          "Data Subject Rights Procedures",
          "Data Breach Response Plan",
        ],
        knowledge_base: [
          {
            question: "What are the key steps for GDPR implementation?",
            answer:
              "Key steps include reviewing data processes, updating privacy policies, conducting DPIAs, revising data processing agreements, implementing security measures, training staff, and setting up monitoring procedures for ongoing compliance.",
          },
          {
            question: "How can organisations maintain GDPR compliance?",
            answer:
              "Ongoing compliance requires regular audits, staying informed on regulatory changes, continuous staff training, effective data breach response plans, and routine updates to privacy policies and procedures.",
          },
          {
            question: "Is there a recognized GDPR certification?",
            answer:
              "No specific EU-recognized GDPR certification exists. However, organisations can seek voluntary certifications or adhere to approved codes of conduct to demonstrate their commitment to GDPR compliance.",
          },
          {
            question:
              "What challenges might arise in achieving GDPR compliance?",
            answer:
              "Challenges include resource limitations, complex data systems, outdated practices, managing data subject rights, cross-border data transfers, and keeping up with evolving regulations. Expert guidance can help navigate these obstacles.",
          },
          {
            question: "How does GDPR compliance benefit customers?",
            answer:
              "Compliance ensures that customers' personal data is protected and handled securely, fostering trust and demonstrating your commitment to privacy.",
          },
          {
            question: "Can GDPR compliance provide a competitive advantage?",
            answer:
              "Yes, GDPR compliance can set your organisation apart by showing your dedication to data protection, appealing to privacy-conscious customers, and enhancing your market position.",
          },
          {
            question:
              "Can personal data be transferred outside the EU under GDPR?",
            answer:
              "Yes, but only to countries with adequate data protection levels or through safeguards like SCCs, BCRs, or specific GDPR exemptions for such transfers.",
          },
        ],
      },
      {
        name: "PCI DSS Regulatory Compliance",
        slug: "pci-dss-regulatory-compliance",
        service_description: [
          "The Payment Card Industry Data Security Standard (PCI DSS) is a set of security standards for companies that process, store, or transmit credit card information.",
          "Ensures that a secure environment is maintained for handling credit card data.",
          "PCI DSS compliance is essential for protecting sensitive cardholder data from breaches.",
          "Reduces the risk of fraud and helps maintain trust with customers.",
          "Non-compliance can result in hefty fines ranging from $5,000 to $100,000 per month until compliance is achieved.",
          "The average cost of a data breach involving payment card information is around $3.86 million, according to IBM's Cost of a Data Breach Report.",
        ],
        description:
          "The Payment Card Industry Data Security Standard (PCI DSS) is a set of security standards designed to ensure that all companies that process, store, or transmit credit card information maintain a secure environment. PCI DSS compliance is essential because it protects sensitive cardholder data from breaches, reduces the risk of fraud, and helps maintain trust with customers. Non-compliance with PCI DSS can result in hefty fines ranging from $5,000 to $100,000 per month until compliance is achieved. Additionally, the average cost of a data breach involving payment card information is around $3.86 million, according to IBM's Cost of a Data Breach Report.",
        knowledge_base: [
          {
            question: "What is PCI DSS, and why is it important?",
            answer:
              "PCI DSS (Payment Card Industry Data Security Standard) is crucial for businesses that handle payment card data. Compliance protects your organisation from data breaches and fraud, builds customer trust, and helps you avoid financial penalties.",
          },
          {
            question: "How is PCI DSS compliance verified?",
            answer:
              "PCI DSS compliance can be verified through self-assessment questionnaires (SAQs) for smaller organisations or on-site assessments by Qualified Security Assessors (QSAs) for larger businesses. Compliance verification is typically required annually.",
          },
          {
            question:
              "Can other security standards help with PCI DSS compliance?",
            answer:
              "While standards like ISO 27001 or the NIST Cybersecurity Framework can support PCI DSS compliance, PCI DSS has unique requirements focused on protecting payment card data, which need to be specifically addressed.",
          },
          {
            question: "How long does it take to achieve PCI DSS compliance?",
            answer:
              "The time required varies depending on your organisation’s size, complexity, and existing security controls. The process usually involves several steps, from gap analysis to remediation and validation, and can take several months to a year.",
          },
          {
            question: "How often do we need to validate PCI DSS compliance?",
            answer:
              "Typically, PCI DSS compliance must be validated annually. However, depending on your transaction volume and agreements with payment card brands, more frequent assessments may be required.",
          },
          {
            question: "How can we get help with PCI DSS compliance?",
            answer:
              "To get assistance with PCI DSS compliance, reach out to our team through the contact information provided on our website. We’ll work with you to understand your needs and offer tailored services to help your organisation achieve and maintain compliance.",
          },
        ],
        commitment: [
          "Gap Analysis for PCI DSS Compliance",
          "Risk Assessment and Mitigation Plan",
          "PCI DSS Policies and Procedures",
          "Encryption and Key Management Documentation",
          "Security Incident Response Plan",
          "Internal and External Compliance Reports",
          "Vendor Management Documentation",
          "Cardholder Data Retention Policies",
          "Compliance Certification",
        ],
        how_we_achieve_it: [
          {
            title: "Customised Security Plans",
            description:
              "We understand that every organisation has unique needs. Our team works closely with you to develop personalised solutions that address your specific security challenges, ensuring full protection against cyber threats.",
          },
          {
            title: "Industry Expertise",
            description:
              "Our cybersecurity experts have extensive experience in the field. They bring deep knowledge of PCI DSS requirements, ensuring your organisation’s security is both strong and compliant.",
          },
          {
            title: "Commitment to Compliance",
            description:
              "We focus on helping you achieve and maintain PCI DSS compliance. Through detailed assessments and ongoing monitoring, we make sure your organisation stays up-to-date with the latest security standards.",
          },
          {
            title: "Advanced Security Frameworks",
            description:
              "We assist you in implementing advanced security frameworks that follow industry best practices. These frameworks help your organisation proactively respond to threats and safeguard valuable data.",
          },
          {
            title: "Long-Term Support",
            description:
              "Our commitment doesn’t end after implementation. We provide ongoing monitoring and support to keep your security measures effective as threats evolve and regulations change.",
          },
        ],
        why_us: [
          {
            title: "Comprehensive Security Assessment",
            description:
              "We start by thoroughly evaluating your current security measures and processes. This helps us identify gaps and vulnerabilities, giving you a clear picture of where improvements are needed to meet PCI DSS requirements.",
          },
          {
            title: "Secure Payment Solutions",
            description:
              "We guide you in choosing and setting up secure payment systems that comply with PCI DSS. Our experts ensure your systems are correctly configured to protect cardholder data and meet industry standards.",
          },
          {
            title: "Ongoing Compliance Support",
            description:
              "Achieving PCI DSS compliance is just the beginning. We offer continuous support, including regular audits, security scans, policy updates, and staff training, to help you stay compliant and secure over time.",
          },
        ],
      },
      {
        name: "ISO 27001 Compliance Services",
        slug: "iso-27001-compliance-services",
        service_description: [
          "ISO 27001:2022 is an international standard for information security management systems (ISMS).",
          "Provides a framework for managing sensitive company and customer information, ensuring its confidentiality, integrity, and availability.",
          "Compliance with ISO 27001:2022 is essential for protecting against data breaches and reducing risks.",
          "Demonstrates a commitment to security.",
          "Organisations that implement ISO 27001 are 60% less likely to experience a data breach, as reported by IT Governance.",
          "Highlights the effectiveness of the standard in improving information security.",
        ],
        description:
          "ISO 27001:2022 is an international standard for information security management systems (ISMS). It provides a framework for managing sensitive company and customer information, ensuring its confidentiality, integrity, and availability. Compliance with ISO 27001:2022 is essential because it helps organisations protect against data breaches, reduce risks, and demonstrate a commitment to security. Organisations that implement ISO 27001 are 60% less likely to experience a data breach, as reported by IT Governance, highlighting the effectiveness of the standard in improving information security.",
        why_us: [
          {
            title: "Tailored Compliance Plan",
            description:
              "We work closely with your organisation to develop a customised roadmap for ISO 27001:2022 compliance. Our team conducts a thorough assessment of your current security practices, identifies gaps, and outlines the steps needed to achieve compliance. This tailored approach ensures an efficient, practical, and business-aligned path to meeting ISO 27001:2022 requirements.",
          },
          {
            title: "Audit Support and Certification Preparation",
            description:
              "We guide you through every step of the audit process, from initial preparation to certification. Our team conducts internal audits to ensure your organisation is ready for the external certification audit. We help address any non-conformities, close gaps, and make the certification process smooth and confident.",
          },
        ],
        how_we_achieve_it: [
          {
            title: "Project Planning",
            description:
              "We start by creating a detailed project plan. Our senior team collaborates with your project team to schedule activities, develop a communication strategy, and establish an issue resolution process. We ensure that business operations remain uninterrupted, conducting necessary tests outside of working hours.",
          },
          {
            title: "Project Initiative",
            description:
              "We prepare the project team from both your organisation and our end. During the planning and kickoff phase, we finalise the project plan, communication strategy, and issue resolution process. A kickoff meeting with the steering committee confirms the scope of work and sets the milestones and timelines.",
          },
          {
            title: "Project Execution",
            description:
              "We elevate your security standards by meticulously documenting policies and conducting a comprehensive gap assessment. Our team implements precise controls, leading to the certification audit. We ensure a smooth integration of security measures, making your journey to ISO 27001 compliance both efficient and successful.",
          },
          {
            title: "Reporting & Knowledge Transfer",
            description:
              "We focus on closing any identified gaps through remediation measures. A close-out meeting summarises the findings and agreed action plans. The final phase includes a detailed report and a knowledge transfer session for your technical team, ensuring everyone understands the outcomes and next steps. We present our final deliverables to both technical and management teams, concluding the process with clear, actionable results.",
          },
        ],
        commitment: [
          "Information Security Policy",
          "Risk Assessment and Treatment Plan",
          "Information Security Objectives and Measures",
          "Security Awareness and Training Program",
          "Internal & External Audit Support",
          "Continuous Improvement Records",
          "ISMS Certification Documentation",
        ],
      },
    ],
  },
];

export default ServiceData;
