import React from "react";
import Bg from "../../images/shapes/bg_pattern_1.svg";


const FeaturesSection = (props) => {
  return (
    <section
      className="client_logo_section section_space"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="container">
        <div className="mission-vision-values">
          <div className="line"></div> 
          <div lg="4" className="card mission">
            <div className="icon">
              <i className="fa fa-bullseye"></i>
            </div>
            <h3>Perceptiveness</h3>
            <p>
              We strive ourselves as problem solvers. Whatever your pain point
              may be, our team of highly competent professionals is geared with
              broad perspectives to strategically solve them. We are ready to
              turn our clients’ vision into reality.
            </p>
          </div>
          <div className="connector">
            <div className="circle"></div>
          </div>
          <div lg="4" className="card vision">
            <div className="icon">
              <i className="fa fa-lightbulb"></i>
            </div>
            <h3>Personal approach</h3>
            <p>
              We understand that every client is unique and has its own set of
              business needs and goals. We also know that one solution fits all
              approach does not work and hence we curate our digital solutions.
              We work with clients and partner for success.
            </p>
          </div>
          <div className="connector">
            <div className="circle"></div>
          </div>
          <div className="card values">
            <div className="icon">
              <i className="fa fa-balance-scale"></i>
            </div>
            <h3>Promptness</h3>
            <p>
            In today's world, we understand how crucial it is especially when running a business in the growing digital setting. We follow a strict timeline in developing projects and accommodating special requests and adjustments.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
