import React, { Fragment, useEffect } from "react";
import Header2 from "../../components/Header2/Header2";
import Hero2 from "../../components/hero2/hero2";
import About from "../../components/about/about";
import EmpoweringSuccess from "../../components/business-consulting-components/EmpoweringSuccess/EmpoweringSuccess";
// import About from '../../components/software-company-components/about/about';
import ConsultingService from "../../components/business-consulting-components/ConsultingService/ConsultingService";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import MetaTags from "../../components/MetaTags";

const HomePage2 = () => {
  return (
    <Fragment>
      <div className="index_business_consulting">
        <Header2 />
        <main className="page_content">
          <MetaTags
            title="Adri Tech Labs | Comprehensive Security Solutions Tailored for Your Needs"
            canonicalUrl="https://www.adritechlabs.com"
            description="We provide cutting-edge security services to protect your business from evolving cyber threats"
            keywords="cyber security services companies, cyber security service, cyber security services company, cyber security consulting services,cyber security , Managed Security Services, Threat Intelligence, Endpoint Protection, Cloud Security, Incident Response, Vulnerability Management , Phishing Protection , Malware Protection , Security Operations Center (SOC) , Data Loss Prevention, Compliance Management, Mobile Security, web security , website security , Network Security , Security Information and Event Management (SIEM) , Identity Protection, Security Awareness Training , Advanced Threat Detection , Ransomware Protection, Web Application Security, Risk Management,IT Security Services, Cloud Access Security Broker (CASB), Zero Trust Security, Adri IT Solutions, Comprehensive Security Solutions, Cybersecurity Services, Business Protection, Evolving Cyber Threats, Security Consulting, Tailored Security Solutions, IT Security Services, Risk Management, Cyber Threat Protection, Security Assessment, Incident Response, Security Governance, Data Protection Services, Network Security Solutions, Digital Security Strategies, Threat Intelligence, Security Compliance, Vulnerability Assessment, Security Infrastructure, Managed Security Services"
          />
          <Hero2 />
          <About />
          <ConsultingService />
          <EmpoweringSuccess />
          <CtaSection />
          <Footer />
          <Scrollbar />
        </main>
      </div>
    </Fragment>
  );
};
export default HomePage2;

// const meta = {
//   title:
//     "Adri Tech Labs | Comprehensive Security Solutions Tailored for Your Needs",
//   description:
//     "We provide cutting-edge security services to protect your business from evolving cyber threats.",
//   canonical: "https://www.adritechlabs.com/",
//   keywords:
//     "cyber security services companies, cyber security service, cyber security services company, cyber security consulting services,cyber security , Managed Security Services, Threat Intelligence, Endpoint Protection, Cloud Security, Incident Response, Vulnerability Management , Phishing Protection , Malware Protection , Security Operations Center (SOC) , Data Loss Prevention, Compliance Management, Mobile Security, web security , website security , Network Security , Security Information and Event Management (SIEM) , Identity Protection, Security Awareness Training , Advanced Threat Detection , Ransomware Protection, Web Application Security, Risk Management,IT Security Services, Cloud Access Security Broker (CASB), Zero Trust Security, Adri IT Solutions, Comprehensive Security Solutions, Cybersecurity Services, Business Protection, Evolving Cyber Threats, Security Consulting, Tailored Security Solutions, IT Security Services, Risk Management, Cyber Threat Protection, Security Assessment, Incident Response, Security Governance, Data Protection Services, Network Security Solutions, Digital Security Strategies, Threat Intelligence, Security Compliance, Vulnerability Assessment, Security Infrastructure, Managed Security Services",
// };
