import React, { Fragment, useState } from "react";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import { Link } from "react-router-dom";
import CtaSection from "../../components/CtaSection/CtaSection";
import Footer from "../../components/footer/Footer";
import { Row, Col } from "reactstrap";
import { careerdata } from "../../api/careerdata";
import MetaTags from "../../components/MetaTags";

export const TermsConditions = () => {
  return (
    <Fragment>
      <Header />
      <main className="page_content about-page">
        <MetaTags
          title="Terms and Conditions | Adri Tech Labs"
          canonicalUrl="https://www.adritechlabs.com/terms-conditions"
          description="At Adri Tech Labs, our Terms and Conditions outline the rules and guidelines for using our services and website. This page details your rights and responsibilities as a user, as well as our policies regarding service usage, liability, and dispute resolution. We encourage you to read our terms carefully to ensure a clear understanding of our mutual obligations."
          keywords="Terms and Conditions, Adri Tech Labs, service usage rules, user responsibilities, liability policy, dispute resolution, terms of service, legal agreement, user rights, website usage policy"
        />
        <PageTitle
          pageTitle={"Terms and Conditions"}
          //   pagesub={"More Techco 😃"}
          //   pageTop={"About"}
        />
        <section id="career_openings" className="career-openings section_space">
          <div className="section-outer panel py-6 xl:py-9  dark:bg-gray-800">
            <div className="container max-w-lg">
              <div className="section-inner panel">
                <div className="panel vstack justify-center items-center gap-4 sm:gap-6 xl:gap-8 max-w-850px mx-auto">
                  <div className="panel">
                    <p>
                      These Terms govern your access to and use of the website
                      and services provided by Adri Tech Labs ("Company," "we,"
                      "us," or "our"). By accessing or using our website and
                      services, you agree to comply with and be bound by these
                      Terms. If you do not agree to these Terms, please
                      discontinue use of our services immediately.
                    </p>

                    <h3>1. Acceptance of Terms</h3>
                    <p>
                      By using our website or services, you acknowledge that you
                      have read, understood, and agree to these Terms and our
                      Privacy Policy. You must be at least 18 years old to use
                      our services, or have the consent of a parent or legal
                      guardian if you are under 18.
                    </p>

                    <h3>2. Services Provided</h3>
                    <p>
                      Adri Tech Labs provides a range of cybersecurity services,
                      including, but not limited to, vulnerability assessments,
                      data protection consultations, and cybersecurity
                      solutions. The specific services provided to you will be
                      outlined in a separate service agreement.
                    </p>
                    <p>You agree to:</p>
                    <ul>
                      <li className="mb-1">
                        Use our services solely for lawful purposes and in
                        compliance with all applicable laws.
                      </li>
                      <li className="mb-1">
                        Not engage in any behavior that could damage, disable,
                        or impair our services or servers.
                      </li>
                      <li>
                        Not attempt to gain unauthorized access to any part of
                        our services or systems.
                      </li>
                    </ul>

                    <h3>3. User Accounts</h3>
                    <p>
                      When you create an account with Adri Tech Labs (if
                      applicable), you must provide accurate and complete
                      information. You are responsible for maintaining the
                      confidentiality of your account credentials and for all
                      activities that occur under your account. You agree to
                      notify us immediately of any unauthorized use of your
                      account or any other security breach.
                    </p>
                    <p>
                      We reserve the right to suspend or terminate your account
                      if you violate these Terms.
                    </p>

                    <h3>4. Intellectual Property Rights</h3>
                    <p>
                      All intellectual property rights in the content, design,
                      graphics, text, and other materials on our website and in
                      our services are owned by Adri Tech Labs or our licensors.
                      This includes but is not limited to:
                    </p>
                    <ul>
                      <li className="mb-1">
                        Trademarks, service marks, logos, and branding.
                      </li>
                      <li className="mb-1">
                        Copyrighted materials such as text, images, and videos.
                      </li>
                      <li>Patented technologies, if applicable.</li>
                    </ul>
                    <p>
                      You are granted a limited, non-exclusive, non-transferable
                      license to access and use the content for your personal,
                      non-commercial purposes. Any unauthorized reproduction,
                      distribution, modification, or use of our content may
                      result in legal action.
                    </p>

                    <h3>5. Fees and Payment</h3>
                    <p>
                      All fees for our services are outlined in separate
                      agreements or invoices provided to you. Payments must be
                      made within the agreed time frame unless otherwise
                      specified in the agreement. Failure to pay for the
                      services may result in the suspension or termination of
                      access to our services.
                    </p>
                    <p>
                      We reserve the right to modify our fees and charges at any
                      time, and any changes will be communicated to you in
                      advance.
                    </p>

                    <h3>6. Limitation of Liability</h3>
                    <p>
                      To the fullest extent permitted by law, Adri Tech Labs and
                      its affiliates, officers, employees, and agents shall not
                      be liable for any direct, indirect, incidental,
                      consequential, or special damages, including but not
                      limited to loss of profits, data, or business
                      opportunities, arising out of or in connection with:
                    </p>
                    <ul>
                      <li className="mb-1">
                        Your use of, or inability to use, our website or
                        services.
                      </li>
                      <li className="mb-1">
                        Any reliance placed on the information or content
                        provided on our website.
                      </li>
                      <li>
                        Any unauthorized access to or use of our servers or any
                        personal information stored on them.
                      </li>
                    </ul>
                    <p>
                      In no event will our liability exceed the amount paid by
                      you, if any, for accessing or using our services.
                    </p>

                    <h3>7. Warranties and Disclaimers</h3>
                    <p>
                      We provide our services "as is" and "as available,"
                      without any express or implied warranties, including but
                      not limited to warranties of merchantability, fitness for
                      a particular purpose, or non-infringement. While we strive
                      to ensure the accuracy of the content on our website and
                      the effectiveness of our services, we do not guarantee
                      that:
                    </p>
                    <ul>
                      <li className="mb-1">
                        Our services will be uninterrupted, secure, or
                        error-free.
                      </li>
                      <li className="mb-1">
                        The results obtained from using our services will meet
                        your expectations.
                      </li>
                      <li>
                        Any defects in the website or services will be
                        corrected.
                      </li>
                    </ul>

                    <h3>8. Indemnification</h3>
                    <p>
                      You agree to indemnify and hold harmless Adri Tech Labs
                      and its affiliates, officers, employees, and agents from
                      any claims, liabilities, damages, losses, and expenses,
                      including reasonable legal fees, arising out of or in
                      connection with your use of our services or violation of
                      these Terms.
                    </p>

                    <h3>9. Data Protection</h3>
                    <p>
                      We are committed to protecting your personal data. Our
                      collection, use, and storage of your data are governed by
                      our Privacy Policy, which is incorporated into these Terms
                      by reference. By using our services, you agree to the
                      terms of our Privacy Policy.
                    </p>

                    <h3>10. Links to Third-Party Websites</h3>
                    <p>
                      Our website may contain links to third-party websites or
                      services that are not owned or controlled by Adri Tech
                      Labs. We do not endorse or assume responsibility for the
                      content, privacy policies, or practices of any third-party
                      websites. You access such websites at your own risk, and
                      we encourage you to review the terms and conditions and
                      privacy policies of any third-party websites you visit.
                    </p>

                    <h3>11. Termination</h3>
                    <p>
                      We reserve the right to terminate or suspend your access
                      to our services, with or without notice, if we believe you
                      have violated these Terms or engaged in unlawful activity.
                      Upon termination, your right to use our services will
                      immediately cease, and you must delete or destroy any
                      materials obtained from our services.
                    </p>

                    <h3>12. Governing Law and Dispute Resolution</h3>
                    <p>
                      These Terms shall be governed by and construed in
                      accordance with the laws of the Republic of India, without
                      regard to its conflict of law principles. Any disputes
                      arising out of or in connection with these Terms or your
                      use of our services shall be subject to the exclusive
                      jurisdiction of the courts located in [Your Jurisdiction].
                    </p>

                    <h3>13. Changes to Terms and Conditions</h3>
                    <p>
                      We reserve the right to modify or update these Terms at
                      any time, and any changes will become effective upon
                      posting the updated Terms on our website. It is your
                      responsibility to review these Terms periodically.
                      Continued use of our services after any changes are posted
                      constitutes your acceptance of the revised Terms.
                    </p>

                    <h3>14. Contact Information</h3>
                    <p>
                      If you have any questions or concerns regarding these
                      Terms or our services, please contact us at:
                    </p>
                    <p>
                      <strong>Email:</strong>{" "}
                      <a
                        style={{ color: "#0064B4" }}
                        href="mailto:support@adriitsolutions.com"
                      >
                        support@adriitsolutions.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <CtaSection />
      <Footer />
    </Fragment>
  );
};
