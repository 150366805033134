import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import srImg from "../../images/services/service_image_8.webp";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import WhyUs from "../AboutUsPage/WhyUs";
import MetaTags from "../../components/MetaTags";

const ServicePage = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <Fragment>
      <Header />
      <main className="page_content about-page">
        <MetaTags
          title="Services | Adri Tech Labs"
          canonicalUrl="https://www.adritechlabs.com/service"
          description="At Adri Tech Labs, we offer a comprehensive range of cybersecurity services designed to protect your business from emerging threats. Our services include risk assessments, threat detection, vulnerability management, and incident response, all tailored to meet your specific needs. With our expert team and cutting-edge technology, we ensure your digital assets remain secure and resilient."
          keywords="Services, Adri Tech Labs, cybersecurity services, risk assessment, threat detection, vulnerability management, incident response, business security solutions, tailored cybersecurity, digital asset protection"
        />
        <PageTitle
          pageTitle={"Services"}
          // pagesub={"Services"}
          // pageTop={"Our Main"}
        />
        {/* <section className="about_section section_space bg-light">
          <div className="container">
            <div className="row align-items-center justify-content-lg-between">
              <div className="col-lg-5 order-lg-last">
                <div className="team_cartoon_image">
                  <img src={srImg} alt="Service Cartoon" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="about_content">
                  <div className="heading_block">
                    <div className="heading_focus_text">
                      We Are
                      <span className="badge bg-secondary text-white">
                        Cybersecurity Experts
                      </span>
                    </div>
                    <h2 className="heading_text">
                      Tailored Security Solutions for Your Success
                    </h2>
                    <p className="heading_description mb-0">
                      We understand that every business is unique, with its own
                      set of challenges, goals, and aspirations. That’s why we
                      offer tailored cybersecurity solutions designed to protect
                      your digital assets, ensure compliance, and safeguard your
                      organization against evolving threats.
                    </p>
                  </div>
                  <Link onClick={ClickHandler} to={"/service"} className="btn">
                    <span className="btn_label" data-text="Talk to an Expart">
                      Talk to an Expart
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <ServiceSection />
        {/* <WhyUs /> */}
      </main>
      <CtaSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServicePage;
