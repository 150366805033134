import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import ModalVideo from "react-modal-video";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import srImg from "../../images/services/svgviewer-output (1).png";
import ServiceData from "../../components/ServiceSection/servicedata";
import { Link } from "react-router-dom";
import MetaTags from "../../components/MetaTags";

const ServiceSinglePage = (props) => {
  const { slug } = useParams();

  const ServiceDetails = ServiceData?.find((item) => item.slug === slug);

  const [isOpen, setOpen] = useState(false);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <Fragment>
      <Header />
      <main>
        <MetaTags
          title={`${ServiceDetails?.title} | Adri Tech Labs`}
          canonicalUrl={`https://www.adritechlabs.com/service-single/${ServiceDetails.slug}`}
          description={`${ServiceDetails?.description}`}
          keywords="Services, Adri Tech Labs, cybersecurity services, risk assessment, threat detection, vulnerability management, incident response, business security solutions, tailored cybersecurity, digital asset protection"
        />
        <PageTitle
          pageTitle={ServiceDetails?.title}
          pageTop={
            <span>
              <a href="/service" className="text-white">
                Services
              </a>{" "}
              {">"} {ServiceDetails?.title}{" "}
            </span>
          }
        />
        <section className="about_section section_space2">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 order-lg-last p-0">
                <div className="p-4">
                  <div className="team_cartoon_image">
                    <img src={srImg} alt="Service Cartoon" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-0">
                <div className="p-4">
                  <div className="about_content">
                    <div className="heading_block">
                      <h1 className="service-title mb-4">
                        {ServiceDetails.title}
                      </h1>
                      <ul className="heading_description mb-4">
                        {ServiceDetails.service_description.map(
                          (item, index) => {
                            return <li key={index}>{item}</li>;
                          }
                        )}
                      </ul>
                    </div>
                    <Link
                      onClick={ClickHandler}
                      to={"/pricing"}
                      className="noraml-btn"
                    >
                      <span className="btn_label" data-text="Talk to an Expert">
                        Talk to an Expert
                      </span>
                      <span className="btn_icon">
                        <i className="fa-solid fa-arrow-up-right"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about_and_case_section section_space2 section_decoration">
          <div className="container">
            <div
              className="heading_block text-center"
              style={{ marginBottom: "40px" }}
            >
              <h2 className="heading_text">Services Outcome</h2>
            </div>
            <div className="row case_studies_wrapper">
              {ServiceDetails?.services?.map((service, prj) => (
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <a className="card4" href="#">
                    <h3 className="mb-4">
                      <a
                        style={{ color: "#0064B4" }}
                        href={`/service-single/${slug}/${service.slug}`}
                      >
                        {service.name}
                      </a>
                    </h3>
                    <a
                      className="noraml-btn"
                      style={{ width: "fit-content" }}
                      href={`/service-single/${slug}/${service.slug}`}
                    >
                      <span className="btn_label" data-text="Read More">
                        Read More
                      </span>
                    </a>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <CtaSection />
      <Footer />
      <Scrollbar />
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="7e90gBu4pas"
        onClose={() => setOpen(false)}
      />
    </Fragment>
  );
};
export default ServiceSinglePage;

{
  /* {ServiceDetails?.services?.map((service, prj) => (
                <div className="case_study_block" key={prj}>
                  <div className="case_study_content">
                    <h3 className="case_title mt-0">
                      <Link
                        onClick={ClickHandler}
                        to={`/service-single/${slug}/${service.slug}`}
                      >
                        {service.name}
                      </Link>
                    </h3>
                    <p>{service.description}</p>
                    <Link
                      onClick={ClickHandler}
                      to={`/service-single/${slug}/${service.slug}`}
                      className="gradient-btn"
                    >
                      <span className="btn_label" data-text="Read More">
                        Read More
                      </span>
                      <span className="btn_icon">
                        <i className="fa-solid fa-arrow-up-right"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              ))} */
}
