import React from "react";
import blog1 from "../../images/blog/blog_post_image_8.webp";
import blog2 from "../../images/blog/blog_post_image_9.webp";
import blog3 from "../../images/blog/blog_post_image_10.webp";
import blog4 from "../../images/blog/blog_post_image_11.webp";
import author from "../../images/avatar/avatar_8.webp";
import author2 from "../../images/avatar/avatar_9.webp";
import author3 from "../../images/avatar/avatar_10.webp";
import author4 from "../../images/avatar/avatar_11.webp";
import icon1 from "../../images/icons/icon_calendar.svg";
import icon2 from "../../images/icons/icon_user.svg";
import icon3 from "../../images/icons/icon_chat.svg";
import icon4 from "../../images/icons/icon_eye.svg";
import icon5 from "../../images/icons/icon_link.svg";
import icon6 from "../../images/icons/icon_bookmark.svg";
import sImg1 from "../../images/icons/icon_facebook.svg";
import sImg2 from "../../images/icons/icon_twitter_x.svg";
import sImg3 from "../../images/icons/icon_linkedin.svg";
import sImg4 from "../../images/icons/icon_instagram.svg";
import MetaTags from "../../components/MetaTags";
import blogs from "../../api/blogs";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import BlogSidebar from "../BlogSidebar";

const BlogSingle = (props) => {
  const { slug } = useParams();

  const BlogDetails = blogs.find((item) => item.slug === slug);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };


  return (
    <section className="blog_details_section section_space2">
      <MetaTags
        title={`${BlogDetails.title} | Adri Tech Labs`}
        canonicalUrl={`https://www.adritechlabs.com/${BlogDetails.slug}`}
        description= {BlogDetails.meta_description}
        keywords="Adri Tech Labs, cybersecurity blog, security services, cyber threat protection, information security, security best practices, digital security, business protection, cyber risk management, threat intelligence"
      />
      <div className="container">
        {/* <div className="details_item_image">
          <img src={blog1} alt="Techco - Blog" />
        </div> */}
        {/* <h2 className="details_item_title">{BlogDetails.title}</h2> */}
        <p>{BlogDetails.content.introduction.paragraph}</p>
        <hr className="mb-0" />
        <div className="section_space2 pb-0">
          <div className="row">
            <div className="col-lg-8">
              {BlogDetails.content.sections.map((section, index) => (
                <div key={index}>
                  <h2 className="details_item_info_title mb-5">
                    {index + 1}. {section.title}
                  </h2>
                  {section.content.map((item, idx) => (
                    <div key={idx}>
                      <h5 className="details_item_subtitle">
                        {index + 1}.{idx + 1}
                        {"  "}
                        {item.subtitle}
                      </h5>
                      <p>{item.paragraph}</p>
                    </div>
                  ))}
                </div>
              ))}
              <h2 className="details_item_info_title">Conclusion</h2>
              <p>{BlogDetails.content.conclusion.paragraph}</p>
            </div>
            <BlogSidebar />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSingle;
