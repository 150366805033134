import React, { Fragment, useState } from "react";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import { Link } from "react-router-dom";
import CtaSection from "../../components/CtaSection/CtaSection";
import Footer from "../../components/footer/Footer";
import { Row, Col } from "reactstrap";
import { careerdata } from "../../api/careerdata";
import MetaTags from "../../components/MetaTags";

export const Career = () => {

  return (
    <Fragment>
      <Header />
      <main className="page_content about-page">
        <MetaTags
          title="Career | Adri Tech Labs"
          canonicalUrl="https://www.adritechlabs.com/career"
          description="Join the innovative team at Adri Tech Labs and help us shape the future of cybersecurity. Our Career page highlights exciting job opportunities for professionals passionate about technology and security. Explore open positions, learn about our work culture, and discover how you can contribute to protecting businesses from evolving cyber threats."
          keywords="Career, Adri Tech Labs, cybersecurity jobs, job opportunities, work with us, technology careers, cybersecurity professionals, job openings, work culture, career in cybersecurity"
        />
        <PageTitle
          pageTitle={"Career"}
          //   pagesub={"More Techco 😃"}
          //   pageTop={"About"}
        />
        <section id="career_openings" className="career-openings section_space">
          <div className="section-outer panel py-6 xl:py-9  dark:bg-gray-800">
            <div className="container max-w-lg">
              <div className="section-inner panel">
                <div className="panel vstack justify-center items-center gap-4 sm:gap-6 xl:gap-8 max-w-850px mx-auto">
                  <div className="panel">
                    <Row
                      className="row child-cols-12 sm:child-cols-6 col-match g-1"
                      data-uc-grid=""
                      data-anime="onview: -100; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 200});"
                    >
                      {careerdata.map((job, index) => (
                        <Col lg="4" className="m-0" key={index}>
                          <div className="panel vstack p-4 rounded lg:rounded-2 normal-bg">
                            <div className="vstack gap-narrow">
                              <h4 className="mb-2">{job.title}</h4>
                              <p>{job.description}</p>
                            </div>
                            <Link
                              to={`/career-details/${job.slug}`}
                              className="gradient-light-btn"
                            >
                              <span>Apply Now</span>
                              <i className="icon icon-narrow unicon-arrow-up-right fw-bold rtl:-rotate-90" />
                            </Link>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <CtaSection />
      <Footer />
    </Fragment>
  );
};
