import React from "react";
import Bg from "../../images/backgrounds/cta.jpg";
import { Link } from "react-router-dom";

const CtaSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
      <section
        className="calltoaction_section"
      >
        <div className="container text-center">
          <div className="heading_block text-white">
            <h1 className="text-white">Ready to Work, Let's Chat</h1>
            <p className="heading_description mb-0">
              Our team of experts is ready to collaborate with you every step of
              the way, from initial consultation to implementation.
            </p>
          </div>
          <Link
            onClick={ClickHandler}
            to="/pricing"
            className="btn btn-primary"
          >
            <span className="btn_label" data-text="Contact Us Today!">
              Contact Us Today!
            </span>
            <span className="btn_icon">
              <i className="fa-solid fa-arrow-up-right"></i>
            </span>
          </Link>
        </div>
      </section>
  );
};

export default CtaSection;
