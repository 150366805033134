import React from "react";
import Services from "../../api/service";
import { Link } from "react-router-dom";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";

import ServiceData from "./servicedata";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  ListGroup,
  ListGroupItem,
  CardLink,
  Row,
  Col,
} from "reactstrap";

const ServiceSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <>
      {/* <section className="service_section section_space section_decoration xb-hidden">
        <div className="container"> */}
      {/* <div className="heading_block text-center">
          <h2 className="heading_text mb-0">Our Featured Services</h2>
        </div> */}

      {/* <div className="row">
          {ServiceData.slice(0, 5).map((service, srv) => (
            <div className={`${service.col} mt-30`} key={srv}>
              {service.title ? (
                <div className="service_block">
                  <div className="service_image">
                    <img src={service.sImg} alt="IT Management Services" />
                  </div>
                  <div className="service_content">
                    <h3 className="service_title">
                      <Link
                        onClick={ClickHandler}
                        to={`/service-single/${service.slug}`}
                      >
                        {service.title}
                      </Link>
                    </h3>

                    <div className="links_wrapper">
                      <Link
                        onClick={ClickHandler}
                        to={`/service-single/${service.slug}`}
                        className="icon_block"
                      >
                        <i className="fa-regular fa-arrow-up-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
        </div> */}
      {/* <div className="row">
          {ServiceData.map((service, srv) => (
            <div className="col-lg-4" key={srv}>
              <div className="service_block_2 bg-light">
                <h3 className="service_title">
                  <Link
                    onClick={ClickHandler}
                    to={`/service-single/${service.slug}`}
                  >
                    {service.title}
                  </Link>
                </h3>
                <p>{service.description}</p>
                <Link
                  onClick={ClickHandler}
                  // className="btn btn-outline-light"
                  to={`/service-single/${service.slug}`}
                >
                  <span className="btn_label" data-text="Read More">
                    Read More
                  </span>
                </Link>
              </div>
            </div>
          ))}
        </div> */}
      {/* <Row>
            {ServiceData.map((service, srv) => (
              <Col lg="4">
                <Card className="service-card" style={{}}>
                  <img alt="Card" src={service.sImg} />
                  <CardBody>
                    <Link
                      onClick={ClickHandler}
                      style={{ color: "#020842" }}
                      to={`/service-single/${service.slug}`}
                    >
                      <CardTitle tag="h5">{service.title}</CardTitle>
                    </Link>
                    <CardText>{service.subtitle}</CardText>
                    <Link
                      onClick={ClickHandler}
                      // className="btn btn-outline-light"
                      to={`/service-single/${service.slug}`}
                    >
                      <span
                        style={{ color: "#020842" }}
                        className="btn_label"
                        data-text="Read More"
                      >
                        Read More
                      </span>
                    </Link>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row> */}
      {/* </div>
      </section> */}
      <section class="service_section section_space section_decoration xb-hidden">
        <div className="container">
          <Row>
            {ServiceData.map((service, srv) => (
              <Col lg="4" className="g-4">
                <article class="card-new card--1">
                  <div
                    class="card__img"
                    style={{ backgroundImage: `url(${service.sImg})` }}
                  ></div>
                  <div
                    class="card__img--hover"
                    style={{ backgroundImage: `url(${service.sImg})` }}
                  ></div>
                  <div class="card__info">
                    <h5 class="card__title mb-0">
                      {" "}
                      <Link
                        onClick={ClickHandler}
                        style={{ color: "#020842" }}
                        to={`/service-single/${service.slug}`}
                      >
                        {service.title}
                      </Link>
                    </h5>
                    <p className="card_description">{service.subtitle}</p>
                    <a
                      href={`/service-single/${service.slug}`}
                      style={{display:'block',color:'#020842'}}
                    >
                      <BsFillArrowUpRightCircleFill className="feature-icon" />
                    </a>
                  </div>
                </article>
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </>
  );
};

export default ServiceSection;
