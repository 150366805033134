import React, { Fragment, useState } from "react";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import { Link } from "react-router-dom";
import ServiceData from "../../components/ServiceSection/servicedata";
import CtaSection from "../../components/CtaSection/CtaSection";
import Footer from "../../components/footer/Footer";
import icon1 from "../../images/icons/icon_calendar.svg";
import newsdata from "../../api/newsdata";
import MetaTags from "../../components/MetaTags";

export const News = () => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <Fragment>
      <Header />
      <main className="page_content about-page">
        <MetaTags
          title="Industry Updates | Adri Tech Labs"
          canonicalUrl="https://www.adritechlabs.com/industry-updates"
          description="Stay informed with the latest trends and developments in cybersecurity through our Industry Updates at Adri Tech Labs. This page features timely articles, news, and insights that highlight emerging threats, technological advancements, and best practices in the cybersecurity landscape. Join us as we explore the critical issues affecting businesses and how to navigate them effectively."
          keywords="Industry Updates, Adri Tech Labs, cybersecurity news, trends in cybersecurity, emerging threats, technology advancements, cybersecurity insights, best practices, business security updates"
        />
        <PageTitle
          pageTitle={"Industry Updates"}
          //   pagesub={"More Techco 😃"}
          //   pageTop={"About"}
        />
        <section className="blog_section section_space section_decoration">
          <div className="container">
            <div className="row justify-content-center">
              {newsdata.map((blog, Bitem) => (
                <div className="col-lg-4" key={Bitem}>
                  <div className="blog_post_block layout_2">
                    <div className="blog_post_image">
                      <a
                        onClick={ClickHandler}
                        href={blog.link}
                        target="_blank"
                        className="image_wrap"
                      >
                        <img
                          src={blog.screens}
                          alt=""
                          style={{
                            height: "300px",
                            width: "420px",
                            objectFit: "cover",
                          }}
                        />
                        <i className="fa-solid fa-arrow-up-right"></i>
                      </a>
                    </div>
                    <div className="blog_post_content p-0">
                      <h3 className="blog_post_title mb-0">
                        <a
                          onClick={ClickHandler}
                          href={blog.link}
                          target="_blank"
                        >
                          {blog.title}
                        </a>
                      </h3>
                      <ul className="post_meta unordered_list">
                        <li>
                          <a
                            onClick={ClickHandler}
                            target="_blank"
                            href={blog.authlink}
                          >
                            <i className="fa-regular fa-circle-user"></i> By{" "}
                            <b>{blog.author}</b>
                          </a>
                        </li>
                        <li>
                          <img src={icon1} alt="Icon Calendar" />{" "}
                          {blog.create_at}
                        </li>
                        {/* <li>
                          <Link
                            onClick={ClickHandler}
                            to={`/blog-single/${blog.slug}`}
                          >
                            <i className="fa-regular fa-comment-lines"></i> 24
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <CtaSection />
      <Footer />
    </Fragment>
  );
};
