import React, { useState } from "react";
import icon1 from "../../../images/icons/icon_bulb.svg";
import icon2 from "../../../images/icons/icon_user_rating.svg";
import icon3 from "../../../images/icons/icon_hand_shake.svg";
import ModalVideo from "react-modal-video";
import eImg from "../../../images/blog/svgviewer-output (3).png";
import { GrUserExpert } from "react-icons/gr";
import { RiLightbulbFlashLine } from "react-icons/ri";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";

const EmpoweringSuccess = (props) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <section className="empowering_success_section section_space">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="heading_block text-center">
              <h2 className="heading_text2 mb-0">Why Choose Us ?</h2>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="image_wrap position-relative">
              <img className="rounded" src={eImg} alt="Techco - About" />
              {/* <button
                onClick={() => setOpen(true)}
                className="video_btn ripple_effect"
              >
                <span className="btn_icon">
                  <i className="fa-solid fa-play"></i>
                </span>
              </button> */}
            </div>
          </div>
          <div className="col-lg-6">
            <ul className="empowering_services unordered_list_block ps-lg-5">
              <li>
                <div className="iconbox_block layout_icon_left">
                  <div className="iconbox_icon">
                    {/* <img src={icon1} alt="Bulb SVG Icon" /> */}
                    <FaStar color="#0064B4" fontSize={40}/>
                  </div>
                  <div className="iconbox_content">
                    <h3 className="iconbox_title">Expertise</h3>
                    <p className="mb-0">
                      Our team has decades of combined experience in the
                      cybersecurity industry.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="iconbox_block layout_icon_left">
                  <div className="iconbox_icon">
                    {/* <img src={icon2} alt="User Rating SVG Icon" /> */}
                    <RiLightbulbFlashLine color="#0064B4" fontSize={40}/>
                  </div>
                  <div className="iconbox_content">
                    <h3 className="iconbox_title">Proven Solutions</h3>
                    <p className="mb-0">
                      We use state-of-the-art technology and methodologies to
                      protect your business.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="iconbox_block layout_icon_left">
                  <div className="iconbox_icon">
                    {/* <img src={icon3} alt="Hand Shake SVG Icon" /> */}
                    <FaPeopleGroup color="#0064B4" fontSize={40}/>
                  </div>
                  <div className="iconbox_content">
                    <h3 className="iconbox_title">Customer Focus</h3>
                    <p className="mb-0">
                      We prioritise understanding your unique needs and
                      delivering customised solutions.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="7e90gBu4pas"
        onClose={() => setOpen(false)}
      />
    </section>
  );
};

export default EmpoweringSuccess;
