import sImg1 from '../images/services/service_image_1.webp'
import sImg2 from '../images/services/service_image_2.webp'
import sImg3 from '../images/services/service_image_3.webp'
import sImg4 from '../images/services/service_image_4.webp'
import sImg5 from '../images/services/service_image_5.webp'

import icon1 from '../images/icons/icon_code.svg'
import icon2 from '../images/icons/icon_programming_tree.svg'
import icon3 from '../images/icons/icon_monitor_2.svg'
import icon4 from '../images/icons/icon_phone.svg'
import icon5 from '../images/icons/icon_bug.svg'
import icon6 from '../images/icons/icon_programming.svg'

import icon7 from '../images/icons/icon_analisis_2.svg'
import icon8 from '../images/icons/icon_process.svg'
import icon9 from '../images/icons/icon_chart_2.svg'
import icon10 from '../images/icons/icon_meeting.svg'
import icon11 from '../images/icons/icon_bulb_2.svg'
import icon12 from '../images/icons/icon_speed_miter.svg'
import { FaLaptopCode , FaNetworkWired } from "react-icons/fa";
import { MdOutlineSystemSecurityUpdateGood , MdOutlineSecurity } from "react-icons/md";
import { GrCompliance } from "react-icons/gr";
import { SiDarkreader , SiMalwarebytes } from "react-icons/si";
import { LiaIndustrySolid } from "react-icons/lia";




const Services = [
    {
        Id: '1',
        sImg: <FaLaptopCode fontSize={60} />,
        title: 'Website Security Inspection',
        description: 'Comprehensive security inspection for websites to detect vulnerabilities, secure web applications, and prevent cyber threats like SQL injection, XSS, and more.'
    },
    {
        Id: '2',
        sImg: <MdOutlineSystemSecurityUpdateGood fontSize={60} />,
        title: 'Mobile Application Threat Analysis',
        description: 'In-depth analysis of mobile apps to identify potential security risks, vulnerabilities, and ensure the app complies with security standards.'
    },
    {
        Id: '3',
        sImg: <FaNetworkWired fontSize={60} />,
        title: 'Network/IT Infrastructure Security Assessment',
        description: 'Evaluation of network and IT infrastructure to assess security posture, identify weaknesses, and recommend best practices to safeguard against potential threats.'
    },
    {
        Id: '4',
        sImg: <GrCompliance fontSize={60} />,
        title: 'Security Governance and Compliance Service',
        description: 'Helps organizations implement security governance frameworks and ensures compliance with industry standards and regulatory requirements.'
    },
    {
        Id: '5',
        sImg: <MdOutlineSecurity fontSize={60} />,
        title: 'Cybersecurity Vulnerability Management',
        description: 'Ongoing vulnerability assessment and management to identify, prioritize, and mitigate risks across systems and applications.'
    },
    {
        Id: '6',
        sImg: <SiDarkreader fontSize={60} />,
        title: 'Dark Web Intelligence Services',
        description: 'Monitoring and intelligence gathering from the dark web to detect potential threats, compromised data, and cyber risks before they escalate.'
    },
    {
        Id: '7',
        sImg: <SiMalwarebytes fontSize={60} />,
        title: 'Crisis Response and Malware Evaluation',
        description: 'Rapid incident response to cyber crises, including malware evaluation, containment, and remediation to mitigate damage and restore operations.'
    },
    {
        Id: '8',
        sImg: <LiaIndustrySolid fontSize={60} />,
        title: 'Industrial Security Assessment Service',
        description: 'Specialized security assessments for industrial environments, including SCADA and ICS systems, to detect and mitigate risks to operational technology.'
    },
]


export default Services;