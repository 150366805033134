import React from "react";
import Services from "../../../api/service";
import { Link } from "react-router-dom";
import Bg from "../../../images/backgrounds/bg_image_3.webp";

const ConsultingService = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section
      className="business_consulting_service_section section_space2"
      // style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="heading_block text-center">
              <h2 className="heading_text text-white">Our Services</h2>
              {/* <p className="heading_description mb-0 text-white">
                Comprehensive Security Solutions for Every Need
              </p> */}
            </div>
          </div>
        </div>

        <div className="business_consulting_services row">
          {Services.map((service, srv) => (
            <div className="col-lg-3" key={srv}>
              <div className="iconbox_block">
                <div className="iconbox_icon">
                  {/* <img src={service.sImg} alt="Techco - Service icon" /> */}
                  {service.sImg}
                </div>
                <div className="iconbox_content">
                  <h3 className="iconbox_title">{service.title}</h3>
                  <p className="mb-0">
                  {service.description}
                  </p>
                  {/* <Link onClick={ClickHandler} to="/pricing" className="btn mt-3">
                    <span className="btn_label" data-text={service.btntitle}>
                      {service.btntitle}
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </Link> */}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="btns_group pb-0">
          <Link onClick={ClickHandler} className="gradient-header-btn2" to="/service">
            <span className="btn_label" data-text="Get Started Today">Get Started Today</span>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ConsultingService;
