// src/components/OrphanUrls.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const OrphanUrls = () => {
  const [urls, setUrls] = useState([]);

  useEffect(() => {
    const fetchSitemap = async () => {
      try {
        const response = await axios.get('https://www.adritechlabs.com/sitemap.xml');
        const text = await response.data;
        const parser = new DOMParser();
        const xml = parser.parseFromString(text, 'text/xml');
        const urlElements = xml.getElementsByTagName('url');
        const urlList = Array.from(urlElements).map(url => url.getElementsByTagName('loc')[0].textContent);
        setUrls(urlList);
      } catch (error) {
        console.error('Error fetching sitemap:', error);
      }
    };

    fetchSitemap();
  }, []);

  return (
    <div>
      <h1>Orphan URLs</h1>
      <ul>
        {urls.map((url, index) => (
          <li key={index}>
            <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrphanUrls;
