import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import ModalVideo from "react-modal-video";
import wImg from "../../images/about/image3.png";
import PolicySection from "./Policy";
import WhyUs from "./WhyUs";
import FeaturesSection from "../../components/FeaturesSection/FeaturesSection";
import CtaSection from "../../components/CtaSection/CtaSection";
import MetaTags from "../../components/MetaTags";

const AboutUsPage = (props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Fragment>
      <Header />
      <main className="page_content about-page">
        <MetaTags
          title="About Us | Adri Tech Labs"
          description="At Adri Tech Labs, we deliver innovative cybersecurity solutions tailored to protect businesses from digital threats. Our expert team focuses on customized security strategies, leveraging cutting-edge technology to ensure your organization stays secure and compliant in a dynamic landscape."
          keywords="About Us, Adri Tech Labs, cybersecurity solutions, digital security, tailored security strategies, threat protection, business security, cybersecurity experts"
          canonicalUrl="https://www.adritechlabs.com/about"
        />
        <PageTitle pageTitle={"About Us"} />
        <section className="intro_about_section section_space pt-6 pb-0">
          <div className="container">
            <div className="heading_block mb-0">
              <div className="row justify-content-lg-between">
                <div className="col-lg-6">
                  <div className="image_wrap text-center gap-4">
                    <img src={wImg} alt="Adri Tech Labs team collaboration" />
                  </div>
                </div>
                <div className="col-lg-6 m-auto">
                  <div className="gap-4">
                    <p className="heading_description">
                      At Adri Tech Labs, we have extensive experience in
                      curating comprehensive security solutions for the Web,
                      Desktop, and Mobile services that help you establish a
                      strong digital footprint and drive growth and revenue to
                      the key industries we serve.
                    </p>
                    <p className="heading_description">
                      Our team has passionately worked in partnership with
                      clients, developers, and designers, and we've succeeded in
                      meeting tough deadlines and usability goals on tight
                      budgets. We are passionate about our clients’ success and
                      partner for the Digital Transformation journey.
                    </p>
                    <p className="heading_description mb-0">
                      We are committed to safeguarding your business with
                      advanced security solutions. Our team of experts brings
                      years of experience in cybersecurity, ensuring your data
                      and systems are always protected.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FeaturesSection />
        <PolicySection />
        <WhyUs />
        <CtaSection />
      </main>
      <Footer />
      <Scrollbar />
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="7e90gBu4pas"
        onClose={() => setOpen(false)}
      />
    </Fragment>
  );
};
export default AboutUsPage;
