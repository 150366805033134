import React from 'react';
import wImg from '../../images/about/image.png'
import { IoIosPeople } from "react-icons/io";
import { IoShieldCheckmark } from "react-icons/io5";
import { GiTechnoHeart } from "react-icons/gi";
import { FcProcess } from "react-icons/fc";
import { FaHandshakeSimple , FaArrowTrendUp , FaRecycle } from "react-icons/fa6";

const Policy = [
    {
        title: 'Skilled Team',
        icon: <IoIosPeople color='#0064B4' fontSize={24}/>,
    },
    {
        title: 'Proven Success',
        icon: <IoShieldCheckmark color='#0064B4' fontSize={24}/>,
    },
    {
        title: 'Tech & Industry Expertise',
        icon: <GiTechnoHeart color='#0064B4' fontSize={24}/>,
    },
    {
        title: 'Agile & Adaptable',
        icon: <FaRecycle color='#0064B4' fontSize={24}/>,
    },
    {
        title: 'Strong Partnerships',
        icon: <FaHandshakeSimple color='#0064B4' fontSize={24}/>,
    },
    {
        title: 'Trend Awareness',
        icon: <FaArrowTrendUp color='#0064B4' fontSize={24}/>,
    },


]


const WhyUs = (props) => {

    return (
        <section className="service_section section_space2">
            <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6">
                        <div className="image_wrap">
                            <img src={wImg} alt="Techco - About"/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ps-lg-5">
                            <div className="heading_block">
                                {/* <div className="heading_focus_text">
                                    <span className="badge bg-secondary text-white">Why Us</span>
                                    Better
                                </div> */}
                                <h2 className="heading_text2 mb-0">
                                    Why Our Services are Better Than Others?
                                </h2>
                            </div>
                            <ul className="service_facilities_group unordered_list">
                                {Policy.map((policy, pitem) => (
                                    <li key={pitem}>
                                        <strong className="iconbox_block iconbox_new_footer_block layout_icon_left">
                                            <span className="iconbox_icon">
                                                {/* <img src={policy.icon} alt="Dollar SVG Icon" /> */}
                                                 {policy.icon}
                                            </span>
                                            <span className="iconbox_content">
                                                <strong className="iconbox_title mb-0" style={{color:'#78828C'}}>{policy.title}</strong>
                                            </span>
                                        </strong>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhyUs;