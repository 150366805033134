import React, { Fragment } from "react";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import BlogList from "../../components/BlogList";
import MetaTags from "../../components/MetaTags";

const BlogPage = (props) => {

  return (
    <Fragment>
      <Header />
      <main className="page_content blog-page">
        <MetaTags
          title="Blogs | Adri Tech Labs"
          canonicalUrl="https://www.adritechlabs.com/blog"
          description="Stay informed on the latest cybersecurity trends and best practices at Adri Tech Labs' blog. Discover expert insights, strategies, and resources to help your business protect against evolving cyber threats."
          keywords="Adri Tech Labs, cybersecurity blog, security services, cyber threat protection, information security, security best practices, digital security, business protection, cyber risk management, threat intelligence"
        />
        <PageTitle pageTitle={"Blogs"} />
        <BlogList />
      </main>
      <CtaSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogPage;
