import React, { Fragment } from "react";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import ContactSection from "../../components/ContactSection";
import MetaTags from "../../components/MetaTags";

const ContactPage = (props) => {
  return (
    <Fragment>
      <Header />
      <main className="page_content about-page">
        <MetaTags
          title="Contact Us | Adri Tech Labs"
          canonicalUrl="https://www.adritechlabs.com/contact"
          description="At Adri Tech Labs, we value your feedback and inquiries. Our Contact Us page provides multiple ways to reach our team, whether you have questions about our services or need assistance with cybersecurity solutions. Get in touch with us today, and let us help you enhance your security posture."
          keywords="Contact Us, Adri Tech Labs, cybersecurity inquiries, reach us, feedback, customer support, security solutions assistance, contact information, cybersecurity services inquiries"
        />
        <PageTitle pageTitle={"Contact Us"} />
        <ContactSection />
        <CtaSection />
      </main>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ContactPage;
