import React, { Fragment, useState } from "react";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useEffect } from "react";
import NotFound from "../../images/hero/404.png";

const PageNotFound = () => {
  useEffect(() => {
    window.history.replaceState(null, "404 - Page Not Found", "/404");
  }, []);

  document.title = " 404 - Page Not Found";

  return (
    <Fragment>
      <main className="page_content about-page">
        <section className="pricing_section section_space2">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 p-0">
                <div className="p-4">
                  <div className="team_cartoon_image text-center">
                    <img
                      src={NotFound}
                      alt="Service Cartoon"
                      style={{ maxWidth: "100%" }}
                    />
                    <h3 className="mt-4" style={{ color: "#0064B4" }}>
                      404 - Page not found
                    </h3>
                    <p style={{ color: "#78828C" }}>
                      The page you are looking for might have been removed had
                      its name changed or is temporarily unavailable.
                    </p>
                    <a className="noraml-btn" href="/">
                      <span className="btn_label" data-text="Go To HomePage">
                        Go To HomePage
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Scrollbar />
    </Fragment>
  );
};
export default PageNotFound;
