import React, { Fragment, useState } from "react";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import { Link } from "react-router-dom";
import ServiceData from "../../components/ServiceSection/servicedata";
import CtaSection from "../../components/CtaSection/CtaSection";
import Footer from "../../components/footer/Footer";
import MetaTags from "../../components/MetaTags";
import {
  Card,
  Col,
  Row,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

export const KnowledgeCenter = () => {
  const [open, setOpen] = useState(1);
  const toggle = (id) => {
    if (open === id) {
      setOpen(1);
    } else {
      setOpen(id);
    }
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const knowledgedata = [
    {
      title: "Phishing Attacks",
      question: "What is phishing, and how can organizations prevent it?",
      answer:
        "Phishing is a type of social engineering attack where attackers impersonate legitimate entities to trick individuals into providing sensitive information, such as usernames, passwords, or financial details. These attacks often come via email or text messages, prompting users to click on malicious links.",
      overview:
        "Phishing attacks exploit human vulnerabilities, making them one of the most common cyber threats. They often disguise themselves as urgent or official communications from trusted entities like banks or colleagues. Organizations can reduce phishing risks by implementing employee training, multi-factor authentication (MFA), and email filtering systems.",
      key_points: [
        "Types of Phishing: Spear phishing, whaling, clone phishing, and smishing.",
        "Common Targets: Corporate executives, financial departments, and HR personnel.",
        "Prevention: Awareness training, suspicious link checking, and use of secure communication channels.",
      ],
      affected_industries: [
        "Finance",
        "Retail",
        "Healthcare",
        "Government",
        "Education",
      ],
      attachments: [
        {
          type: "PDF",
          name: "Phishing Prevention Checklist",
        },
        {
          type: "Video",
          name: "How to Spot a Phishing Email",
        },
      ],
      reference_links: [
        {
          name: "Anti-Phishing Working Group (APWG)",
          url: "https://apwg.org/",
        },
        {
          name: "CISA Phishing Prevention and Response",
          url: "https://www.cisa.gov/phishing",
        },
      ],
    },
    {
      title: "Data Breaches",
      question:
        "What causes data breaches, and how can organizations mitigate them?",
      answer:
        "A data breach occurs when sensitive, protected, or confidential data is exposed to unauthorized individuals. Causes include weak security measures, unpatched software, and insider threats. The impact of a data breach can be devastating, leading to financial loss, legal issues, and reputational damage.",
      overview:
        "Data breaches can result from external attacks, internal errors, or inadequate data handling procedures. Organizations must employ encryption, strong access controls, regular audits, and incident response plans to minimize the risk.",
      key_points: [
        "Causes: Hacking, employee negligence, lost/stolen devices.",
        "Impacts: Identity theft, financial loss, regulatory penalties.",
        "Mitigation: Data encryption, regular security updates, least privilege access controls.",
      ],
      affected_industries: [
        "Healthcare",
        "Finance",
        "Retail",
        "Telecommunications",
        "Education",
      ],
      attachments: [
        {
          type: "PDF",
          name: "Incident Response Plan Template",
        },
        {
          type: "Case Study",
          name: "Major Data Breach Examples",
        },
      ],
      reference_links: [
        {
          name: "IBM Data Breach Report",
          url: "https://www.ibm.com/security/data-breach",
        },
        {
          name: "GDPR Compliance Guidelines",
          url: "https://gdpr.eu/",
        },
      ],
    },
    {
      title: "Distributed Denial of Service (DDoS) Attacks",
      question: "What is a DDoS attack, and how can it be mitigated?",
      answer:
        "A Distributed Denial of Service (DDoS) attack involves overwhelming a targeted server, service, or network with traffic from multiple sources, causing service disruption or complete downtime. These attacks can severely impact business operations and customer access.",
      overview:
        "DDoS attacks are increasingly used to take down websites, applications, and services, especially those that are critical to business functions. Cloud-based DDoS mitigation services, firewalls, and load balancing techniques help organizations protect themselves.",
      key_points: [
        "Attack Types: Volumetric, protocol, and application layer DDoS attacks.",
        "Symptoms: Slow network performance, unavailable services, increased traffic from suspicious IPs.",
        "Prevention: Load balancing, rate limiting, and DDoS mitigation services.",
      ],
      affected_industries: [
        "E-Commerce",
        "Finance",
        "Gaming",
        "Telecommunications",
        "Media & Entertainment",
      ],
      attachments: [
        {
          type: "PDF",
          name: "DDoS Prevention and Mitigation Best Practices",
        },
        {
          type: "Infographic",
          name: "Anatomy of a DDoS Attack",
        },
      ],
      reference_links: [
        {
          name: "Cloudflare DDoS Protection",
          url: "https://www.cloudflare.com/ddos/",
        },
        {
          name: "AWS Shield for DDoS Mitigation",
          url: "https://aws.amazon.com/shield/",
        },
      ],
    },
    {
      title: "Zero-Day Exploits",
      question:
        "What is a zero-day exploit, and how can it be protected against?",
      answer:
        "A zero-day exploit takes advantage of vulnerabilities in software that are unknown to the software vendor or developer. Since there’s no available patch at the time of the attack, such exploits can cause significant damage before detection and resolution.",
      overview:
        "Zero-day vulnerabilities are dangerous because they can be exploited before the vendor is aware of the issue, leaving systems open to attack. Keeping systems updated, employing threat intelligence, and adopting advanced detection tools can mitigate these risks.",
      key_points: [
        "Examples: Stuxnet, Heartbleed, WannaCry.",
        "Detection: Advanced threat monitoring and behavior analysis.",
        "Protection: Frequent software updates, threat intelligence services, and network segmentation.",
      ],
      affected_industries: [
        "Energy",
        "Defense",
        "Healthcare",
        "Manufacturing",
        "Government",
      ],
      attachments: [
        {
          type: "PDF",
          name: "Zero-Day Attack Response Guidelines",
        },
        {
          type: "Report",
          name: "Global Impact of Zero-Day Vulnerabilities",
        },
      ],
      reference_links: [
        {
          name: "Kaspersky Zero-Day Exploits",
          url: "https://www.kaspersky.com/",
        },
        {
          name: "CVE Database for Vulnerabilities",
          url: "https://cve.mitre.org/",
        },
      ],
    },
    {
      title: "Insider Threats",
      question:
        "What are insider threats, and how can organizations defend against them?",
      answer:
        "Insider threats occur when individuals within an organization, such as employees or contractors, misuse their access to harm the company. These threats can be intentional (malicious) or unintentional (negligent actions) but often result in data breaches or intellectual property theft.",
      overview:
        "Insider threats are challenging because they stem from trusted individuals with legitimate access to systems and data. Organizations must monitor user behavior, enforce strict access controls, and provide training on secure data handling.",
      key_points: [
        "Types of Insider Threats: Malicious insiders, negligent employees, and compromised insiders.",
        "Detection: Anomalous behavior detection, logging, and audits.",
        "Mitigation: Least privilege access, robust monitoring, and employee training.",
      ],
      affected_industries: [
        "Finance",
        "Healthcare",
        "Tech Companies",
        "Manufacturing",
        "Government",
      ],
      attachments: [
        {
          type: "PDF",
          name: "Insider Threat Mitigation Strategies",
        },
        {
          type: "Case Study",
          name: "Real-World Insider Threat Incidents",
        },
      ],
      reference_links: [
        {
          name: "CERT Insider Threat Center",
          url: "https://insights.sei.cmu.edu/insider-threat/",
        },
        {
          name: "FBI Insider Threat Information",
          url: "https://www.fbi.gov/insider-threat",
        },
      ],
    },
  ];

  return (
    <Fragment>
      <Header />
      <main className="page_content about-page">
        <MetaTags
          title="Knowledge Center | Adri Tech Labs"
          description="Welcome to the Knowledge Center at Adri Tech Labs, your go-to resource for the latest insights and best practices in cybersecurity. Here, we provide valuable articles, whitepapers, and guides designed to help you stay informed about emerging threats and effective security strategies. Our expert team is dedicated to sharing knowledge that empowers businesses to enhance their cybersecurity posture and make informed decisions."
          keywords="Knowledge Center, Adri Tech Labs, cybersecurity insights, best practices, security articles, whitepapers, cybersecurity guides, threat awareness, security strategies, digital protection knowledge"
          canonicalUrl="https://www.adritechlabs.com/knowledge-center"
        />
        <PageTitle
          pageTitle={"Knowledge Center"}
          //   pagesub={"More Techco 😃"}
          //   pageTop={"About"}
        />
        <section className="service_section section_space">
          <div className="container">
            <div className="faq_accordion_new accordion" id="faq_accordion">
              <Accordion
                open={open}
                toggle={toggle}
                className="accordion"
                id="service_process_faq"
              >
                {knowledgedata.map((service, index) => {
                  return (
                    <>
                      <AccordionItem className="accordion-item-new">
                        <AccordionHeader targetId={index + 1}>
                          {service.title}
                        </AccordionHeader>
                        <AccordionBody
                          accordionId={index + 1}
                          className="acc_body"
                        >
                          <h6 className="">{service.question}</h6>
                          <p>{service.answer}</p>
                          <h6>Overview</h6>
                          <p>{service.overview}</p>
                          <h6>Key Points</h6>
                          <ul>
                            {service.key_points.map((item) => {
                              return <li key={Math.random()}>{item}</li>;
                            })}
                          </ul>
                          <h6>Industries</h6>
                          <div className="blog_post_content">
                            <div className="post_meta_wrap">
                              <ul className="category_btns_group unordered_list">
                                {service.affected_industries.map(
                                  (item, index) => (
                                    <li key={index}>
                                      <Link onClick={ClickHandler} to="#">
                                        {item}
                                      </Link>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                          <h6 className="mt-3">Reference</h6>
                          <ul>
                            {service.reference_links.map((item) => {
                              return (
                                <li
                                  style={{ listStyle: "auto" }}
                                  key={Math.random()}
                                >
                                  <a
                                    target="_blank"
                                    style={{ color: "#78828C" }}
                                    href={`${item.url}`}
                                  >
                                    {item.name}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </AccordionBody>
                      </AccordionItem>
                    </>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </section>
      </main>
      <CtaSection />
      <Footer />
    </Fragment>
  );
};
