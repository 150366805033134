import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import emailjs from "emailjs-com"; // Import EmailJS

const ContactForm = (props) => {

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [forms, setForms] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    service: "",
    message: "",
  });

  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };


  const submitHandler = (e) => {
    e.preventDefault();

    if (validator.allValid()) {
      // EmailJS integration
      const templateParams = {
        from_name: forms.name,
        from_email: forms.email,
        phone: forms.phone,
        company: forms.company,
        service: forms.service,
        message: forms.message,
      };

      // Replace 'your_service_id', 'your_template_id', and 'your_public_key' with your actual EmailJS credentials
      emailjs
        .send(
          "service_xz86p1r",
          "template_hmo6tsh",
          templateParams,
          "2Wf55mx_7S_5W7m8e"
        )
        .then(
          (response) => {
            setFormSubmitted(true)
            setForms({
              name: "",
              email: "",
              phone: "",
              company: "",
              service: "",
              message: "",
            });
          },
          (err) => {
            console.error("FAILED...", err);
            alert("An error occurred, please try again.");
          }
        );

      validator.hideMessages();
    } else {
      validator.showMessages();
      setForms({ ...forms });
    }
  };

  return (
    <>
      {!formSubmitted ? (
        <form onSubmit={(e) => submitHandler(e)}>
          <div className="row">
            {/* Name input */}
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="input_title contact-label"
                  htmlFor="input_name"
                >
                  Name
                </label>
                <input
                  value={forms.name}
                  type="text"
                  name="name"
                  className="form-control contact-input"
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                  placeholder="Name"
                />
                {validator.message("name", forms.name, "required|alpha_space")}
              </div>
            </div>

            {/* Email input */}
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="input_title contact-label"
                  htmlFor="input_email"
                >
                  Email
                </label>
                <input
                  value={forms.email}
                  type="email"
                  name="email"
                  className="form-control contact-input"
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                  placeholder="Email"
                />
                {validator.message("email", forms.email, "required|email")}
              </div>
            </div>

            {/* Phone input */}
            <div className="col-6">
              <div className="form-group">
                <label
                  className="input_title contact-label"
                  htmlFor="input_phone"
                >
                  Phone Number
                </label>
                <input
                  value={forms.phone}
                  type="number"
                  name="phone"
                  className="form-control contact-input"
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                  placeholder="Phone Number"
                />
                {validator.message("phone", forms.phone, "required|phone")}
              </div>
            </div>

            {/* Company input */}
            <div className="col-6">
              <div className="form-group">
                <label
                  className="input_title contact-label"
                  htmlFor="input_phone"
                >
                  Company
                </label>
                <input
                  value={forms.company}
                  type="text"
                  name="company"
                  className="form-control contact-input"
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                  placeholder="Company"
                />
                {validator.message(
                  "Company",
                  forms.company,
                  "required|company"
                )}
              </div>
            </div>

            {/* Service select */}
            <div className="col-12">
              <div className="form-group">
                <label
                  className="input_title contact-label"
                  htmlFor="input_service"
                >
                  Service
                </label>
                <select
                  style={{ height: "60px", border: "none" }}
                  value={forms.service}
                  className="form-control contact-input"
                  name="service"
                  id="service"
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                >
                  <option value="">Select a service</option>
                  <optgroup label="Security Diagnostic Services">
                    <option value="Website Security Inspection">
                      Website Security Inspection
                    </option>
                    <option value="Mobile App Threat Analysis">
                      Mobile App Threat Analysis
                    </option>
                    <option value="Thick Client Security Assessment">
                      Thick Client Security Assessment
                    </option>
                    <option value="Network/IT Infrastructure Security Assessment">
                      Network/IT Infrastructure Security Assessment
                    </option>
                    <option value="Cloud Security Assessment">
                      Cloud Security Assessment
                    </option>
                    <option value="Wireless Security Assessment">
                      Wireless Security Assessment
                    </option>
                    <option value="Source Code Review">
                      Source Code Review
                    </option>
                    <option value="Server and Endpoint Hardening">
                      Server and Endpoint Hardening
                    </option>
                    <option value="Hardware Device Security Assessment">
                      Hardware Device Security Assessment
                    </option>
                    <option value="IoT Security Testing">
                      IoT Security Testing
                    </option>
                    <option value="Product Security Assessment">
                      Product Security Assessment
                    </option>
                  </optgroup>

                  {/* Industrial Security Assessment Services */}
                  <optgroup label="Industrial Security Assessment Services">
                    <option value="ICS/OT Security Audit">
                      ICS/OT Security Audit
                    </option>
                  </optgroup>

                  {/* Cyber Protection Services */}
                  <optgroup label="Cyber Protection Services">
                    <option value="Cybersecurity Vulnerability Management">
                      Cybersecurity Vulnerability Management
                    </option>
                    <option value="Security Talent Acquisition and Strengthening">
                      Security Talent Acquisition and Strengthening
                    </option>
                    <option value="Managed SOC Services">
                      Managed SOC Services
                    </option>
                  </optgroup>

                  {/* Personalized Services */}
                  <optgroup label="Personalized Services">
                    <option value="Dark Web Intelligence Services">
                      Dark Web Intelligence Services
                    </option>
                    <option value="Virtual Security Advisory Services">
                      Virtual Security Advisory Services
                    </option>
                    <option value="Crisis Response and Malware Evaluation">
                      Crisis Response and Malware Evaluation
                    </option>
                    <option value="Phishing and Ransomware Attack Exercises">
                      Phishing and Ransomware Attack Exercises
                    </option>
                    <option value="Cyber Insurance Strategy Consulting">
                      Cyber Insurance Strategy Consulting
                    </option>
                    <option value="Digital Crime Investigation and Forensic Examination">
                      Digital Crime Investigation and Forensic Examination
                    </option>
                  </optgroup>

                  {/* Security Governance and Compliance Services */}
                  <optgroup label="Security Governance and Compliance Services">
                    <option value="Cyber Risk and Maturity Evaluation">
                      Cyber Risk and Maturity Evaluation
                    </option>
                    <option value="Cyber Risk Insurance Advisory">
                      Cyber Risk Insurance Advisory
                    </option>
                    <option value="Bank Cybersecurity Compliance with RBI Guidelines">
                      Bank Cybersecurity Compliance with RBI Guidelines
                    </option>
                    <option value="Cybersecurity Compliance with SEBI Regulations">
                      Cybersecurity Compliance with SEBI Regulations
                    </option>
                    <option value="General Data Protection Regulations (GDPR) Readiness">
                      General Data Protection Regulations (GDPR) Readiness
                    </option>
                    <option value="PCI DSS Regulatory Compliance">
                      PCI DSS Regulatory Compliance
                    </option>
                    <option value="ISO 27001 Compliance Services">
                      ISO 27001 Compliance Services
                    </option>
                  </optgroup>
                </select>
                {validator.message("service", forms.service, "required")}
              </div>
            </div>

            {/* Message input */}
            <div className="col-12">
              <div className="form-group">
                <label
                  className="input_title contact-label"
                  htmlFor="input_textarea"
                >
                  Message
                </label>
                <textarea
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                  value={forms.message}
                  type="text"
                  name="message"
                  className="form-control contact-input"
                  placeholder="Write your message..."
                ></textarea>
                {validator.message("message", forms.message, "required")}
              </div>
              <button type="submit" className="noraml-btn">
                <span className="btn_label" data-text="Send Message">
                  Send Message
                </span>
                <span className="btn_icon">
                  <i className="fa-solid fa-arrow-up-right"></i>
                </span>
              </button>
            </div>
          </div>
        </form>
      ) : (
        // Success message after form submission
        <div className="p-6 contact_form">
          <h2 className="text-2xl mb-0" style={{color:'#001E3C !important'}}>Thank you!</h2>
          <p className="text-lg text-theme">
            Your message has been sent successfully.
          </p>
        </div>
      )}
    </>
  );
};

export default ContactForm;
