import React, { Fragment, useState } from "react";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import { Link } from "react-router-dom";
import CtaSection from "../../components/CtaSection/CtaSection";
import Footer from "../../components/footer/Footer";
import { Row, Col } from "reactstrap";
import { careerdata } from "../../api/careerdata";
import MetaTags from "../../components/MetaTags";

export const PrivacyPolicy = () => {
  return (
    <Fragment>
      <Header />
      <main className="page_content about-page">
        <MetaTags
          title="Privacy Policy | Adri Tech Labs"
          canonicalUrl="https://www.adritechlabs.com/privacy-policy"
          description="At Adri Tech Labs, we prioritize your privacy and are committed to protecting your personal information. Our Privacy Policy outlines how we collect, use, and safeguard your data while ensuring compliance with applicable laws and regulations. We encourage you to read through our policy to understand your rights and how we maintain the confidentiality of your information."
          keywords="Privacy Policy, Adri Tech Labs, data protection, personal information, privacy commitment, information security, data usage, compliance, user rights, confidentiality"
        />
        <PageTitle
          pageTitle={"Privacy Policy"}
          //   pagesub={"More Techco 😃"}
          //   pageTop={"About"}
        />
        <section id="career_openings" className="career-openings section_space">
          <div className="section-outer panel py-6 xl:py-9  dark:bg-gray-800">
            <div className="container max-w-lg">
              <div className="section-inner panel">
                <div className="panel vstack justify-center items-center gap-4 sm:gap-6 xl:gap-8 max-w-850px mx-auto">
                  <div className="panel">
                    <p>
                      At Adri Tech Labs (“Company,” “we,” “us,” or “our”), we
                      are committed to safeguarding the privacy of our clients,
                      website visitors, and users. This Privacy Policy outlines
                      how we collect, use, disclose, and protect your personal
                      data when you interact with us. Please read this policy
                      carefully to understand our views and practices regarding
                      your personal data and how we will treat it.
                    </p>
                    <h3>1. Information We Collect</h3>
                    <p>
                      We collect and process the following types of information:
                    </p>
                    <ul>
                      <li className="mb-1">
                        <strong>Personal Data:</strong> This may include your
                        name, email address, phone number, and any other details
                        you provide via our contact forms or during business
                        inquiries.
                      </li>
                      <li>
                        <strong>Non-Personal Data:</strong> When you interact
                        with our website, we may automatically collect certain
                        information, such as your IP address, browser type,
                        device information, and website usage patterns through
                        cookies and similar technologies.
                      </li>
                    </ul>

                    <h3>2. How We Collect Information</h3>
                    <ul>
                      <li className="mb-1">
                        <strong>Directly from You:</strong> When you voluntarily
                        provide your information through forms or direct
                        communication with us.
                      </li>
                      <li>
                        <strong>Third Parties:</strong> In some cases, we may
                        receive personal data from third parties who refer
                        clients or from publicly available sources.
                      </li>
                    </ul>

                    <h3>3. Purpose of Data Collection</h3>
                    <ul>
                      <li className="mb-1">
                        Provide and improve our services, including responding
                        to inquiries or fulfilling your requests.
                      </li>
                      <li className="mb-1">
                        Communicate with you regarding our services, updates,
                        and any relevant information.
                      </li>
                      <li className="mb-1">
                        Comply with legal obligations and enforce our
                        agreements.
                      </li>
                      <li>
                        Conduct analytics to improve the user experience and
                        optimize our website’s performance.
                      </li>
                    </ul>

                    <h3>4. Cookies and Tracking Technologies</h3>
                    <p>
                      We use cookies and similar technologies to collect and
                      store certain information when you use our website. These
                      technologies help us:
                    </p>
                    <ul>
                      <li className="mb-1">
                        Understand user behavior and preferences.
                      </li>
                      <li className="mb-1">
                        Provide a better, personalized browsing experience.
                      </li>
                      <li>Analyze website traffic and usage.</li>
                    </ul>
                    <p>
                      You may manage your cookie preferences by adjusting your
                      browser settings. You can also disable cookies, but doing
                      so may affect the functionality of certain parts of our
                      website.
                    </p>

                    <h3>5. Data Sharing and Disclosure</h3>
                    <p>
                      We do not sell or rent your personal information to third
                      parties. However, we may share your data with trusted
                      third-party service providers under the following
                      circumstances:
                    </p>
                    <ul>
                      <li className="mb-1">
                        <strong>Legal Obligations:</strong> When required by law
                        or in response to valid legal processes, such as
                        subpoenas or court orders.
                      </li>
                      <li>
                        <strong>Business Transfers:</strong> In the event of a
                        merger, acquisition, or sale of all or a portion of our
                        assets, your data may be transferred as part of the
                        transaction.
                      </li>
                    </ul>
                    <p>
                      We ensure that any third party we share data with is bound
                      by confidentiality and data protection agreements.
                    </p>

                    <h3>6. Data Retention</h3>
                    <p>
                      We retain personal data only for as long as necessary to
                      fulfill the purposes outlined in this Privacy Policy. Once
                      the data is no longer needed, we will securely delete or
                      anonymize it. The specific retention periods depend on the
                      type of data and the purposes for which it is collected.
                    </p>

                    <h3>7. Data Security</h3>
                    <p>
                      We take the security of your data seriously and implement
                      appropriate technical and organizational measures to
                      protect your personal information from unauthorized
                      access, alteration, disclosure, or destruction. This
                      includes encryption, access controls, and regular security
                      audits. However, please note that no system can guarantee
                      absolute security.
                    </p>

                    <h3>8. International Data Transfers</h3>
                    <p>
                      If your personal data is transferred to and processed in a
                      country other than your own, we ensure that appropriate
                      safeguards are in place to protect your data in compliance
                      with applicable privacy laws. For transfers outside the
                      European Economic Area (EEA), we use standard contractual
                      clauses approved by the European Commission or ensure that
                      the receiving country provides an adequate level of data
                      protection.
                    </p>

                    <h3>9. Your Rights</h3>
                    <p>
                      You have the following rights regarding your personal
                      data:
                    </p>
                    <ul>
                      <li className="mb-1">
                        <strong>Access:</strong> Request access to the personal
                        data we hold about you.
                      </li>
                      <li className="mb-1">
                        <strong>Rectification:</strong> Request corrections to
                        inaccurate or incomplete data.
                      </li>
                      <li className="mb-1">
                        <strong>Erasure:</strong> Request the deletion of your
                        personal data when it is no longer necessary for the
                        purposes for which it was collected.
                      </li>
                      <li className="mb-1">
                        <strong>Restriction:</strong> Request the restriction of
                        processing your data in certain circumstances.
                      </li>
                      <li className="mb-1">
                        <strong>Data Portability:</strong> Request the transfer
                        of your personal data to another service provider, where
                        technically feasible.
                      </li>
                      <li className="mb-1">
                        <strong>Objection:</strong> Object to the processing of
                        your personal data in certain cases, such as for direct
                        marketing.
                      </li>
                      <li>
                        <strong>Withdrawal of Consent:</strong> Where consent is
                        the legal basis for processing, you have the right to
                        withdraw your consent at any time.
                      </li>
                    </ul>
                    <p>
                      To exercise these rights, please contact us using the
                      information provided below.
                    </p>

                    <h3>10. Data Breach Notification</h3>
                    <p>
                      In the unlikely event of a data breach, we have procedures
                      in place to notify you and the appropriate supervisory
                      authority within the required legal timeframes,
                      particularly if the breach poses a risk to your rights and
                      freedoms.
                    </p>

                    <h3>11. Changes to This Policy</h3>
                    <p>
                      We may update this Privacy Policy from time to time to
                      reflect changes in our practices or relevant laws. We will
                      post any updates on this page, and where appropriate, we
                      will notify you via email or by other means. Please review
                      this page periodically for the latest information on our
                      privacy practices.
                    </p>

                    <h3>12. Contact Information</h3>
                    <p>
                      If you have any questions or concerns regarding this
                      Privacy Policy or how we handle your personal data, please
                      contact us at:
                    </p>
                    <p>
                      <strong>Email:</strong>{" "}
                      <a
                        style={{ color: "#0064B4" }}
                        href="mailto:support@adriitsolutions.com"
                      >
                        support@adriitsolutions.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <CtaSection />
      <Footer />
    </Fragment>
  );
};
